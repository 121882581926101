import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Box from '@material-ui/core/Box';
import {Tools} from './Tools';
import Hidden from '@material-ui/core/Hidden';
import DialogHeader from './DialogHeader';
import {Theme} from './Theme';
import MultiLines from './MultiLines';
import Toast from './Toast';
import Yesno from './Yesno';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UserContainer} from './UserContainer';
import {Inc,Dataset,Stage,StageTicket,StageSeat,StageBooking} from './Inc';
import Ajax from './Ajax';

interface Props {
    close():void,
    stage:Stage,
    booking:StageBooking,
    cancel():void,
    edit():void,
    isOpen:boolean,
	limited?:boolean,
}

const StageBookingDetail = (props:Props)=> {
	const userState = UserContainer.useContainer();
    const [states,setStates] = useState({
        tickets:[] as Dataset[],
        seats:[] as Dataset[],
        ticketCode:"",
        mode: "",
		limited:false,
    })
    const [toast,setToast] = useState("");
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
    
    const cancel = ()=>{
        setStates({...states,mode:"cancel"});
    }
    const yes = ()=>{
		Ajax.setToken(userState.token);
        Ajax.cancelBooking(props.booking.Id,"A").then((res)=>{
            //console.log(res);
            props.cancel();
        }).catch((error)=>{
            setToast("error");
        });
    }
    const no = ()=>{
        setStates({...states,mode:""});
    }
    const edit = ()=>{
        props.edit();
    }
    const closeToast = ()=>{
        setToast("");
    }
    useEffect(()=>{
        if( props.stage.Id!=="" && props.booking ){
            let tickets:Dataset[] = [];
            let seats:Dataset[] = [];
            let exists:{[key: string]: boolean} = {};

            props.stage.Tickets.map((ticket:StageTicket,index:number)=>{
                if( ticket.Accepting && !exists[ ticket.Code ] ){
                    exists[ ticket.Code ] = true;
                    tickets.push({
                        id: ticket.Code,
                        label: ticket.Label,
                    })
				}
				return true;
            });

            props.stage.Seats.map((seat:StageSeat,index:number)=>{
                if( seat.Name!=="" && seat.Accepting ){
					let price = 0;
					if( seat.Price ){
						price = seat.Price;
					}
                    seats.push({
                        id: String(index+1),
                        label: seat.Name + "（" + Tools.comma(price) + "円）",
                    })
				}
				return true;
            });
            //Tools.addTicketCode(props.booking,props.stage.doc);
			let limited = false;
			if( props.limited!==null && props.limited===true ){
				limited = true;
			}
            setStates({...states,mode:'',tickets:tickets,seats:seats,limited:limited});
        }
    },[props.stage,props.booking]);

    if(!props.booking){
        return (null);
    }

    return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
                <DialogHeader close={props.close}/>
				<DialogContent dividers>
                <div className="confirmRoot">
                    <h2>{props.stage.Title}</h2>
                    <Grid container spacing={1} style={{marginBottom:"1em"}}>
                        <Grid item xs={12} md={3} className="formName">時間・回</Grid>
                        <Grid item xs={12} md={9} className="formValue">
                            {Tools.getTimeLabel(props.booking.TicketCode)}
                        </Grid>
                        <Grid item xs={12} md={3} className="formName">チケット種類</Grid>
                        <Grid item xs={12} md={9} className="formValue">
                            {Inc.seats[props.booking.SeatId-1]} {Tools.getLabel(states.seats,String(props.booking.SeatId))}
                        {props.booking.TicketCode.substr(12,5)==="00000" &&
                            <p style={{color:"#CC9900"}}><FontAwesomeIcon icon="question-circle"/> この日時と種類の組み合わせは存在しません</p>
                        }
                        </Grid>
						<Hidden implementation="js" xsDown>
							<React.Fragment>
								<Grid item xs={3} className="formName">枚数</Grid>
								<Grid item xs={3} className="formValue">
									{props.booking.Quantity} 枚
								</Grid>
								<Grid item xs={3} className="formName">　合計金額</Grid>
								<Grid item xs={3} className="formValue">
									{Tools.comma(props.booking.Quantity * props.booking.Price)} 円
								</Grid>
							</React.Fragment>
						</Hidden>
						<Hidden implementation="js" smUp>
							<React.Fragment>
								<Grid item xs={6} className="formName">枚数</Grid>
								<Grid item xs={6} className="formName">合計金額</Grid>
								<Grid item xs={6} className="formValue">
									{props.booking.Quantity} 枚
								</Grid>
								<Grid item xs={6} className="formValue">
									{Tools.comma(props.booking.Quantity * props.booking.Price)} 円
								</Grid>
							</React.Fragment>
						</Hidden>
						{props.stage.UseNumber &&
						<React.Fragment>
                        <Grid item xs={12} md={3} className="formName">整理番号</Grid>
                        <Grid item xs={12} md={9} className="formValue">
							{props.booking.Serial!=="" ? (
							<span>{props.booking.Serial}</span>
							):(
							<span className="empty">なし</span>
							)}
                        </Grid>
						</React.Fragment>
						}
                        <Grid item xs={12} md={3} className="formName">フォーム</Grid>
                        <Grid item xs={12} md={9} className="formValue">
                            {props.booking.FormName!=="" ? (
                                <span>{props.booking.FormName}</span>
                            ):(
                                <span>{Inc.defaultFormName}</span>
                            )}
                        </Grid>
                        <Grid item xs={12} md={3} className="formName">名前</Grid>
                        <Grid item xs={12} md={9} className="formValue">
                            {props.booking.Name}
                        </Grid>
                        <Grid item xs={12} md={3} className="formName">名前（カナ）</Grid>
                        <Grid item xs={12} md={9} className="formValue">
                            {props.booking.Kana}
                        </Grid>
                        <Grid item xs={12} md={3} className="formName">メールアドレス</Grid>
                        <Grid item xs={12} md={9} className="formValue">
							{props.booking.Email==="" ? (
								<span className="empty">なし</span>
							):(
								<span>{props.booking.Email}</span>
							)}
							{props.booking.Checked ? (
								<span className="ok">（確認済み）</span>
							):(
								<span className="ng">（未確認）</span>
							)}
                        </Grid>
						{props.stage.UseTel &&
						<React.Fragment>
                        <Grid item xs={12} md={3} className="formName">電話番号</Grid>
                        <Grid item xs={12} md={9} className="formValue">
							{props.booking.Tel==="" ? (
								<span className="empty">なし</span>
							):(
								<span>{props.booking.Tel}</span>
							)}
                        </Grid>
						</React.Fragment>
						}                   
						{props.stage.UseMemo &&
						<React.Fragment>
                        <Grid item xs={12} md={3} className="formName">備考欄</Grid>
                        <Grid item xs={12} md={9} className="formValue">
							{props.booking.Memo !=="" ? (
                            	<MultiLines text={props.booking.Memo}/>
							):(
								<span className="empty">なし</span>
							)}
                        </Grid>                            
						</React.Fragment>
						}                   
						{props.stage.UseEnq &&
						<React.Fragment>
                        <Grid item xs={12} md={3} className="formName">公演情報</Grid>
                        <Grid item xs={12} md={9} className="formValue">
							{props.booking.Contact !== "" ? (
								<span>{Tools.getLabel(Inc.contacts,props.booking.Contact)}</span>
							):(
								<span className="empty">選択なし</span>
							)}
                        </Grid>                            
						</React.Fragment>
						}                   
                        <Grid item xs={12} md={3} className="formName">支払方法</Grid>
                        <Grid item xs={12} md={9} className="formValue">
                            {props.booking.Payment}
                        </Grid>                            
                    </Grid>
					{props.booking.Canceled && (
					<Box className="canceled">
						キャンセルされました<br/>
						{props.booking.CancelMethod==="U" &&
						<span>（顧客自身によるキャンセル）</span>
						}
						{props.booking.CancelMethod==="A" &&
						<span>（管理者によるキャンセル）</span>
						}
					</Box>
					)}
                </div>
				</DialogContent>
				<DialogActions>
					{!props.booking.Canceled && (
					<React.Fragment>
						{!states.limited &&
						<Button variant="outlined" size="small" color="primary" onClick={cancel} style={{color:'red'}}><FontAwesomeIcon icon="times" style={{marginRight:"0.25em",color:'red'}}/>キャンセルする</Button>
						}
						<Box style={{flex:1}}/>
						<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
						{!states.limited &&
						<Button variant="contained" color="secondary" onClick={edit}>修正する</Button>
						}
					</React.Fragment>
					)}
				</DialogActions>
                <Toast close={closeToast} mes={toast}/>
                <Yesno mes="本当にキャンセルしてよろしいですか？" yes={yes} no={no} isOpen={states.mode==="cancel"}/>
        </Dialog>
    );
}

export default StageBookingDetail;
