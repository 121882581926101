import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import {Tools} from './Tools';

interface Props {
	result: string,
    close():void,
}

const InquiryResult = (props:Props)=> {
	if( props.result==="" ){
		return (null);
	}
    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.result!==""}
            onClose={props.close}
        >
            <div style={Tools.getModalStyle()} className="alert">
                <div className="dialogRoot">
                    <div style={{marginBottom:"1em"}}>
						{props.result==="success" &&
						<span>送信しました</span>
						}
						{props.result==="failure" &&
						<span>送信できませんでした</span>
						}
                    </div>
                    <Button variant="outlined" color="secondary" style={{width:"100%"}} onClick={props.close}>閉じる</Button>
                </div>
            </div>
        </Modal>
    );
}

export default InquiryResult;
