import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Theme} from './Theme';
import {Inc,Dataset,Stage,StageTicket,PagerParam} from './Inc';

export class Tools {
	static getWindowSize = ()=>{
		let wd = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName('body')[0],
		w = wd.innerWidth || e.clientWidth || g.clientWidth,
		h = wd.innerHeight|| e.clientHeight|| g.clientHeight;
	
		return {
			width: w,
			height: h
		}
	}
	static isWidthDown = (num:number)=>{
		const size = Tools.getWindowSize();
		if( size.width<num ){
			return true;
		}
		return false;
	}
	static getModalStyle = ()=> {
		const top = 50;
		const left = 50;
	  
		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`,
		};
	}
	static getModalSearchStyle = ()=>{
		const size = Tools.getModalSize();
		return {
			width: `${size.width}px`,
			height: `${size.height}px`,
			backgroundColor: `white`,
		};
	}
	static getModalConfirmStyle = ()=>{
		const size = Tools.getModalSize();
		return {
			width: `${size.width}px`,
			height: `${size.height}px`,
		};
	}
	static getModalEditorStyle = ()=>{
		const size = Tools.getModalSize(true);
		return {
			width: `${size.width}px`,
			height: `${size.height}px`,
		};
	}
	static getModalSize = (wide?:boolean)=>{
		const size = Tools.getWindowSize();
		let w = 800;
		let max = 0.9;
		if( wide ){
			w = 960;
			max = 0.95;
		}
		if( size.width<800 ){
			w = size.width;
			max = 1.0;
		}
		let h = Math.floor(size.height * max);
		if( h>800 && !wide ){
			h = 800;
		}
		//console.log(w,h);
		return {
			width: w,
			height: h,
		}
	}
	static decodeQueryStrings(str:string){
		const sp1 = str.replace("?","").split("&");
		let data:{[key: string]: string;} = {};
		for(let i=0 ; i<sp1.length ; i++){
			const sp2 = sp1[i].split("=");
			data[ sp2[0] ] = sp2[1];
		}
		return data;
	}
	static pager = (param:PagerParam,func:any)=>{
		if(!param){
			return (null);
		}
		const style = {
			backgroundColor: "white",
			border:"1px solid silver",
			width:"3em",
			padding:"0.25em 0",
			margin:"0 0.1em",
			cursor:"pointer",
		}
		const style_current = {
			backgroundColor: Theme.palette.primary.main,
			border:`1px solid ${Theme.palette.primary.main}`,
			width:"3em",
			padding:"0.25em 0",
			margin:"0 0.1em",
			color:"white",
		}
		let tmp = [];
		if( param.Start>param.MinPage ){
			tmp.push(
				(<button style={style} key="pgfirst" onClick={()=>func(param.MinPage)}>&lt;</button>)
			);
		}
		for( let i=param.Start ; i<=param.End ; i++ ){
			const key = "pg"+i;
			if( i!==param.Current ){
				tmp.push(
					(<button style={style} key={key} onClick={()=>func(i)}>{i}</button>)
				);
			} else {
				tmp.push(
					(<button style={style_current} key={key} onClick={()=>func(i)}>{i}</button>)
				);
			}
		}
		if( param.End<param.MaxPage ){
			tmp.push(
				(<button style={style} key="pglast" onClick={()=>func(param.MaxPage)}>&gt;</button>)
			);
		}

		return (
			<Grid container spacing={0} direction='row-reverse'>
				<Grid item md={1} sm={2} xs={3} style={{textAlign:"right"}}><span style={{borderBottom:"1px solid silver"}}>{param.Count}件</span></Grid>
				<Grid item md={1} sm={2} xs={9} style={{textAlign:"right"}}>
					{param.Unit ? (
						<span style={{borderBottom:"1px solid silver"}}>{param.Sum}{param.Unit}</span>
					):(
						<span style={{borderBottom:"1px solid silver"}}>0</span>
					)}
				</Grid>
				<Grid item md={10} sm={8} xs={12}>{tmp}</Grid>
			</Grid>
		);
	}
	static getLabel = (list:Dataset[],id:string|boolean) => {
		for( let i=0 ; i<list.length ; i++ ){
			if( typeof id === "string" ){
				if( list[i].id===id ){
					return list[i].label;
				}
			} else {
				if( list[i].id==="1" && id===true ){
					return list[i].label;
				}
				if( list[i].id==="0" && id===false ){
					return list[i].label;
				}
			}
		}
		return "";
	}
	static zeroPadding = (num:string,length:number)=>{
		return ('0000000000' + num).slice(-length);
	}
	static comma = (num:number) =>{
		return String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
	}
	static joinTime = (h:string,m:string)=>{
		if( h!=="" && m!=="" ){
			return Tools.zeroPadding(h,2)+":"+Tools.zeroPadding(m,2);
		}
		return "";
	}
	static toDate = (str:string)=>{
		const dt = new Date(str);
		return dt.toLocaleString();
	}
	static getSelectYears = ()=>{
		let years:Dataset[] = [];
		const dt = new Date();
		const current = dt.getFullYear();
		for( let year=current ; year>=2005 ; year-- ){
			years.push({
				id: String(year),
				label: String(year)+"年",
			})
		}
		return years;
	}
	static getSelectMonths = ()=>{
		let months:Dataset[] = [];
		for( let month=1 ; month<=12 ; month++ ){
			months.push({
				id: String(month),
				label: String(month)+"月",
			})
		}
		return months;
	}
	//static getStoreImg = (owner_id:number,img_id:number)=>{
	//	return process.env.REACT_APP_STOREURL + "/img/owner/"+String(owner_id)+"/"+String(img_id)+".jpg";
	//}

	//内部画像
	//ヘルプの画像などで使っている
	static getImgPath = (path:string)=>{
		if( process.env.REACT_APP_PUBLIC_IMG_URL ){
			return process.env.REACT_APP_PUBLIC_IMG_URL + path;
		}
		return "";
	}

	//アップロード画像
	static readonly getUploadImgPath = (fileName:String)=>{
		if( fileName!=="" && process.env.REACT_APP_IMAGE_VIEW_URL ){
			return process.env.REACT_APP_IMAGE_VIEW_URL+fileName+".jpg";
		}
		return "";
	}


	static toHan = (strVal:string)=>{
		// 半角変換
		var halfVal = strVal.replace(/[！-～]/g,
		  function( tmpStr ) {
			// 文字コードをシフト
			return String.fromCharCode( tmpStr.charCodeAt(0) - 0xFEE0 );
		  }
		);
	   
		// 文字コードシフトで対応できない文字の変換
		return halfVal.replace(/”/g, "\"")
		  .replace(/’/g, "'")
		  .replace(/‘/g, "`")
		  .replace(/￥/g, "\\")
		  .replace(/＠/g, "@")
		  .replace(/　/g, " ")
		  .replace(/〜/g, "~");
	}
	static isNumber = (numVal:string)=>{
		const pattern = /^[-]?([0-9]\d*|0)(\.\d+)?$/;
		return pattern.test(numVal);
	}
	static colorLabel = (type:string,id:number|string,text:string|undefined)=>{
		if(!text){
			return (null);
		}
		const cl = "cl cl-" + type + id;
		return (
			<span className={cl}>{text}</span>
		)
	}
	static toSerials = (serial:string,quantity:number,prefix:string) =>{
		let label = serial;
		if( quantity>1 ){
			label += " - ";
			const num = parseInt(serial.substring(prefix.length));
			label += prefix + Tools.zeroPadding(String(num+quantity-1),4);
		}
		return label;
	}
	static getTicketCode = (ticket:StageTicket) =>{
		let flags = "";
		for( let i=0; i<Inc.seats.length; i++ ){
			if( ticket.TargetSeats[i]===true ){
				flags = flags + "1";
			} else {
				flags = flags + "0";
			}
		}
		const sp = ticket.Date.split(/[^0-9]/);
		return Tools.zeroPadding(sp[0],4) + Tools.zeroPadding(sp[1],2) + Tools.zeroPadding(sp[2],2) + Tools.zeroPadding(ticket.Hour,2) + Tools.zeroPadding(ticket.Minute,2) + flags;
	}
	static getTicketLabel = (ticket:StageTicket) =>{
		const sp = ticket.Date.split(/[^0-9]/);
		const d = new Date(ticket.Date);
		const youbi = [ "日", "月", "火", "水", "木", "金", "土" ][ d.getDay() ];
		return Tools.zeroPadding(sp[0],4) + "年" + Tools.zeroPadding(sp[1],2) + "月" + Tools.zeroPadding(sp[2],2) + "日（" + youbi + "）" + Tools.zeroPadding(ticket.Hour,2) + ":" + Tools.zeroPadding(ticket.Minute,2);
	}
	static getTimeLabel = (code:string) =>{
		const d = new Date(code.substr(0,4)+"/"+code.substr(4,2)+"/"+code.substr(6,2));
		const youbi = [ "日", "月", "火", "水", "木", "金", "土" ][ d.getDay() ];
		return code.substr(0,4) + "年" + code.substr(4,2) + "月" + code.substr(6,2) + "日（" + youbi + "）　" + code.substr(8,2) + ":" + code.substr(10,2);
	}
	static getUniqueId(myStrong?: number): string {
		let strong = 10000;
		if (myStrong) strong = myStrong;
		return (
			("0000" + Math.floor(strong * Math.random()).toString(16)).slice(-4) + ("00000000000" + new Date().getTime().toString(16)).slice(-11)
		);
	}
	static validateMail(mail:string) {
		var mail_regex1 = new RegExp( '(?:[-!#-\'*+/-9=?A-Z^-~]+\.?(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*|"(?:[!#-\[\]-~]|\\\\[\x09 -~])*")@[-!#-\'*+/-9=?A-Z^-~]+(?:\.[-!#-\'*+/-9=?A-Z^-~]+)*' );
		var mail_regex2 = new RegExp( '^[^\@]+\@[^\@]+$' );
		if( mail.match( mail_regex1 ) && mail.match( mail_regex2 ) ) {
			// 全角チェック
			if( mail.match( /[^a-zA-Z0-9\!\"\#\$\%\&\'\(\)\=\~\|\-\^\\\@\[\;\:\]\,\.\/\\\<\>\?\_\`\{\+\*\} ]/ ) ) { return false; }
			// 末尾TLDチェック（〜.co,jpなどの末尾ミスチェック用）
			if( !mail.match( /\.[a-z]+$/ ) ) { return false; }
			return true;
		} else {
			return false;
		}
	}
	static validateUrl(url:string) {
		var regex = new RegExp( '^https?://.+[\.].+$' );
		if( url.match( regex ) ) {
			return true;
		} else {
			return false;
		}
	}
	static validateTel(tel:string) {
		var regex = new RegExp( '^0[0-9]+\-[0-9]+\-[0-9]+$' );
		if( tel.match( regex ) ) {
			return true;
		} else {
			return false;
		}
	}
	static validateZip(zip:string) {
		var regex = new RegExp( '^[0-9]{3}\-[0-9]{4}$' );
		if( zip.match( regex ) ) {
			return true;
		} else {
			return false;
		}
	}
	static validateNumber(num:string) {
		var regex = new RegExp( '^[0-9]+$' );
		if( num.match( regex ) ) {
			return true;
		} else {
			return false;
		}
	}
	static validateDate(value:string) {
		const sp = value.split(/[^0-9]/);
		if( sp.length!==3 ){
			return false;
		}
		const y = parseInt(sp[0]);
		const m = parseInt(sp[1])-1;
		const d = parseInt(sp[2]);
		if( isNaN(y) || isNaN(m) || isNaN(d) || y<1 || m<0 || d<1 ){
			return false;
		}
		const date = new Date(y,m,d);
		if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
			return false;
		}
		return true;
	}
	static getStageStatus(stage:Stage) {
		let beforeFlag = false;
		let afterFlag = false;
		const st = Date.parse(stage.StartDate+" "+stage.StartHour+":"+stage.StartMinute);
		const en = Date.parse(stage.EndDateTime);
		const now = Date.now();
		if( st>now ){
			beforeFlag = true;
		}
		if( en<now ){
			afterFlag = true;
		}

		let id = "editing"
		if( stage.Accepting ){
			id = "accepting";
		}
		if(!stage.Accepting && beforeFlag ){
			id = "waiting";
		}
		if(!stage.Accepting && afterFlag ){
			id = "finished";
		}
		if(!stage.Accepting && !afterFlag && !beforeFlag){
			id = "editing";
		}
		return id;
	}
	static FileSize = (byte:number)=>{
		if( byte<1024 ){
			return String(byte) + "byte";
		} else if( byte < 1024*1024 ){
			return String(Math.floor(byte/1024*10)/10) + "KB";
		} else if( byte < 1024*1024*1024 ){
			return String(Math.floor(byte/1024/1024*10)/10) + "MB";
		} else {
			return String(Math.floor(byte/1024/1024/1024*10)/10) + "GB";
		}
	}
	static FileType = (name:string)=>{
		const sp = name.split('.');
		if( sp.length>1 ){
			return sp[ sp.length-1 ];
		}
		return '';
	}
	static toByte = (str:string)=>{
		return (encodeURIComponent(str).replace(/%../g,"x").length);
	}
	static readonly hasZen = (input:string)=>{
		if( input.match(/[^\x01-\x7E\xA1-\xDF]/) ) {
			return true;
		}
		return false;
	}
	static readonly hasHankana = (input:string)=>{
		var reg = new RegExp(/[ｦ-ﾟ]/);
		return reg.test(input);
	}
	static readonly getExt = (name:string)=>{
		const sp = name.split('.');
		let ext = sp[sp.length-1];
		ext = ext.toLowerCase();
		if( ext==="jpeg" ){
			ext = "jpg";
		}
		return ext;
	}
}