import React,{useState,useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
//import Popper from '@material-ui/core/Popper';
//import IconButton from '@material-ui/core/IconButton';
import {Tools} from './Tools';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ColInput from './ColInput';
import Toast from './Toast';
import Yesno from './Yesno';
import {Inc,StageForm} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DialogHeader from './DialogHeader';
import {Theme} from './Theme';

interface Props {
    close():void,
    stageForm:StageForm,
    update(stageForm:StageForm,deleteFlag:boolean):void,
    isOpen:boolean,
    isFirst:boolean,
    check(name:string,code:string):boolean[]
}

const StageFormInput = (props:Props)=> {
    const [stageForm,setStageForm] = useState(Inc.defaultStageForm())
    const [toast,setToast] = useState("");
	const [message,setMessage] = useState("");
	const [hasSecret,setHasSecret] = useState(false);
	const [vali,setVali] = useState(Inc.defaultStageFormInputErrors());
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
    const yes = ()=>{
        props.update(props.stageForm,true);
        setMessage("");
    }
    const no = ()=>{
        setMessage("");
    }
    const change = (name:keyof StageForm,value:string )=>{
        setStageForm({...stageForm,[name]:value});
    }
    const changeSecret = (name:'HasSecret',value:boolean )=>{
        setHasSecret(value);
    }
    const update = async ()=>{
		const errors = Inc.defaultStageFormInputErrors();
		let flag = true;

		if( !props.isFirst ){
            const name = stageForm.Name.trim();
            if(name===""){
				errors.Name = "入力してください";
				flag = false;
            } else if(name.length>24){
				errors.Name = "24文字以内にしてください";
				flag = false;
            }
            const code = stageForm.Code.trim();
            if(code===""){
				errors.Code = "入力してください";
				flag = false;
            } else if(code.length>16){
                errors.Code = "16文字以内にしてください";
                flag  = false;
			}
			const check = await props.check(name,code);
			if( errors.Name==="" ){
				if( check[0] ){
					errors.Name = "この名前は他のフォームで使われています";
					flag = false;
				}
			}
			if( errors.Code==="" ){
				if( check[1] ){
					errors.Code = "このコードは他のフォームで使われています";
					flag = false;
				}
			}
			const bccmail = stageForm.BccMail.trim();
			if( bccmail==="" ){
				//errors.BccMail = "省略できません";
				//flag = true;
			} else if( Tools.validateMail(bccmail)===false ){
				errors.BccMail = "不正なメールアドレスです";
				flag = false;
			}	
		}
		if( flag ){
			let secret = '';
			if( stageForm.Secret ){
				secret = stageForm.Secret;
			}
			if( hasSecret && secret==='' ){
				secret = 'new';
			} else if( !hasSecret && secret!=='' ){
				secret = '';
			}
			props.update({...stageForm,Secret:secret},false);
		} else {
			setToast(Inc.errorText+"部分を修正してください");
			setVali(errors);
		}
    }
    const confirm = ()=>{
        setMessage("本当に削除してよろしいですか？");
    }
    const closeToast = ()=>{
        setToast("");
    }
    useEffect(()=>{
		if(!props.isOpen){
			return;
		}
        //console.log("form",props.stageForm);
		if( props.stageForm.Secret && props.stageForm.Secret!=='' ){
			setHasSecret(true);
		} else {
			setHasSecret(false);	
		}
        setStageForm(props.stageForm);
    },[props.isOpen]);

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
        >
                <DialogHeader close={props.close}/>
                {props.stageForm && stageForm &&
				<>
				<DialogContent dividers>
                <div className="confirmRoot">
					<h2>フォーム</h2>
                    <div style={{marginBottom:"1.5em"}}>
                        {props.isFirst ? (
                        <FormControl className="formControl formControlDense">
                            <div className="formLabel">名前</div>
                            <div className="formValue">{Inc.defaultFormName}</div>
                        </FormControl>
                        ):(
						<React.Fragment>
							<div className="formLabel">名前</div>
                        	<ColInput id="Name" noName={true} name="名前" dense={true} error={vali.Name} placeholder="24文字以内" type="text" value={stageForm.Name} change={change}/>
						</React.Fragment>
                        )}
                    </div>
                    <div style={{marginBottom:"1.5em"}}>
                        {props.isFirst ? (
                        <FormControl className="formControl formControlDense">
							<div className="formLabel">コード</div>
                            <div className="formValue">なし</div>
                        </FormControl>
                        ):(
						<React.Fragment>
							<div className="formLabel">コード</div>
                        	<ColInput id="Code" name="コード" noName={true} dense={true} error={vali.Code} helper="フォームのURLの一部になります" placeholder="半角英数字16文字以内" hankaku={true} type="text" value={stageForm.Code} change={change}/>
						</React.Fragment>
                        )}
                    </div>
                    <div style={{marginBottom:"1.5em"}}>
                        {props.isFirst ? (
                        <FormControl className="formControl formControlDense">
							<div className="formLabel">通知メール</div>
                            <div className="formValue">なし</div>
                        </FormControl>
                        ):(
						<React.Fragment>
							<div className="formLabel">通知メール</div>
                        	<ColInput id="BccMail" name="通知メール" noName={true} dense={true} error={vali.BccMail} helper="省略可。共通の通知メール以外にも通知したい場合は入力してください" placeholder="メールアドレス" type="text" pattern="email" value={stageForm.BccMail} change={change}/>
						</React.Fragment>
                        )}
                    </div>
                    <div style={{marginBottom:"1.5em"}}>
                        <ColInput id="Viewing" name="公開状態" useBool={true} dense={true} type="radio" value={stageForm.Viewing} list={Inc.formViewings} change={change}/>
                    </div>
                    <div style={{marginBottom:"1.5em"}}>
						{props.isFirst ? (
                        <FormControl className="formControl formControlDense">
							<div className="formLabel">予約状況確認ページ</div>
                            <div className="formValue">なし</div>
                        </FormControl>
						):(
						<ColInput id="Secret" name="予約状況確認ページ" useBool={true} dense={true} type="radio" value={hasSecret} list={props.isFirst ? Inc.formHasSecretFirst : Inc.formHasSecret} change={changeSecret}/>
						)}
                    </div>
                </div>
				</DialogContent>
				<DialogActions>
                    {props.stageForm.Code!=="" &&
						<Button variant="outlined" color="primary" onClick={confirm}><FontAwesomeIcon icon="times" style={{marginRight:"0.25em"}}/>削除する</Button>
                    }
					<Box style={{flex:1}}/>
					<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
					<Button variant="contained" color="secondary" onClick={update}>変更する</Button>
				</DialogActions>
				</>
                }
                <Toast close={closeToast} mes={toast}/>
                <Yesno mes={message} yes={yes} no={no} isOpen={message!==""}/>
        </Dialog>
    );
}

export default StageFormInput;
