import React,{useState, useEffect, useMemo} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
//import Tooltip from '@material-ui/core/Tooltip';
//import MultiLines from './MultiLines';
import ClimbUp from './ClimbUp';
import Loading from './Loading';
import Yesno from './Yesno';
import Ajax from './Ajax';
import Toast from './Toast';
import StageHeader from './StageHeader';
import StageAgreeInput from './StageAgreeInput';
import StageRangeInput from './StageRangeInput';
import {UserContainer} from './UserContainer';
import {Inc,StageTicket,StageAgree,StageRange} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import "./StageDetail.scss";
//import { Tools } from './Tools';

interface Props {
	
}

//interface RCProps extends RouteComponentProps<{id:string}> {
//	
//}

const StageAgreeDetail = (props:Props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		agree:Inc.defaultStageAgree(),
		isInput: false,
		isRange: false,
		isLoading:true,
		editIndex: -1,
	})
	const [redirectId,setRedirectId] = useState("");
	const [message,setMessage] = useState("");
	const [toast,setToast] = useState("");
	const {gid} = useParams<{gid:string}>();
	
	const yes = ()=>{
		setStates({...states, isLoading:true})
		Ajax.setToken(userState.token);
		Ajax.startAgree(userState.stage.Id).then((res)=>{
			//console.log("start_agree",res);
			if( res.ok ){
				setMessage("");
				refreshAgree();
			} else {
				setStates({...states, isLoading:false});
				setToast("開始できませんでした");
			}
		}).catch((error)=>{
			setStates({...states, isLoading:false});
			console.log(error);
			setToast("開始できませんでした");
		})

	}
	const no = ()=>{
		setMessage("");
	}
	const closeToast = ()=>{
		setToast("");
	}
	const openStageAgreeInput = ()=>{
		setStates({...states,isInput:true});
	}
	const closeStageAgreeInput = ()=>{
		setStates({...states,isInput:false});
	}
	const openStageRangeInput = ()=>{
		setStates({...states,isRange:true});
	}
	const closeStageRangeInput = ()=>{
		setStates({...states,isRange:false});
	}
	const refreshAgree = ()=>{
		loadAgree(userState.stage.Id)
	}
	const refreshRange = async (range:StageRange)=>{
		setStates({...states, isLoading:true})
		Ajax.setToken(userState.token);
		await Ajax.updateStageRange(range).then((res)=>{
			//console.log(res);
			if( res.ok ){
				//console.log("ver",userState.ver,res.ver);
				userState.setVer(res.ver);
				userState.setStage({...userState.stage,StartDate:range.StartDate,StartHour:range.StartHour,StartMinute:range.StartMinute,EndDays:range.EndDays,EndHours:range.EndHours,EndMinutes:range.EndMinutes});
				setStates({...states, isRange:false, isLoading:false})
			} else {
				setStates({...states,isLoading:false});
				setToast("更新できませんでした");
			}
		}).catch((error)=>{
			setStates({...states,isLoading:false});
			setToast("更新できませんでした");
		});
	}
	const confirm = ()=>{
		setMessage("このイベントの利用を開始します。利用期間（データの保存期間）は今から1年間です。開始してよろしいですか？");
	}
	const addAgree = async (agree:StageAgree)=>{
		setStates({...states,isLoading:true});
		Ajax.setToken(userState.token);
		await Ajax.addAgree(agree).then((res)=>{
			//console.log(res);
			if( res.ok ){
				setStates({...states,agree:agree, isInput:false, isLoading:false});
			} else {
				setStates({...states,isLoading:false});
				setToast("登録できませんでした");
			}
		}).catch((error)=>{
			throw error;
		});

	}
	const updateAgree = async (agree:StageAgree)=>{
		setStates({...states,isLoading:true});
		Ajax.setToken(userState.token);
		await Ajax.updateAgree(agree).then((res)=>{
			//console.log(res);
			if( res.ok ){
				setStates({...states,agree:agree, isInput:false, isLoading:false});
			} else {
				setStates({...states,isLoading:false});
				setToast("更新できませんでした");
			}
		}).catch((error)=>{
			throw error;
		});		
	}

	const loadAgree = (stageId:string)=>{
		Ajax.setToken(userState.token);
		Ajax.getAgree(stageId).then((res)=>{
			if( res.ok ){
				setStates({...states,agree:res.agree, isInput:false, isLoading:false})
			} else {
				setStates({...states, isInput:false, isLoading:false})
			}
		}).catch((error)=>{
			console.log(error);
		});
	}

	const load = async (stageId:string)=>{
		try {
			const res = await Ajax.getStage(stageId,false);
			if( res.ok ){
				userState.setStage(res.stage);
				userState.setVer(res.ver);
			} else {
				setRedirectId("/");
			}
		} catch(error) {
			console.log("error",error);
			setRedirectId("/");
		}
	}

	const checkVer = async (stageId:string)=>{
		let flag = true;
        if( !userState.user || !userState.hasStage() || userState.stage.Id!==stageId ){
			flag = false;
        }
		const res = await Ajax.getStageVer(stageId);
		//console.log("stageVer",res);
		if( !res.ok ){
			flag = false;
		} else if( userState.ver!==res.ver ){
			flag = false;
		}
		if( !flag ){
            //setRedirectId("/stage/detail/"+stageId);
			//return;
			await load(stageId);
		}
		loadAgree(stageId);
	}

	useEffect( ()=>{
		const id:string = String(gid).trim();
		checkVer(id);
		//loadAgree(userState.stage.Id);
		//setStates({...states, isLoading:false});
	},[userState.user,gid]);

	const endDate = useMemo( ()=>{
		let endDate = "";
		if(userState.stage.StartDate!==""){
			if( userState.stage.EndDays===0 && userState.stage.EndHours===0 && userState.stage.EndMinutes===0 ){
				endDate = "開演まで"
			} else {
				endDate = "開演の"
				if( userState.stage.EndDays>0 ){
					endDate = endDate+userState.stage.EndDays+"日"
				}
				if( userState.stage.EndHours>0 ){
					endDate = endDate+userState.stage.EndHours+"時間"
				}
				if( userState.stage.EndMinutes>0 ){
					endDate = endDate+userState.stage.EndMinutes+"分"
				}
				endDate = endDate + "前"
			}
		}
		return endDate;
	},[userState.stage.StartDate,userState.stage.EndDays,userState.stage.EndHours,userState.stage.EndMinutes] );

	const u_caution = useMemo( ()=>{
		//開演日時が受付期間内であるか確認
		let isSafeRange = true;

		const u_start = new Date(userState.stage.StartDate + " " + userState.stage.StartHour+":"+userState.stage.StartMinute+":00");

		userState.stage.Tickets.map((ticket:StageTicket,index:number)=>{
			const kaien = new Date(ticket.Date+" "+ticket.Hour+":"+ticket.Minute+":00");
			if( kaien<u_start ){
				isSafeRange = false;
			}
			return true;
		});


		if( !isSafeRange ){
			return "受付期間に含まれない開演日時があります";
		}
		return "";
	},[userState.stage.StartDate,userState.stage.StartHour,userState.stage.StartMinute,userState.stage.Tickets] );

	const a_caution = useMemo( ()=>{
		//開演日時が利用期間内であるか確認
		let isSafeAgree = true;

		const a_start = new Date(states.agree.DateFrom+" 00:00:00");
		const a_end = new Date(states.agree.DateTo+" 23:59:59");

		userState.stage.Tickets.map((ticket:StageTicket,index:number)=>{
			const kaien = new Date(ticket.Date+" "+ticket.Hour+":"+ticket.Minute+":00");
			if( kaien<a_start || kaien>a_end ){
				isSafeAgree = false;
			}
			return true;
		});

		if( !isSafeAgree ){
			return "利用期間に含まれない開演日時があります";
		}
		return "";
	},[userState.stage.Tickets,states.agree.DateFrom,states.agree.DateTo] );

	if( redirectId!=="" ){
		return (
			<Redirect to={redirectId}/>
		)
	}

	if( !userState.ready ){
		return (null);
	}

	let btncolor1: "secondary"|"primary" = "secondary";
	let btncolor2: "secondary"|"primary" = "secondary";
	let btncolor3: "secondary"|"primary" = "secondary";

	if( states.agree.Accepting ){
		if( userState.stage.StartDate==="" ){
			btncolor3 = "primary";
		}
	} else if( states.agree.Name==="" ){
		btncolor1 = "primary";
	} else {
		btncolor2 = "primary";
	}

	return (
		<React.Fragment>
			<StageHeader stage={userState.stage} current="agree"/>
			<div id="stage_detail" className="mediumRoot">
				<div className="paper">
					<Hidden implementation="js" smDown>
						<Grid container spacing={0} style={{marginBottom:"1em"}}>
							<Grid item xs={8}>
								<Button variant="contained" color={btncolor1} onClick={openStageAgreeInput} style={{marginRight:"0.25em"}}>
									<FontAwesomeIcon icon="user"/>利用者情報
								</Button>
								<Button variant="contained" color={btncolor2} disabled={states.agree.Accepting || states.agree.Name===""} onClick={confirm}>
									<FontAwesomeIcon icon="bell"/>利用開始
								</Button>
							</Grid>
							<Grid item xs={4} style={{textAlign:"right"}}>
								<Button variant="contained" color={btncolor3} disabled={!states.agree.Accepting} onClick={openStageRangeInput}>
									<FontAwesomeIcon icon="edit"/>受付期間
								</Button>
							</Grid>
						</Grid>
					</Hidden>
					<Hidden implementation="js" mdUp>
						<Grid container spacing={1}>
							<Grid item xs={6}>
								<Button variant="contained" color={btncolor1} onClick={openStageAgreeInput}>
									<FontAwesomeIcon icon="user"/>利用者情報
								</Button>
							</Grid>
							<Grid item xs={6} style={{textAlign:"right"}}>
								<Button variant="contained" color={btncolor2} disabled={states.agree.Accepting} onClick={confirm}>
									<FontAwesomeIcon icon="bell"/>利用開始
								</Button>

							</Grid>
						</Grid>

					</Hidden>

					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={7} style={{paddingTop:"1em"}}>
							{!states.agree.Accepting && states.agree.Name==="" &&
							<div className="noticeAgree">準備が整ったら利用者情報を入力して利用開始してください</div>
							}
							<Hidden implementation="js" mdUp>
								<h3 className="sep">利用者情報</h3>
							</Hidden>
							<Table style={{marginBottom:"2em"}}>
								<TableBody>
									<TableRow>
										<TableCell className="colName">利用者名</TableCell>
										<TableCell>{states.agree.Name}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">団体名</TableCell>
										<TableCell>{states.agree.Company}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">電話</TableCell>
										<TableCell>{states.agree.Tel}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">郵便番号</TableCell>
										<TableCell>{states.agree.Zip}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">住所</TableCell>
										<TableCell>{states.agree.Addr}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">Ｅメール</TableCell>
										<TableCell>{states.agree.Email}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">利用開始日</TableCell>
										<TableCell>
								{states.agree.DateFrom!=="" ? (
									<span>{states.agree.DateFrom}</span>
								):(
									<span style={{color:"#DA4234"}}>まだ利用開始されていません</span>
								)}

										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">利用終了日</TableCell>
										<TableCell>
								{states.agree.DateTo!=="" ? (
									<span>{states.agree.DateTo}</span>
								):(
									<span>利用開始日の１年後</span>
								)}

										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
							{a_caution !== "" &&
							<div className="cautions">
								※{a_caution}
							</div>
							}
						</Grid>
						<Grid item xs={12} sm={12} md={5} style={{paddingTop:"1em"}}>
							<div className="stageRange">
								<Grid container spacing={0} className="stageInput">
									<Grid item xs={5} className="formName">受付開始日時</Grid>
									<Grid item xs={7} className="formValue">
										{userState.stage.StartDate!=="" ? (
											<span>{userState.stage.StartDate} {userState.stage.StartHour}:{userState.stage.StartMinute}</span>
										):(
											<span>未定</span>
										)}
									</Grid>
									<Grid item xs={5} className="formName">受付終了日時</Grid>
									<Grid item xs={7} className="formValue">
										{endDate!=="" ? (
											<span>{endDate}</span>
										):(
											<span>未定</span>
										)}
									</Grid>
								</Grid>
								{u_caution !== "" &&
								<div className="cautions">
									※{u_caution}
								</div>
								}
							</div>
							<Hidden implementation="js" mdUp>
								<Box style={{marginTop:"1em",marginBottom:"2em"}}>
									<Button variant="contained" color={btncolor3} disabled={!states.agree.Accepting} onClick={openStageRangeInput} style={{width:"100%"}}>
										<FontAwesomeIcon icon="edit"/>受付期間
									</Button>
								</Box>
							</Hidden>

							<div className="stageRangeNotice">
								<ul>
									<li>利用期間は1年間です。利用期間が終了するとイベントも予約も自動的に削除されます。</li>
									<li>利用者情報はこのイベントを削除しない限り、次回以降のイベントにも引き継がれます。もちろん修正もできます。</li>
									<li>受付期間を設定しないと、お客様用の受付フォームは利用できません。また、受付開始日は利用期間外には設定できません。</li>
									<li>開催内容の登録後、半年過ぎても利用開始されないイベントは自動的に削除されます。</li>
									<li>利用者情報がチケットの受付フォームや予約完了後のマイページなど予約者（お客様）の目に触れる場所に表示されることはありません。</li>
								</ul>

							</div>
						</Grid>

					</Grid>

				</div>
				<StageAgreeInput isOpen={states.isInput} close={closeStageAgreeInput} update={updateAgree} add={addAgree} stage={userState.stage} agree={states.agree}/>
				<StageRangeInput isOpen={states.isRange} close={closeStageRangeInput} update={refreshRange} stage={userState.stage} agree={states.agree}/>
				<Yesno mes={message} yes={yes} no={no} isOpen={message!==""}/>
				<Toast close={closeToast} mes={toast}/>
				<Loading isLoading={states.isLoading}/>
				<ClimbUp/>
			</div>
		</React.Fragment>
	);
}

export default StageAgreeDetail;
