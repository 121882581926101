import React,{useState,useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Tools} from './Tools';
import DialogHeader from './DialogHeader';
import ColInput from './ColInput';
import {Stage} from './Inc';
import {Theme} from './Theme';

interface Props {
	close():void,
	stage:Stage,
	serial:number,
	update(serial:number):void,
	isOpen:boolean,
}

const StageBookingSerialInput = (props:Props)=> {
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	const [states,setStates] = useState({
		"current": "0",
		"new": "0",
		"error": "",
	});
	
	const change = (name:string,value:string)=>{
		setStates({...states,[name]:value});
	}

	const update = ()=>{
		if( !Tools.validateNumber(states.new) ){
			setStates({...states,error:"数字だけで入力して下さい"})
		} else {
			props.update(parseInt(states.new));
		}
	}

	useEffect(()=>{
		if( props.isOpen ){
			setStates({current:String(props.serial),new:String(props.serial),error:""});
		}
	},[props.isOpen]);

	let withPrefix = Tools.zeroPadding(String(parseInt(states.current)+1),4);
	if( props.stage.Prefix!=="" ){
		withPrefix = props.stage.Prefix + withPrefix;
	}
	

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
				<DialogHeader close={props.close}/>
				<DialogContent dividers>
				<div className="confirmRoot">
					<h2>整理番号インデックス設定</h2>
					<Box style={{marginBottom:"1em"}}>
						整理番号の現在のインデックスは<span className="serial">{states.current}</span>です。<br/>
						次の予約の整理番号は<span className="serial">{withPrefix}</span>になります。<br/>
						{!props.stage.UseNumber &&
						<p style={{fontSize:"90%",color:"#FF0000"}}>※整理番号を利用するには、「開催内容」で整理番号「あり」に設定してください。</p>
						}
					</Box>
					<Grid container spacing={1} style={{marginBottom:"2em"}}>
						<Grid item xs={5} className="formName">インデックス</Grid>
						<Grid item xs={7} >
							<ColInput name="インデックス" noName={true} id="new" error={states.error} type="text" dense={true} value={states.new} change={change}/>
						</Grid>
					</Grid>
				</div>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
					<Button variant="contained" color="secondary" onClick={update}>インデックスを変更する</Button>
				</DialogActions>
		</Dialog>
	);
}

export default StageBookingSerialInput;
