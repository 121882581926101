import React,{useEffect,useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogHeader from './DialogHeader';
//import useStyles from './Styles';
import ColInput from './ColInput';
import Title from './Title';
import Toast from './Toast';
import {Theme} from './Theme';
import {UserContainer} from './UserContainer';

interface Props {
    isOpen:boolean,
	close(send:boolean):void,
}

const EmailInput:React.FC<Props> = (props)=> {
	const userState = UserContainer.useContainer();
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	const [states,setStates] = useState({
		email: '',
		error: '',
		toast: '',
	})

	const change = (name:string,value:string)=>{
		const regex = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
		const oldEmail = userState.user?.email;

		let error = '';
		if( !regex.test(value) ){
			error = '正しくないメールアドレスです';
		} else if( oldEmail && value===oldEmail ){
			error = '現在のメールアドレスと同じです';
		}
		setStates({...states,[name]:value,error:error});
	}

	const close = ()=>{
		props.close(false);
	}

	const closeToast = ()=>{
		setStates({...states,toast:''})
	}

	const update = ()=>{
		//再認証用のメールを送信
		const oldEmail = userState.user?.email;
		if( oldEmail ){
			userState.sendReauthEmail(oldEmail,states.email,()=>{
				props.close(true);
			},()=>{
				setStates({...states,toast:'メールを送信できませんでした'});
			});
		}

		//userState.updateUserEmail(states.email,()=>{
		//	props.close(true);
		//},()=>{
		//	setStates({...states,toast:'メールを送信できませんでした'});
		//})
	}

	useEffect(()=>{
		if(!props.isOpen){
			return;
		}
		setStates({
			email: '',
			error: '',
			toast: '',
		});
	},[props.isOpen]);

    //const classes = useStyles();
    if( !props.isOpen ){
        return (null);
	}


	return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				//none
				close();
			}}
			fullScreen={fullScreen}
        >
			<DialogHeader close={close}/>
			<DialogContent dividers>
			<div className="confirmRoot">
				<Title text="メールアドレス変更" size={2}/>
				<Box style={{marginBottom:'1em'}}>
					<p>ログイン用のメールアドレスを変更するには「再認証（再ログイン）」と「新しいメールアドレスの受信確認」が必要になります。</p>
				</Box>
				<Box style={{marginBottom:'3em',padding:'1em',fontSize:'90%',backgroundColor:'#F5F5F5'}}>
					新しいメールアドレスを入力すると、まず現在のメールアドレスにログインメールが送信されますので、メールに書かれたURLにアクセスしてください。
				</Box>
				<Box>
					<FormControl className="formControl">
						<FormLabel component="label" className="formLabel">現在のメールアドレス</FormLabel>
						<Box style={{marginTop:'0.5em'}}>
							{userState.user?.email}
						</Box>
					</FormControl>
				</Box>				
				<Box style={{marginBottom:'2em'}}>
					<FormControl fullWidth={true} className="formControl">
						<FormLabel component="label" className="formLabel">新しいメールアドレス</FormLabel>
						<ColInput id="email" noName={true} dense={true} error={states.error} placeholder="ここに入力" helper="" type="text" name="新しいメールアドレス" value={states.email} change={change}/>
					</FormControl>
				</Box>
			</div>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="default" onClick={close}>閉じる</Button>
				<Button variant="contained" color="primary" onClick={update} disabled={states.error!=='' || states.email===''}>変更する</Button>
			</DialogActions>
			<Toast mes={states.toast} close={closeToast}/>
        </Dialog>

    );
}

export default EmailInput;
