export const CookieHashName = "ticketa19";
export const SiteName = "R7 TICKET SERVICE（R7チケットサービス）";
export const getCookieOptions = (domain:string | undefined)=>{
	//console.log("domain",domain);
	const expire = new Date();
	expire.setDate(expire.getDate() + 20);
	const options = {
		path: "/",
		expires: expire,
		maxAge: 86400*20,
		domain: domain,
		secure: false,
		httpOnly: false,
		sameSite: true,
	}
	return options;
};

export interface Dataset {
	id: string,
	label: string,	
}
export interface Boolset {
	id: boolean,
	label: string,	
}
export interface PagerParam {
	Start:number,
	End:number,
	MinPage:number,
	MaxPage:number,
	Current:number,
	Count:number,
	Sum?:number,
	Unit?:string,
}
export interface Profile {
	name: string,
	dateKeys: string[],
	version: number,
	secret: string,
	firstDateKey: string,
	lastDateKey: string,
	publicKey: string,
}
export interface Info {
	Title: string,
	Text: string,
	Date: string,
	Deleted: boolean,
}
export interface Stage {
	Id: string,
	Code: string,
	Title: string,
	Organizer: string,
	Place: string,
	Tickets: StageTicket[],
	Seats: StageSeat[],
	Limit: string,
	StartDate: string,
	StartHour: string,
	StartMinute: string,
	EndDateTime: string,
	EndDays: number,
	EndHours: number,
	EndMinutes: number,
	UseNumber: boolean,
	Prefix: string,
	MaxQuantity: number,
	Desk: string,
	Url: string,
	Email: string,
	Tel: string,
	BccMail: string,
	Notice: string,
	Description: string,
	Message: string,
	Alert: string,
	Forms: StageForm[],
	UseTel: boolean,
	UseMemo: boolean,
	UseEnq: boolean,
	ImageKey: string,
	Accepting: boolean,
	OpenedAt: string,
	UserId: string,
	Removable: boolean,
	Deleted: boolean,
	CreatedAt: string,
	UpdatedAt: string,
}
export interface StageTicket {
	Code: string,
	Label: string,
	Date: string,
	Hour: string,
	Minute: string,
	TargetSeats: boolean[],
	Limit: number | null,
	UseLimit: boolean,
	Accepting: boolean,
}
export interface StageSeat {
	Name: string,
	Price: number | null,
	Accepting: boolean,
}
export interface StageForm {
	Name: string,
	Code: string,
	BccMail: string,
	Secret?: string,
	Viewing: boolean,
}
export interface StageCopy {
	Title: string,
	Code: string,
}
export interface StageAgree {
	StageId: string,
	UserId: string,
	Company: string,
	Name: string,
	Tel: string,
	Zip: string,
	Addr: string,
	Email: string,
	Fee: number,
	Received: boolean,
	ReceivedTime: string,
	Send: number,
	DateFrom: string,
	DateTo: string,
	Accepting: boolean,
	CreatedAt: string,
	UpdatedAt: string,
}
export interface StageRange {
	StageId: string,
	StartDate: string,
	StartHour: string,
	StartMinute: string,
	EndDays: number,
	EndHours: number,
	EndMinutes: number,
}
export interface StageFormConfig {
	StageId: string,
	UseTel: boolean,
	UseMemo: boolean,
	UseEnq: boolean,
}
export interface AdditionalBooking {
	SeatId: number,
	Quantity: number,
	Price: number,
}
export interface StageBooking {
	Id: number,
	StageId: string,
	TicketCode: string,
	SeatId: number,
	Quantity: number,
	Additionals: AdditionalBooking[],
	FormCode: string,
	FormName: string,
	Price: number,
	TotalFee: number,
	Name: string,
	Kana: string,
	Email: string,
	Tel: string,
	Memo: string,
	Contact: string,
	Checked: boolean,
	Serial: string,
	Payment: string,
	PaymentTypeId: string,
	Secret: string,
	Token: string,
	EntryMethod: string,// user admin csv
	CancelMethod: string,// user admin
	Canceled: boolean,
	CreatedAt: string,
	UpdatedAt: string,
}
export interface Inquiry {
	UserId: string,
	Name: string,
	Company: string,
	Email: string,
	Message: string,
	CreatedAt: string,
	UpdatedAt: string,
}
export interface StageRangeInputErrors {
	StartDate: string,
	EndDays: string,
	EndHours: string,
	EndMinutes: string,
}
export interface StageInputErrors {
	Code: string,
	Title: string,
	Organizer: string,
	Place: string,
	Tickets: string,
	Seats: string[],
	Desk: string,
	Url: string,
	Email: string,
	Tel: string,
	BccMail: string,
	Notice: string,
	Description: string,
	Message: string,
	Alert: string,
	Prefix: string,
}
export interface StageBookingInputErrors {
	TicketCode: string,
	SeatId: string,
	Quantity: string,
	Additionals: string,
	FormCode: string,
	FormName: string,
	Serial: string,
	Name: string,
	Kana: string,
	Email: string,
	Tel: string,
	Memo: string,
	Contact: string,
	StockTypeId: string,
	Payment: string,
}
export interface StageAgreeInputErrors {
	Name: string,
	Company: string,
	Tel: string,
	Email: string,
	Zip: string,
	Addr: string,
}
export interface StageTicketInputErrors {
	Date: string,
	Limit: string,
	TargetSeats: string,
}
export interface StageFormInputErrors {
	Name: string,
	Code: string,
	BccMail: string,
}
export interface StageCopyErrors {
	Title: string,
	Code: string,
}
export interface InquiryInputErrors {
	Name: string,
	Company: string,
	Email: string,
	Message: string,
}
export interface StageBookingSearchParam {
	FormName: string,
	TicketCode: string,
	SeatId: number,
	Name: string,
	Email: string,
	Checked: string,
	Tel: string,
	Contact: string,
	Payment: string,
	PaymentTypeId: string,
	Canceled: boolean,
	P:number,
}
export interface Mail {
	Subject: string,
	Body: string,
}
export interface MailErrors {
	Subject: string,
	Body: string,
}

export interface HelpCard {
	Title: string,
	Text?: string,
	Texts?: string[],
	Img: string,
	Notice: string,
	Btns: HelpCardBtn[],
}
export interface HelpCardBtn {
	Name: string,
	Link: string,
	Sample: string,
	Download: string,
	Color: "inherit" | "primary" | "secondary" | "default",
}
export interface ImageData {
	key: string,
	source: string,
	size: number,
	result: boolean,
}

export const MaxStages = 10;//登録できるイベント数

export class Inc {
	static readonly seats = ["A","B","C","D","E"];
	static readonly maxQuantity = 10;//一度に購入できるチケット枚数の上限
	static readonly errorText = "青字";
	static readonly maxMail = 3;//緊急メールを送れる回数
	static readonly refnums:Dataset[] = [
		{
			id:	 "0",
			label:  "なし",
		},
		{
			id:	 "1",
			label:  "あり",
		},
	];
	static readonly secrets:Dataset[] = [
		{
			id:	 "0",
			label:  "自分だけ見られる",
		},
		{
			id:	 "1",
			label:  "誰でも見られる",
		},
	];
	static readonly formViewings:Dataset[] = [
		{id:"1",label:"利用可"},
		{id:"0",label:"休止中"},
	]
	static readonly formHasSecret:Dataset[] = [
		{id:"1",label:"利用する"},
		{id:"0",label:"なし"},
	]	
	static readonly formHasSecretFirst:Dataset[] = [
		{id:"0",label:"なし"},
	]	
	static readonly contacts:Dataset[] = [
		{id:"1",label:"希望する"},
		{id:"9",label:"すでに届いている"},
		{id:"0",label:"希望しない"},
	]
	static readonly stockTypes:Dataset[] = [
		{id:"1",label:"使用する"},
		{id:"0",label:"使用しない"},
	]
	static readonly useLimits:Dataset[] = [
		{id:"0",label:"しない"},
		{id:"1",label:"する"},
	]
	static readonly checked:Dataset[] = [
		{id:"0",label:"未確認"},
		{id:"1",label:"確認済み"},
	]
	static readonly paymentTypes:Dataset[] = [
		{id:"1",label:"当日精算"},
		{id:"9",label:"その他"},
	]
	static readonly defaultPagerParam = ()=>{
		let param:PagerParam = {
			Start:0,
			End:0,
			MinPage:0,
			MaxPage:0,
			Current:1,
			Count:0,
		}
		return param;
	}
	static readonly defaultFormName = "代表";

	static readonly defaultProfile = ()=>{
		let profile:Profile = {
			name: "",
			dateKeys: [],
			version: 0,
			firstDateKey: "",
			lastDateKey: "",
			secret: "0",
			publicKey: "",
		};
		return profile;
	}
	static readonly defaultStage = ()=>{
		let defTickets: StageTicket[] = [];
		//let defSeats: StageSeat[] = [Inc.defaultStageSeat(),Inc.defaultStageSeat(),Inc.defaultStageSeat(),Inc.defaultStageSeat(),Inc.defaultStageSeat()];
		let defSeats: StageSeat[] = [Inc.defaultStageSeat()];
		let defStageForms: StageForm[] = [Inc.defaultStageForm()];
		defSeats[0].Name = "前売券";
		defSeats[0].Accepting = true;
		let stage:Stage = {
			Id: "",
			Code: "",
			Title: "",
			Organizer: "",
			Place: "",
			Tickets: defTickets,
			Seats: defSeats,
			Limit: "",
			StartDate: "",
			StartHour: "17",
			StartMinute: "00",
			EndDateTime: "",
			EndDays: 0,
			EndHours: 0,
			EndMinutes: 0,
			UseNumber: false,
			MaxQuantity: Inc.maxQuantity,
			Prefix: "",
			Desk: "",
			Url: "",
			Tel: "",
			Email: "",
			BccMail: "",
			Notice: "",
			Description: "",
			Message: "",
			Alert: "",
			Forms: defStageForms,
			Accepting: false,
			ImageKey: "",
			UseTel: false,
			UseMemo: true,
			UseEnq: true,
			UserId: "",
			Removable: true,
			Deleted: false,
			OpenedAt: "",
			CreatedAt: "",
			UpdatedAt: "",
		};
		return stage;
	}
	static readonly defaultStageTicket = ()=>{
		let stageTicket:StageTicket = {
			Code: "",
			Label: "",
			Date: "",
			Hour: "17",
			Minute: "00",
			TargetSeats: [false,false,false,false,false],
			Limit: null,
			UseLimit: false,
			Accepting: false,
		};
		return stageTicket;
	}
	static readonly defaultStageSeat = ()=>{
		let stageSeat:StageSeat = {
			Name: "",
			Price: null,
			Accepting: false,
		};
		return stageSeat;
	}
	static readonly defaultStageForm = ()=>{
		let stageForm:StageForm = {
			Name: "",
			Code: "",
			BccMail: "",
			Viewing: false,
		};
		return stageForm;
	}
	static readonly defaultStageCopy = ()=>{
		let stageCopy:StageCopy = {
			Title: "",
			Code: "",
		};
		return stageCopy;
	}
	static readonly defaultStageBooking = ()=>{
		let stageBooking:StageBooking = {
			Id: 0,
			StageId: "",
			TicketCode: "",
			SeatId: 0,
			Quantity: 0,
			Additionals: [],
			FormCode: "",
			FormName: "",
			Price: 0,
			TotalFee: 0,
			Name: "",
			Kana: "",
			Email: "",
			Tel: "",
			Memo: "",
			Contact: "0",
			Checked: false,
			Serial: "",
			Secret: "",
			Token: "",
			Payment: "",
			PaymentTypeId: "1",
			EntryMethod: "",
			CancelMethod: "",
			Canceled: false,
			CreatedAt: "",
			UpdatedAt: "",
		};
		return stageBooking;
	}
	static readonly defaultStageAgree = ()=>{
		let stageAgree:StageAgree = {
			StageId: "",
			UserId: "",
			Company: "",
			Name: "",
			Tel: "",
			Zip: "",
			Addr: "",
			Email: "",
			Fee: 0,
			Received: false,
			ReceivedTime: "",
			Send: 0,
			DateFrom: "",
			DateTo: "",
			Accepting: false,		
			CreatedAt: "",
			UpdatedAt: "",
		}
		return stageAgree;
	}
	static readonly defaultStageRange = ()=>{
		let stageRange:StageRange = {
			StageId: "",
			StartDate: "",
			StartHour: "17",
			StartMinute: "00",
			EndDays: 0,
			EndHours: 0,
			EndMinutes: 0,
		};
		return stageRange;
	}
	static readonly defaultStageFormConfig = ()=>{
		let stageFormConfig:StageFormConfig = {
			StageId: "0",
			UseTel: false,
			UseMemo: true,
			UseEnq: true,
		};
		return stageFormConfig;
	}
	static readonly defaultInquiry = ()=>{
		let inquiry:Inquiry = {
			UserId: "",
			Name: "",
			Company: "",
			Email: "",
			Message: "",
			CreatedAt: "",
			UpdatedAt: "",
		};
		return inquiry;
	}
	static readonly defaultStageInputErrors = ()=>{
		let errors:StageInputErrors = {
			Code: "",
			Title: "",
			Organizer: "",
			Place: "",
			Tickets: "",
			Seats: [],
			Desk: "",
			Url: "",
			Email: "",
			Tel: "",
			BccMail: "",
			Notice: "",
			Description: "",
			Message: "",
			Alert: "",
			Prefix: "",
		}
		return errors;
	}
	static readonly defaultStageBookingInputErrors = ()=>{
		let errors:StageBookingInputErrors = {
			TicketCode: "",
			SeatId: "",
			Quantity: "",
			Additionals: "",
			FormCode: "",
			FormName: "",
			Serial: "",
			Name: "",
			Kana: "",
			Email: "",
			Tel: "",
			Memo: "",
			Contact: "",
			StockTypeId: "",
			Payment: "",
		}
		return errors;
	}
	static readonly defaultStageAgreeInputErrors = ()=>{
		let errors:StageAgreeInputErrors = {
			Name: "",
			Company: "",
			Zip: "",
			Email: "",
			Addr: "",
			Tel: "",
		}
		return errors;
	}
	static readonly defaultStageTicketInputErrors = ()=>{
		let errors:StageTicketInputErrors = {
			Date: "",
			Limit: "",
			TargetSeats: "",
		}
		return errors;
	}
	static readonly defaultStageRangeInputErrors = ()=>{
		let errors:StageRangeInputErrors = {
			StartDate: "",
			EndDays: "",
			EndHours: "",
			EndMinutes: "",
		}
		return errors;
	}
	static readonly defaultStageFormInputErrors = ()=>{
		let errors:StageFormInputErrors = {
			Name: "",
			Code: "",
			BccMail: "",
		}
		return errors;
	}
	static readonly defaultStageCopyErrors = ()=>{
		let errors:StageCopyErrors = {
			Title: "",
			Code: "",
		}
		return errors;
	}
	static readonly defaultInquiryInputErrors = ()=>{
		let errors:InquiryInputErrors = {
			Name: "",
			Company: "",
			Email: "",
			Message: "",
		};
		return errors;
	}
	static readonly defaultStageBookingSearchParam = ()=>{
		let param:StageBookingSearchParam = {
			FormName: "",
			TicketCode: "",
			SeatId: 0,
			Name: "",
			Email: "",
			Checked: "",
			Tel: "",
			Contact: "",
			Payment: "",
			PaymentTypeId: "",
			Canceled: false,
			P:1,
		}
		return param;
	}
	static readonly defaultMail = ()=>{
		let errors:Mail = {
			Subject: "",
			Body: "",
		}
		return errors;
	}
	static readonly defaultMailInputErrors = ()=>{
		let errors:MailErrors = {
			Subject: "",
			Body: "",
		}
		return errors;
	}
}
