import { createMuiTheme } from '@material-ui/core/styles';

export const Theme = createMuiTheme({
    palette: {
        primary: {
            light: '#EA5244',
            main: '#DA4234',
            dark: '#CA3224',
            contrastText: '#FFF2CA',
        },
        secondary: {
            light: '#FFFBC7',
            main: '#FFF2CA',
            dark: '#F1DCA9',
			contrastText: '#000000',
		},
		error: {
			light: '#0000FF',
			main: '#0000DD',
			dark: '#0000BB',
			contrastText: '#FFFFFF',
		}
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: "#403B2A",
            }
        },
    },

});
