import React,{useState} from 'react';
import {Link,useLocation} from 'react-router-dom'//yarn add @types/react-router-dom
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@material-ui/icons/Help';
import MailIcon from '@material-ui/icons/Mail';
import PasswordIcon from '@material-ui/icons/VpnKey';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
//import Drawer from '@material-ui/core/Drawer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Logo from './Logo';
import EmailInput from './EmailInput';
import EmailSend from './EmailSend';
import {Theme} from './Theme';
import {UserContainer} from './UserContainer';
import "./TopBar.scss";

interface Props {
    redirect(to:string):void,
}

const TopBar = (props:Props)=> {
    const userState = UserContainer.useContainer();
    const [states,setStates] = useState({
        isPersonMenuOpen: false,
		isEmailInputOpen: false,
		isEmailSendOpen: false,
		//limited: false,
        redirectTo: "",
    });
	const openPersonMenu = ()=>{
		if( !states.isPersonMenuOpen ){
			setStates({...states,isPersonMenuOpen:true,isEmailInputOpen:false,isEmailSendOpen:false});
		}
	}
	const closePersonMenu = ()=>{
		if( states.isPersonMenuOpen ){
			setStates({...states,isPersonMenuOpen:false,isEmailInputOpen:false,isEmailSendOpen:false});
		}
    }
    const logout = ()=>{
        userState.logout();
        setStates({...states,isPersonMenuOpen:false,isEmailInputOpen:false,isEmailSendOpen:false});
        props.redirect("/login");
    }
	const openEmailInput = ()=>{
		setStates({...states,isPersonMenuOpen:false,isEmailInputOpen:true,isEmailSendOpen:false});
	}
	const closeEmailInput = (send:boolean)=>{
		setStates({...states,isPersonMenuOpen:false,isEmailInputOpen:false,isEmailSendOpen:send});
	}
	const closeEmailSend = ()=>{
		setStates({...states,isPersonMenuOpen:false,isEmailInputOpen:false,isEmailSendOpen:false});
	}
    const location = useLocation();
    //useEffect( ()=>{
    //    if(location.pathname.match(/stage\/booking\/[0-9a-z]+\/[^\/]+\/[^\/]+$/)){
	//		setStates({...states,limited:true});
	//	}
	//},[location]);
	
	let provider = "メール";
	if( userState.user ){
		if( userState.user.providerData[0] ){
			if( userState.user.providerData[0].providerId==="twitter.com" ){
				provider = "twitter";
			}
			if( userState.user.providerData[0].providerId==="google.com" ){
				provider = "google";
			}
		}
	}

    return (
		<React.Fragment>
        <AppBar position="fixed" className="appbar" style={{zIndex:Theme.zIndex.drawer+1}}>
            <Toolbar variant="dense" className="toolbar">
                <Logo isTop={location.pathname==="/"}/>
                <div className="menuPerson">
					<a href="https://twitter.com/r7ticket" rel="noopener noreferrer" target="_blank"><IconButton color="default" className="tweetButton" aria-label="twitter" style={{marginRight:"0em"}}>
						<FontAwesomeIcon icon={['fab','twitter-square']} style={{color:"#FFF2CA"}}/>
					</IconButton></a>
					<Link to="/help"><IconButton color="default" className="personButton" aria-label="guide" style={{marginRight:"0.2em"}}>
                        <HelpIcon style={{fontSize:"140%"}}/>
                    </IconButton></Link>
				{!userState.user &&
					<Link to="/login" style={{marginLeft:"0.5em",textDecoration:"none"}}><Button variant="contained" color="secondary" style={{color:'#DA4234'}}>
						<FontAwesomeIcon icon="user" style={{marginRight:"0.5em"}}/>ログイン
					</Button></Link>
				}
                {userState.user &&
                    <IconButton color="default" className="personButton" aria-label="person" onClick={openPersonMenu}>
                        <AccountBoxIcon style={{fontSize:"140%"}}/>
                    </IconButton>
                }
                </div>
            </Toolbar>
            {states.isPersonMenuOpen &&
				<div style={{position:"fixed",top:"2.5em",right:"10px",maxWidth:"300px",minWidth:"280px",zIndex:9000}}>
					<ClickAwayListener onClickAway={closePersonMenu}>
						<Paper id="menu-list-grow" style={{paddingTop:"0.20em"}}>
							<div style={{padding:"1em"}}>
								<div className="personMenu">
									<React.Fragment>
									<h3 className="withIcon"><VerifiedUserIcon/>アカウント</h3>
									<ul className="personData">
									{userState.user?.displayName &&
										<li>
											{userState.user?.displayName}
										</li>
									}
										<li style={{fontSize:"90%",color:"#999999"}}>{provider}でログイン</li>
									</ul>
									</React.Fragment>
									{userState.user?.email &&
									<React.Fragment>
									<h3 className="withIcon"><MailIcon/>メールアドレス</h3>
									<ul className="personData">
										<li>
											{userState.user?.email}
										</li>
										{provider==='メール' &&
										<li style={{textAlign:'right'}}>
											<Button variant="text" color="primary" onClick={openEmailInput} startIcon={<ArrowIcon/>}>メールアドレス変更</Button>
										</li>
										}
									</ul>
									</React.Fragment>
									}									
								</div>
								<FormControl fullWidth={true}>
									<Button variant="contained" color="primary" onClick={logout}>ログアウト</Button>
								</FormControl>
							</div>
							<div style={{backgroundColor:"#EEEEEE",textAlign:"center"}}>
								<Button onClick={closePersonMenu} color="default" style={{width:"100%"}}><CloseIcon/>閉じる</Button>
							</div>
						</Paper>
					</ClickAwayListener>
				</div>
			}
        </AppBar>
		<EmailInput isOpen={states.isEmailInputOpen} close={closeEmailInput}/>
		<EmailSend isOpen={states.isEmailSendOpen} close={closeEmailSend}/>
		</React.Fragment>
    )
}

export default TopBar;