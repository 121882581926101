import React, { useState,useEffect } from "react";
import {useDropzone} from 'react-dropzone';
import imageCompression from "browser-image-compression";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import UploadFileIcon from '@material-ui/icons/Image';
import CancelIcon from '@material-ui/icons/Close';
import LoadImage from './LoadImage';
import {ImageData} from './Inc';
import {Tools} from './Tools';

interface ImageUploadProps {
	title: string,
	message?: string,
	error?:boolean,
	image: ImageData;
	maxSize?: number,
	maxWidth?: number,
	helper?: string,
	cancel(): void,
	update(result:ImageData):void,
}

const ImageSelect: React.FC<ImageUploadProps> =  (props)=> {
	const defaultStates = {
		isLoading: false,
		isYesNo: false,
		isTest: false,
		deleted: false,
		error: "",
		currentIndex: 0,
	};
	const [states,setStates] = useState(defaultStates);

	const file2string = async (file:File,func:any)=>{
		//console.log(file);

		const reader = new FileReader()
		let maxSize = 3;
		let maxWidth = 800;

		if( props.maxSize ){
			maxSize = props.maxSize;
		}
		if( props.maxWidth ){
			maxWidth = props.maxWidth;
		}

		let size = 0;
		let res = {} as ImageData;
		reader.onabort = () => {
			console.log('file reading was aborted');
			res.result = false;
			return res;
		}
		reader.onerror = () => {
			console.log('file reading has failed');
			res.result = false;
			return res;
		}
		reader.onload = () => {
			//console.log("d");
			const base64Str = reader.result
			if( base64Str ){
				res.key = "";
				res.source = base64Str.toString();
				res.size = size;
				res.result = true;
				func(res);
			}
		}
		const compressOptions = {
			maxSizeMB: maxSize,
			maxWidthOrHeight: maxWidth,
			fileType: "image/jpeg",
		};
		//console.log("b");
		const compressedFile = await imageCompression(file, compressOptions);
		//console.log("c1");
		size = compressedFile.size;
		reader.readAsDataURL(compressedFile);
		//reader.readAsDataURL(file);
	}

	//const removeImage = (index:number)=>{
		//resetErrors();
	//	setStates({...states,error:'',isYesNo:false,deleted:true});

		//let images = [...props.images];
		//images.splice(index,1);
		//images[index] = {
		//	name: images[index].name,
		//	source:"deleted",
		//	size:0,
		//	result:true,
		//};
		//console.log(images);
	//	props.update({} as ImageData);
	//}

	const accepted = async ()=>{
		console.log("accepted");
	}
	const rejected = ()=>{
		console.log("rejected");
	}
    const onDrop = async (acceptedFiles:File[]) => {
        //console.log('acceptedFiles:', acceptedFiles);
		if(acceptedFiles.length===0){
			setStates({...states,isLoading:false,error:'nofile'});
			return;
		}
		
		setStates({...states,isLoading:true,error:''});
		let error = "";
		if( acceptedFiles[0] ){
			if (
				[
					"image/gif",
					"image/jpeg",
					"image/png",
				].includes(acceptedFiles[0].type)
			) {
				await file2string(acceptedFiles[0],(result:ImageData)=>{
					props.update(result);
				});
			} else {
				error = "wrongtype";
			}
		}
		setStates({...states,isLoading:false,error:error});
    };

	useEffect(()=>{
		setStates(defaultStates);
	},[])



	let cl = "formDropzone";
	if( props.error ){
		cl = cl + " formDropzoneError";
	}
	const { getRootProps, getInputProps, open } = useDropzone({noClick:true,onDrop:onDrop,maxFiles:1,multiple:false,accept:["image/png","image/jpeg","image/gif"],onDropAccepted:accepted,onDropRejected:rejected});

	if( states.isLoading ){
		return <Box className={cl} style={{padding:"4em 0",textAlign:"center"}}><CircularProgress /></Box>
	}

	const errorMessage:{[key:string]:string;} = {
		'nofile':'アップロードできないファイルです',
		'wrongtype':'PNG / JPEG / GIF 以外のファイル形式は利用できません',
	};

	let imageSrc = '';
	if( props.image.key && props.image.key!=='' ){
		imageSrc = Tools.getUploadImgPath(props.image.key);
	} else if( props.image.source && props.image.source!=='' ){
		imageSrc = props.image.source;
	}
  	return (
		<React.Fragment>
			<input {...getInputProps()}/>
			{imageSrc!=='' ? (
				<div {...getRootProps()} style={{position:'relative'}}>
					<LoadImage src={imageSrc}/>
					<div style={{position:'absolute',bottom:'0em',padding:'0'}}>
						<IconButton onClick={open}><UploadFileIcon style={{fontSize:'100%',color:'#000000',backgroundColor:'#FFFFFF',opacity:0.8,borderRadius:'0.2em'}}/></IconButton>
					</div>
					<div style={{position:'absolute',top:'0em',right:'0em',padding:'0'}}>
						<IconButton onClick={props.cancel}><CancelIcon style={{fontSize:'80%',color:'#000000',backgroundColor:'#FFFFFF',opacity:0.8,borderRadius:'1em'}}/></IconButton>
					</div>
				</div>
			):(
			<div {...getRootProps()} className={cl}>
				<p style={{textAlign:'center',padding:'2em 0'}}>
					<IconButton onClick={open}><UploadFileIcon style={{fontSize:'280%',color:"#999999"}}/></IconButton>
				</p>
				{props.message && props.message!=="" &&
				
				<p style={{textAlign:'center',fontSize:'80%',padding:'0em 1em 1.5em 1em',color:'#666666'}}>
					{props.message}
				</p>
				}
			</div>

			)}
			{states.error!=='' ? (
				<FormHelperText style={{color:"#FF0000"}}>※{errorMessage[states.error]}</FormHelperText>
			):(
				<FormHelperText>※{props.helper}</FormHelperText>
			)}
		</React.Fragment>
	);
};

export default ImageSelect;
