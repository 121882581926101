import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Tools} from './Tools';
import ColInput from './ColInput';
import Toast from './Toast';
import {UserContainer} from './UserContainer';
import {Inc,Stage,StageAgree} from './Inc';
import DialogHeader from './DialogHeader';
import {Theme} from './Theme';

interface Props {
	close():void,
	stage:Stage,
	agree?:StageAgree,
	add(agree:StageAgree):void,
	update(agree:StageAgree):void,
	isOpen:boolean,
}

const StageAgreeInput = (props:Props)=> {
	const userState = UserContainer.useContainer();
	const [agree,setAgree] = useState(Inc.defaultStageAgree());
	const [vali,setVali] = useState({
		errors:Inc.defaultStageAgreeInputErrors(),
		message:"",
	});
	const [toast,setToast] = useState("");
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	
	const change = (name:string,value:string)=>{
		setAgree({...agree,[name]:value});
	}

	const validate = ()=>{
		let errors = Inc.defaultStageAgreeInputErrors();
		let flag = false;

		if( agree.Name==="" ){
			errors.Name = "入力して下さい";
			flag = true;
		} else if( agree.Name.length>50 ){
			errors.Name = "50文字以内にして下さい";
			flag = true;
		}
		if( agree.Company==="" ){
			errors.Company = "入力して下さい";
			flag = true;
		} else if( agree.Company.length>50 ){
			errors.Company = "50文字以内にして下さい";
			flag = true;
		}
		if( agree.Tel==="" ){
			errors.Tel = "入力して下さい";
			flag = true;
		} else if( Tools.validateTel(agree.Tel)===false ){
			errors.Tel = "不正な電話番号です";
			flag = true;
		}
		if( agree.Zip==="" ){
			errors.Zip = "入力して下さい";
			flag = true;
		} else if( Tools.validateZip(agree.Zip)===false ){
			errors.Zip = "不正な郵便番号です";
			flag = true;
		}
		if( agree.Addr==="" ){
			errors.Addr = "入力して下さい";
			flag = true;
		} else if( agree.Addr.length>100 ){
			errors.Addr = "100文字以内にして下さい";
			flag = true;
		}
		if( agree.Email==="" ){
			errors.Email = "入力して下さい";
			flag = true;
		} else if( Tools.validateMail(agree.Email)===false ){
			errors.Email = "不正なメールアドレスです";
			flag = true;
		}
		if( agree.Email.length>100 ){
			errors.Email = "100文字以内にして下さい";
			flag = true;
		}

		if( flag ){
			setVali({errors:errors,message:Inc.errorText+"部分を修正して下さい"});
			return false
		} else {
			return true
		}

	}

	const add = async ()=>{
		const result = validate();
		if( !result ){
			return;
		}
		if(!userState.user){
			return;
		}

		//props.add({...agree,StageId:props.stage.Id,UserId:userState.user.uid });
		props.update({...agree,StageId:props.stage.Id,UserId:userState.user.uid });
	}

	const update = async ()=>{
		const result = validate();
		if( !result ){
			return;
		}

		props.update(agree);
	}

	const closeToast = ()=>{
		setToast("");
	}

	useEffect(()=>{
		if( props.stage.Id!=="" ){
			if( props.agree ){
				//Tools.addTicketCode(props.agree,props.stage.doc);
				setAgree({...props.agree});
			} else {
				setAgree(Inc.defaultStageAgree());
			}
			setVali({
				errors:Inc.defaultStageAgreeInputErrors(),
				message:"",
			});

			//setStates({...states});
		}
	},[props.stage.Id,props.agree,props.isOpen]);

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
				<DialogHeader close={props.close}/>
				<DialogContent dividers>
					<div className="confirmRoot">
						<h2>利用者情報</h2>
						<Grid container spacing={1} style={{marginBottom:"2em"}}>
							<Grid item xs={12} sm={3} className="formName">利用者名</Grid>
							<Grid item xs={12} sm={9}>
								<ColInput id="Name" noName={true} dense={true} error={vali.errors.Name} placeholder="50文字以内" helper="団体の場合は担当者名" type="text" name="名前" value={agree.Name} change={change}/>
							</Grid>
							<Grid item xs={12} sm={3} className="formName">団体名</Grid>
							<Grid item xs={12} sm={9}>
								<ColInput id="Company" noName={true} dense={true} error={vali.errors.Company} placeholder="50文字以内" helper="省略可" type="text" name="団体名" value={agree.Company} change={change}/>
							</Grid>
							<Grid item xs={12} sm={3} className="formName">電話番号</Grid>
							<Grid item xs={12} sm={9}>
								<ColInput id="Tel" noName={true} pattern="tel" dense={true} error={vali.errors.Tel} placeholder="000-0000-0000" type="text" name="電話番号" value={agree.Tel} change={change}/>
							</Grid>
							<Grid item xs={12} sm={3} className="formName">郵便番号</Grid>
							<Grid item xs={12} sm={9}>
								<ColInput id="Zip" noName={true} hankaku={true} dense={true} error={vali.errors.Zip} placeholder="000-0000" type="text" name="郵便番号" value={agree.Zip} change={change}/>
							</Grid>
							<Grid item xs={12} sm={3} className="formName">住所</Grid>
							<Grid item xs={12} sm={9}>
								<ColInput id="Addr" noName={true} dense={true} error={vali.errors.Addr} placeholder="100文字以内" type="text" name="住所" value={agree.Addr} change={change}/>
							</Grid>
							<Grid item xs={12} sm={3} className="formName">Ｅメール</Grid>
							<Grid item xs={12} sm={9}>
								<ColInput id="Email" noName={true} pattern="email" dense={true} error={vali.errors.Email} placeholder="100文字以内" type="text" name="Ｅメール" value={agree.Email} change={change}/>
							</Grid>
						</Grid>
					</div>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
					{props.agree && props.agree.Name!=="" ? (
						<Button variant="contained" color="secondary" onClick={update}>この内容で更新する</Button>
					):(
						<Button variant="contained" color="secondary" onClick={add}>登録する</Button>
					)}
				</DialogActions>
				<Toast close={closeToast} mes={toast}/>
		</Dialog>
	);
}

export default StageAgreeInput;
