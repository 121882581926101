import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import DialogHeader from './DialogHeader';
import {Theme} from './Theme';
import ColInput from './ColInput';
import Toast from './Toast';
//import Ajax from './Ajax';
import Loading from './Loading';
import store from './store';
import {Inc,Stage} from './Inc';


interface Props {
	close():void,
	add(stage:Stage):void,
	stage:Stage,
	isOpen:boolean,
}

const StageCopyInput = (props:Props)=> {
	const [toast,setToast] = useState("");
	const [isLoading,setIsLoading] = useState(false);
	const [copy,setCopy] = useState(Inc.defaultStageCopy());
	const [vali,setVali] = useState(Inc.defaultStageCopyErrors());
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));

	const closeToast = ()=>{
		setToast("");
	}

	const change = (name:string,value:string)=>{
		setCopy({...copy,[name]:value});
	}

	const validate = async ()=>{
		const errors = Inc.defaultStageCopyErrors();
		let flag = true;
		if( copy.Title==="" ){
			errors.Title = "タイトルを入力してください";
			flag = false;
		}
		if( copy.Code==="" ){
			errors.Code = "コードを入力してください";
			flag = false;
		} else {
			//重複チェック
			await store.searchStage(copy.Code).then((stage)=>{
				errors.Code = "このコードはほかのイベントで使われています";
				flag = false;
			}).catch((error)=>{
				if(error.message==="notFound"){

				} else {
					console.log("searchStage",error);
				}
			})
		}
		if( flag ){
			props.add({...props.stage,Title:copy.Title,Code:copy.Code,Accepting:false,StartDate:"",StartHour:"17",StartMinute:"00",EndDateTime:"",CreatedAt:"",UpdatedAt:""})
		} else {
			setToast(Inc.errorText+"部分を修正して下さい");
			setVali(errors);
		}
	}

	useEffect(()=>{
		if(!props.isOpen){
			setIsLoading(false);
			setCopy(Inc.defaultStageCopy());
			setVali(Inc.defaultStageCopyErrors());
		}
	},[props.isOpen]);

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
				<DialogHeader close={props.close}/>
				<DialogContent dividers>
				{props.stage &&
				<div className="confirmRoot">
					<h2>イベントのコピー</h2>
					<div style={{marginBottom:"2em"}}>
						<b>{props.stage.Title}</b>をコピーして新しいイベントを作成します。<br/>
						新しいタイトルとコードを入力してから、「作成する」を押してください。<br/>
						※どちらもあとで変更できます。<br/>
					</div>
					<Grid container spacing={2}>
						<Grid item xs={3} sm={2} className="formName">タイトル</Grid>
						<Grid item xs={9} sm={10}>
							<ColInput name="タイトル" id="Title" noName={true} dense={true} type="text" error={vali.Title} value={copy.Title} change={change}/>
						</Grid>
						<Grid item xs={3} sm={2} className="formName">コード</Grid>
						<Grid item xs={9} sm={10}>
							<ColInput name="コード" id="Code" noName={true} dense={true} type="text" error={vali.Code} value={copy.Code} placeholder="半角英数字6～20文字" maxLength={20} minLength={6} helper="省略するとコードが自動作成されます" hankaku={true} change={change}/>
						</Grid>
					</Grid>
				</div>
				}
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
					<Button variant="contained" color="secondary" onClick={validate}>作成する</Button>
				</DialogActions>
				<Toast close={closeToast} mes={toast}/>
				<Loading isLoading={isLoading}/>
		</Dialog>
	);
}

export default StageCopyInput;
