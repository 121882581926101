import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import Paper from '@material-ui/core/Paper';
//import Title from './Title';
import Loading from './Loading';
import Toast from './Toast';
import Ajax from './Ajax';
import {Inquiry,SiteName} from './Inc';
import InquiryInput from './InquiryInput';
import InquiryResult from './InquiryResult';
import {UserContainer} from './UserContainer';

interface Props {
    
}

const HelpIndex = (props:Props)=> {
    const [states,setStates] = useState({
        email:"",
        isSend:false,
		isLoading:false,
		isInquiry:false,
		result:"",
    })
    //const [isFinished,setIsFinished] = useState(false);
    const [toast,setToast] = useState("");
    const userState = UserContainer.useContainer();
    const closeToast = ()=>{
        setToast("");
	}
	const openInquiryInput = ()=>{
		setStates({...states,isInquiry:true});
	}
	const closeInquiryInput = ()=>{
		setStates({...states,isInquiry:false});
	}
	const closeInquiryResult = ()=>{
		setStates({...states,result:""});
	}
	const send = async (inquiry:Inquiry)=>{
		let uid = "";
		if(userState.user){
			uid = userState.user.uid;
		}
		setStates({...states,isLoading:true});
		await Ajax.addInquiry({...inquiry,UserId:uid}).then((res)=>{
			//console.log(res);
			if( res.ok ){
				setStates({...states,isInquiry:false,isLoading:false,result:"success"});
				//setStates({...states,isLoading:false,result:"success"});
			} else {
				setStates({...states,isLoading:false,result:"failure"});
			}
		}).catch((error)=>{
			//setToast("送信できませんでした");
			setStates({...states,isInquiry:false,isLoading:false,result:"failure"});
		});
	}

	useEffect(()=>{
		document.title = "ヘルプ｜"+SiteName;
		window.scrollTo(0, 0);
	},[]);

    if( !userState.ready ){
        return (null);
    }

    return (
        <div id="login" className="mediumRoot noStageRoot">
			<div className="help">
				<h2>ヘルプ</h2>
				<div className="menus">
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} md={4}>
						<Link to="/help/about" className="link"><Card className="cardRootIndex">
							<CardContent>
								<Typography gutterBottom variant="h6" color="primary" component="h3">
									R7 TICKET SERVICEとは？
								</Typography>
								<Typography variant="body2" color="textPrimary" component="p">
								ライブや演劇などのイベントに便利な予約受付フォームです。とにかくシンプルで安定したサービスを目指しています。
								</Typography>
							</CardContent>
							<CardActions>
							</CardActions>
						</Card></Link>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<Link to="/help/outline" className="link"><Card className="cardRootIndex">
							<CardContent>
								<Typography gutterBottom variant="h6" color="primary" component="h3">
									大まかな流れ
								</Typography>
								<Typography variant="body2" color="textPrimary" component="p">
									フォームをつくって予約を受け付けるまでの「大まかな流れ」を説明します。
								</Typography>
							</CardContent>
							<CardActions>
							</CardActions>
						</Card></Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link to="/help/make" className="link"><Card className="cardRootIndex">
							<CardContent>
								<Typography gutterBottom variant="h6" color="primary" component="h3">
									イベントをつくる
								</Typography>
								<Typography variant="body2" color="textPrimary" component="p">
									まず最初にイベントを登録しましょう。<br/>
									ここではイベントの入力項目について説明します。<br/>
								</Typography>
							</CardContent>
							<CardActions>
							</CardActions>
						</Card></Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link to="/help/ticket" className="link"><Card className="cardRootIndex">
							<CardContent>
								<Typography gutterBottom variant="h6" color="primary" component="h3">
									チケットを登録する
								</Typography>
								<Typography variant="body2" color="textPrimary" component="p">
									チケットの日時や種類を登録する方法について説明します。<br/>
								</Typography>
							</CardContent>
							<CardActions>
							</CardActions>
						</Card></Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link to="/help/form" className="link"><Card className="cardRootIndex">
							<CardContent>
								<Typography gutterBottom variant="h6" color="primary" component="h3">
									フォームを設定する
								</Typography>
								<Typography variant="body2" color="textPrimary" component="p">
									出演者専用のフォームを作成したりフォームに表示する項目を編集するなど、受付フォームの設定方法について説明します。
								</Typography>
							</CardContent>
							<CardActions>
							</CardActions>
						</Card></Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link to="/help/open" className="link"><Card className="cardRootIndex">
							<CardContent>
								<Typography gutterBottom variant="h6" color="primary" component="h3">
									予約受付を始める
								</Typography>
								<Typography variant="body2" color="textPrimary" component="p">
									フォームを必要なだけ追加して、予約の受付期間を決めたら準備完了です。
								</Typography>
							</CardContent>
							<CardActions>
							</CardActions>
						</Card></Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link to="/help/booking" className="link"><Card className="cardRootIndex">
							<CardContent>
								<Typography gutterBottom variant="h6" color="primary" component="h3">
									予約をもらう
								</Typography>
								<Typography variant="body2" color="textPrimary" component="p">
									お客様用フォームのほかに、管理者用フォームやCSVファイルのアップロードによる予約方法などを説明します。<br/>
								</Typography>
							</CardContent>
							<CardActions>
							</CardActions>
						</Card></Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link to="/help/mail" className="link"><Card className="cardRootIndex">
							<CardContent>
								<Typography gutterBottom variant="h6" color="primary" component="h3">
									緊急メール
								</Typography>
								<Typography variant="body2" color="textPrimary" component="p">
									公演の中止や新型コロナウイルス対応関連など緊急時には予約者にメールを一斉送信できます。<br/>
								</Typography>
							</CardContent>
							<CardActions>
							</CardActions>
						</Card></Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link to="/help/fee" className="link"><Card className="cardRootIndex">
							<CardContent>
								<Typography gutterBottom variant="h6" color="primary" component="h3">
								仕様と利用料金
								</Typography>
								<Typography variant="body2" color="textPrimary" component="p">
								仕様（利用条件）と利用料金についての説明です。
								</Typography>
							</CardContent>
							<CardActions>
							</CardActions>
						</Card></Link>
					</Grid>
				</Grid>
				</div>
				<div className="menu2">
					<Link to="/help/term" className="link">利用規約</Link><br/>
					<Link to="/help/privacy" className="link">プライバシーポリシー</Link><br/>
				</div>
				<div className="contact">
					<p>その他のお問い合わせはこちら</p>
					<Button variant="contained" color="primary" onClick={openInquiryInput}>お問い合わせフォーム</Button>
				</div>
				<div className="copyright">
					<p>
						<FontAwesomeIcon icon="copyright"/>有限会社レトロインク 2021-2024<br/>
						<a href="https://www.retro-ink.com/" target="_blank" rel="noopener noreferrer" className="link">https://www.retro-ink.com/</a>
					</p>
				</div>
			</div>
			<InquiryInput isOpen={states.isInquiry} send={send} close={closeInquiryInput}/>
			<InquiryResult result={states.result} close={closeInquiryResult}/>
            <Toast mes={toast} close={closeToast}/>
            <Loading isLoading={states.isLoading}/>
        </div>
    );
}

export default HelpIndex;
