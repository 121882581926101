import React,{useState} from 'react';
import {Redirect} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Title from './Title';
import Loading from './Loading';
import Toast from './Toast';
import {SiteName} from './Inc';
import {UserContainer} from './UserContainer';

interface Props {
    result:boolean,
}

const MailResult = (props:Props)=> {
    const userState = UserContainer.useContainer();

	if( !userState.ready ){
        return (null);
    }

	let title = "メールアドレス変更";
	if( props.result===false ){
		title += "（失敗）";
	}

    return (
        <div id="login" className="miniRoot">
            <Paper className="paper">
                <Title size={3} text={title} icon={"envelope"}></Title>
				{props.result ? (
                <div className="talk">
					<p>メールアドレスを変更いたしました</p>
					<p>新しいメールアドレスに確認メールを送信しましたので、ちゃんと受信できることを確認してください</p>
					<p style={{marginTop:'3em',fontSize:'0.8em',padding:'1em',backgroundColor:'#F5F5F5'}}>
						※新しいメールアドレスが会員登録時のメールアドレスと異なる場合は、会員登録時のメールアドレスには変更手続きをキャンセルするURLを送信してあります
					</p>
                </div>
				):(
                <div className="talk">
                    <p>再認証が正しく行われませんでした</p>
                    <p>メールアドレス変更の手続きを中断します</p>
                </div>
				)}
            </Paper>
        </div>
    );
}

export default MailResult;
