import React,{useEffect,useState} from 'react';
import {Link,Redirect} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ClimbUp from './ClimbUp';
import StageCopyInput from './StageCopyInput';
import Loading from './Loading';
import {Tools} from './Tools';
import Toast from './Toast';
import {UserContainer} from './UserContainer';
import {Inc,Info,Stage,SiteName,MaxStages} from './Inc';
import titleImg from './img/title.png';
import Ajax from './Ajax';
//import {Payjp} from 'https://js.pay.jp/v2/pay.js';

interface Props {
    
}

const Main = (props:Props)=> {
    const [states,setStates] = useState({
		stages:[] as Stage[],
		infos:[] as Info[],
        isLoading:true,
    })
    const [copy,setCopy] = useState(Inc.defaultStage());
	const [seeId,setSeeId] = useState("");
	const [toast,setToast] = useState("");
	const userState = UserContainer.useContainer();

	//const payjpRef = useRef(null);
	const payjpId = Math.random().toString();

    const seeStage = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.index ){
			return;
        }
        setSeeId(states.stages[ parseInt(e.currentTarget.dataset.index) ].Id);
    }
    const openCopy = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.index ){
			return;
        }
		setCopy(states.stages[ parseInt(e.currentTarget.dataset.index) ]);
		//console.log("copy",states.stages[ parseInt(e.currentTarget.dataset.index) ]);
    }
    const closeCopy = ()=>{
        setCopy(Inc.defaultStage());
	}
	const closeToast = ()=>{
		setToast("");
	}
    const refresh = ()=>{
		setStates({...states,isLoading:true});
        setCopy(Inc.defaultStage());
        load();
	}
	const addStage = async (stage:Stage)=>{
		//setIsLoading(true);
		setStates({...states,isLoading:true});
		Ajax.setToken(userState.token);
		await Ajax.addStage(stage,'').then((res)=>{
			//console.log(ref);
			//props.update();
			if( res.ok ){
				setCopy(Inc.defaultStage());
				load();
			} else {
				setStates({...states,isLoading:false});
				setToast("コピーできませんでした");
			}
		}).catch((error)=>{
			setStates({...states,isLoading:false});
			setToast("コピーできませんでした");
			console.log(error);
		})
	}
    const load = async ()=>{
		let stages:Stage[] = [];
		let infos:Info[] = [];
		try {
			let uid:string = "";
			if(userState.user){
				uid = userState.user.uid;
			}
			const res = await Ajax.getStages(uid);
			//console.log(res);
			if( res.ok ){
				stages = res.stages;
				infos = res.infos;
			}
		} catch(error) {
            console.log("error",error);
        }
		setStates({...states,stages:stages,infos:infos,isLoading:false});
	}
	//const openPayjp = ()=>{
    //    const script = document.createElement("script");
	//	script.type="text/javascript";
	//	script.src="https://checkout.pay.jp/";
	//	script.setAttribute("class","payjp-button");
	//	script.setAttribute("data-key","pk_test_b38be423f975efa4d0f79465");
	//	script.setAttribute("data-submit-text","トークンを作成する");
	//	script.setAttribute("data-partial","true");
	//	script.setAttribute("data-on-created","reactPayjpCheckoutOnCreated");
	//	script.setAttribute("data-on-failed","reactPayjpCheckoutOnCreated");

	//	const elm = document.getElementById(payjpId);
	//	if( elm ){
	//		//console.log("ok");
	//		elm.replaceWith(script);
	//	}
	//}
	//const onCreated = (res:any)=>{
	//	Ajax.sendPayjpToken(res.id);
	//}
	//const onFailed = (res:any)=>{
	//	console.log("failed",res);
	//}

    useEffect( ()=>{
		//(window as any).reactPayjpCheckoutOnCreated = onCreated;
		//(window as any).reactPayjpCheckoutOnFailed = onFailed;
		//openPayjp();
		document.title = SiteName;
		const start = async ()=>{
			await load();
		}
        if( userState.ready ){
			start()
		}
		//return ()=>{
		//	(window as any).reactPayjpCheckoutOnCreated = null;
		//}
	},[userState.ready,userState.user]);

    if( !userState.ready ){
        return (null);
    }

    if( seeId!=="" ){
        const to = "/stage/detail/"+seeId;
        return (
            <Redirect to={to}/>
        )
    }

    return (
		<div>
			<div className="topTitle">
				<h1><img src={titleImg} alt="R7 TICKET SERVICE"/></h1>
				<div className="special">
					<div className="text">
						<p>2022年3月 正式版になりました</p>
						<ul>
							<li>・当日精算に特化したチケット予約サービスです。</li>
							<li>・すべての機能を無料でご利用いただけます。</li>
							<li>・詳細はヘルプをご覧ください。</li>
						</ul>
					</div>
				</div>
				<div className="add">
					{userState.user && states.stages.length<MaxStages ? (
					<Link to="/stage/new" className="link"><Button variant="contained" size="small" color="primary"><FontAwesomeIcon icon="edit" style={{marginRight:"0.5em"}}/>イベントをつくる</Button></Link>
					):(
					<Button variant="contained" color="primary" size="small" disabled={true}><FontAwesomeIcon icon="edit" style={{marginRight:"0.5em"}}/>イベントをつくる</Button>
					)}
					<Link to="/help" className="link"><Button variant="outlined" color="secondary" size="small" style={{marginLeft:"0.25em"}}><FontAwesomeIcon icon="question-circle" style={{marginRight:"0.5em"}}/>ヘルプを見る</Button></Link>
				</div>
			</div>
			<div id="stage_list" className="mediumRoot">
				<div style={{backgroundColor:"#FFFFFF"}}>
					<Grid container spacing={0}>
						<Grid item xs={12} sm={12} md={7} lg={7}>
							<div className="topLeft">
								<div className="refresh">
									{states.stages.length>0 &&
									<Tooltip title="最新の情報に更新します"><Button variant="outlined" color="default" size="small" onClick={refresh}><AutorenewIcon style={{marginRight:"0.25em"}}/>表示を更新</Button></Tooltip>
									}
								</div>
								<div className="stages">
								{states.stages.map((stage:Stage,index:number)=>{
									const key = "stage"+index;
									//let beforeFlag = false;
									//let afterFlag = false;
									let startTime = "";
									if( stage.StartDate && stage.EndDateTime ){
										//const st = Date.parse(stage.StartDate+" "+stage.StartHour+":"+stage.StartMinute);
										//const en = Date.parse(stage.EndDateTime);
										//const now = Date.now();
										//if( st>now ){
										//	beforeFlag = true;
										//}
										//if( en<now ){
										//	afterFlag = true;
										//}
										startTime = stage.StartDate.substr(5,5)+" "+stage.StartHour+":"+stage.StartMinute;
									}
									const status = Tools.getStageStatus(stage);
									return (
										<div key={key} className="stage">
											<div className="text" onClick={seeStage} data-index={index}>
												<div className="title">{stage.Title}</div>
												<div className="organizer">{stage.Organizer}</div>
												{stage.Tickets &&
												<div className="date">
													<FontAwesomeIcon icon="clock" style={{marginRight:"0.5em"}}/>
													{stage.Tickets[0].Date}
													{stage.Tickets.length>1 &&
													<span>
													～{stage.Tickets[ stage.Tickets.length-1 ].Date}
													</span>
													}
												</div>
												}
												<div className="place">
													<FontAwesomeIcon icon="map-marker-alt" style={{marginRight:"0.5em"}}/>
													{stage.Place}
												</div>
												<div className="accepting">
													{status==="accepting" &&
													<span className="active">受付中</span>
													}
													{status==="waiting" &&
													<span className="before">{startTime}～</span>
													}
													{status==="finished" &&
													<span className="after">受付終了</span>
													}
													{status==="editing" &&
													<span className="notOpen"><FontAwesomeIcon icon="lock" style={{marginRight:"0.5em"}}/>準備中</span>
													}
												</div>
											</div>
											<div className="actions">
												<Tooltip title="このイベントをコピーする"><IconButton onClick={openCopy} data-index={index} color="primary">
													<FontAwesomeIcon icon="clone"/>
												</IconButton></Tooltip>
												<Tooltip title="このイベントの詳細を見る"><IconButton onClick={seeStage} data-index={index} color="primary">
													<FontAwesomeIcon icon="edit"/>
												</IconButton></Tooltip>
											</div>
										</div>
									)
								})}
								</div>
								{states.stages.length===0 &&
									<div className="noStage">
										作成したイベントがここに表示されます<br/>
										{!userState.user ? (
											<span>ログインしてイベントをつくりましょう</span>
										):(
											<span>イベントをつくりましょう</span>
										)}
									</div>
								}
							</div>
						</Grid>
						<Grid item xs={12} sm={12} md={5} lg={5}>
							<div className="topRight">
								<div className="infos">
									<h3><FontAwesomeIcon icon="info-circle" style={{marginRight:"0.25em"}}/>事務局からのお知らせ</h3>
								{states.infos.map((info:Info,index:number)=>{
									const key = "info"+index;
									return (
									<div className="info" key={key}>
										<div className="title">{info.Title}</div>
										<div className="text">
											{info.Text}
										</div>
										<div className="date">
											{info.Date}
										</div>
									</div>
									);
									})
								}
								</div>
								<div id={payjpId}/>
							</div>
						</Grid>
						<Grid item xs={12}>
							<div style={{textAlign:"left",fontSize:"80%",color:"#999999",paddingLeft:"0.5em",paddingBottom:"1em"}}>
								version: {process.env.REACT_APP_VERSION}
							</div>
						</Grid>
					</Grid>
				</div>
				<StageCopyInput isOpen={copy.Id!==""} close={closeCopy} add={addStage} stage={copy}/>
				<ClimbUp/>
				<Loading isLoading={states.isLoading}/>
				<Toast close={closeToast} mes={toast}/>
			</div>
		</div>
    );
}

export default Main;
