import React,{useState,useEffect,useCallback} from 'react';
import Box from '@material-ui/core/Box';
import NoImage from './img/noimage.png';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadImageProps {
    src:string,
	alt?:string,
	width?:number,
	height?:number,
}

const LoadImage:React.FC<LoadImageProps> = (props)=>{
	const [status,setStatus] = useState(0);

	const load = async ()=>{
		loadImage(props.src)
		.then(res =>{
			//console.log("success:"+props.src);
			setStatus(1);//success
		})
		.catch(e => {
			//console.log("failure:"+props.src);
			setStatus(9);//failure
		});
	}

	const loadImage = useCallback((src:string)=> {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => resolve(img);
			img.onerror = (e) => reject(e);
			img.src = src;
		});
	},[]);

	useEffect(()=>{
		if(props.src===""){
			setStatus(1);
			return;
		}
		load();
	},[props.src])

	if( status===0 ){
		return <Box style={{textAlign:"center"}}><CircularProgress color="secondary"/></Box>
	}
	if( status===9 ){
		return <Box style={{textAlign:"center",padding:"2em 0",fontSize:"80%",color:"#CCCCCC"}}>image not found</Box>
	}

	let alt = "image";
	if( props.alt ){
		alt = props.alt;
	}

	let width="100%";
	if( props.width ){
		width = props.width.toString() + "px";
	}
	let height="auto";
	if( props.height ){
		height = props.height.toString() + "px";
	}

	return (
		<Box style={{maxWidth:'100%',width:`${width}`,height:`${height}`,textAlign:"center",backgroundColor:"#DDDDDD",lineHeight:"0em"}}><img src={props.src!=='' ? props.src : NoImage} style={{width:"auto",maxWidth:"100%",margin:"0 auto"}} alt={alt}/></Box>
	)
}

export default LoadImage;