import React,{useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {UserContainer} from './UserContainer';
import Loading from './Loading';

const MailUpdate = ()=> {
    const [isSuccess,setIsSuccess] = useState(false);
    const [isFailure,setIsFailure] = useState(false);
    const userState = UserContainer.useContainer();

    useEffect( ()=>{
		if( !userState.ready ){
			return;
		}

		userState.updateUserEmail(()=>{
			//console.log("success");
			setIsSuccess(true);
		},()=>{
			//console.log("failure");
			setIsFailure(true);
		});
	},[userState.ready]);


    if( isSuccess ){
        return (
            <Redirect to="/mail/success"/>
        )
    }

    if( isFailure ){
        return (
            <Redirect to="/mail/failure"/>
        )
    }


    return (
        <Loading isLoading={true}/>
    )
}

export default MailUpdate;
