import {Stage,StageForm,StageBooking,StageAgree,StageRange,StageFormConfig,StageBookingSearchParam,Inquiry,Mail} from './Inc';
import {Tools} from './Tools';

export default class Ajax {
	static token:string = "";
	static url = process.env.REACT_APP_MEJIRO_URL;
	static setToken = (tk:string)=>{
		Ajax.token = tk;
	}
	static send = (path:string,data:any,func:any)=>{
		//console.log("send",Ajax.url+path,data);
		fetch(Ajax.url+path, {
			method : 'POST',
			body : JSON.stringify(data),
			credentials: "include",
		}).then(response => response.json())
		.then((json) => {
			func(json);
		})
		.catch(err => {
			console.log('rejected:', err); // rejected: TypeError: Failed to fetch
		});
	}

	static hello = async ()=>{
		try {
			return await fetch(Ajax.url+"hello",{
				method: 'POST',
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static newToken = async (userId:string)=>{
		try {
			const data = {
				UserId: userId,
			};
			return await fetch(Ajax.url+"token/get",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static validToken = async (userId:string)=>{
		try {
			const data = {
				UserId: userId,
			};
			return await fetch(Ajax.url+"token/valid",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static freeToken = async (userId:string)=>{
		try {
			const data = {
				UserId: userId,
			};
			return await fetch(Ajax.url+"token/free",{
				method: 'POST',
				body: JSON.stringify(data),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getStageVer = async (stageId:string)=>{
		try {
			return await fetch(Ajax.url+"stage/ver",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}
	static addStage = async (stage:Stage,image:string)=>{
		try {
			if(stage.Code===""){
				stage.Code = Tools.getUniqueId();
			}
			return await fetch(Ajax.url+"stage/add",{
				method: 'POST',
				body: JSON.stringify({Stage:stage,Image:image,Token:Ajax.token},function(key,value){
					if( key==="CreatedAt" || key==="UpdatedAt" ){
						return null;
					}
					return value;
				}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static updateStage = async (stage:Stage,image:string)=>{
		try {
			if(!stage.Code || stage.Code===""){
				stage.Code = Tools.getUniqueId();
			}

			return await fetch(Ajax.url+"stage/update",{
				method: 'POST',
				body: JSON.stringify({Stage:stage,Image:image,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static removeStage = async (stageId:string)=>{
		try {
			return await fetch(Ajax.url+"stage/remove",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static updateStageForms = async (stageForms:StageForm[],removable:boolean,stageId:string)=>{
		try {
			return await fetch(Ajax.url+"stage/forms/update",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,Forms:stageForms,Flag:removable,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());

			//return await db.collection("stages").doc(id).update({forms:stageForms});
		} catch(error) {
			throw error;
		};		
	}

	static getStage = async (stageId:string,checkRests:boolean)=>{
		try {
			return await fetch(Ajax.url+"stage/get",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,Flag:checkRests,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getStages = async (userId:string)=>{
		try {
			return await fetch(Ajax.url+"stage/list",{
				method: 'POST',
				body: JSON.stringify({UserId:userId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getSerial = async (stageId:string)=>{
		try {
			return await fetch(Ajax.url+"serial/get",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static updateSerial = async (stageId:string,serial:number)=>{
		try {
			//stageBooking.UpdatedAt = new Date();
			return await fetch(Ajax.url+"serial/update",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,Serial:serial}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}
	
	static updateStageSeats = async (stage:Stage)=>{
		try {
			return await fetch(Ajax.url+"stage/seats/update",{
				method: 'POST',
				body: JSON.stringify({StageId:stage.Id,Seats:stage.Seats,Tickets:stage.Tickets,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error) {
			throw error;
		};		
	}

	static addBooking = async (stageBooking:StageBooking)=>{
		try {
			//console.log("addBooking",{Booking:stageBooking});
			const now = new Date();
			stageBooking.CreatedAt = now.toISOString();
			stageBooking.UpdatedAt = now.toISOString();
			return await fetch(Ajax.url+"booking/add",{
				method: 'POST',
				body: JSON.stringify({Booking:stageBooking,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static updateBooking = async (stageBooking:StageBooking)=>{
		try {
			//stageBooking.UpdatedAt = new Date();
			return await fetch(Ajax.url+"booking/update",{
				method: 'POST',
				body: JSON.stringify({Booking:stageBooking,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static getBookings = async (stageId:string,param:StageBookingSearchParam)=>{
		try {
			return await fetch(Ajax.url+"booking/list",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,SearchBooking:param,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static cancelBooking = async (bookingId:number,method:string)=>{
		try {
			return await fetch(Ajax.url+"booking/cancel",{
				method: 'POST',
				body: JSON.stringify({Id:bookingId,Method:method,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static getBookingPayments = async (stageId:string)=>{
		try {
			return await fetch(Ajax.url+"booking/payments",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static uploadBookingCsv = async (file:File,stageId:string)=>{
		try {
			const data = new FormData();
			data.append("Data",file);
			data.append("StageId",stageId);
			data.append("Token",Ajax.token);
			return await fetch(Ajax.url+"booking/upload",{
				method: 'POST',
				body: data,
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static downloadBookingCsv = async (stageId:string,param:StageBookingSearchParam)=>{
		try {
			return await fetch(Ajax.url+"booking/download", {
				method : 'POST',
				body:JSON.stringify({StageId:stageId,SearchBooking:param,Token:Ajax.token}),
				credentials: "include",
			}).then((response) => {
				//console.log("status",response.status);
				if( response.status!==200 ){
					const error = new Error();
					error.message = "notFound";
					throw error;
				}
				return response.blob()
			}).then((blob) => {
				const dt = new Date();
				const today = dt.getFullYear() + ('00' + (dt.getMonth()+1)).slice(-2) + ('00' + dt.getDate()).slice(-2);
				const name = "リスト_"+today+".csv";
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', name);

				document.body.appendChild(link);

				link.click();

				if( link.parentNode ){
					link.parentNode.removeChild(link);
				}

				return true;
			}).catch((error)=>{
				console.log(error);
				return false;
			})
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static downloadSampleUploadCsv = async ()=>{
		try {
			return await fetch(Ajax.url+"booking/sample/upload", {
				method : 'POST',
				body:"",
				credentials: "include",
			}).then((response) => {
				//console.log("status",response.status);
				if( response.status!==200 ){
					const error = new Error();
					error.message = "notFound";
					throw error;
				}
				return response.blob()
			}).then((blob) => {
				//const dt = new Date();
				const name = "CSVファイルサンプル（予約アップロード）.csv";
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', name);

				document.body.appendChild(link);

				link.click();

				if( link.parentNode ){
					link.parentNode.removeChild(link);
				}

				return true;
			}).catch((error)=>{
				console.log(error);
				return false;
			})
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static downloadSampleDownloadCsv = async ()=>{
		try {
			return await fetch(Ajax.url+"booking/sample/download", {
				method : 'POST',
				body:"",
				credentials: "include",
			}).then((response) => {
				//console.log("status",response.status);
				if( response.status!==200 ){
					const error = new Error();
					error.message = "notFound";
					throw error;
				}
				return response.blob()
			}).then((blob) => {
				//const dt = new Date();
				const name = "CSVファイルサンプル（予約ダウンロード）.csv";
				const url = window.URL.createObjectURL(new Blob([blob]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', name);

				document.body.appendChild(link);

				link.click();

				if( link.parentNode ){
					link.parentNode.removeChild(link);
				}

				return true;
			}).catch((error)=>{
				console.log(error);
				return false;
			})
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static addAgree = async (stageAgree:StageAgree)=>{
		try {
			//console.log("addBooking",{Booking:stageBooking});
			return await fetch(Ajax.url+"agree/add",{
				method: 'POST',
				body: JSON.stringify({Agree:stageAgree,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static updateAgree = async (stageAgree:StageAgree)=>{
		try {
			//stageBooking.UpdatedAt = new Date();
			return await fetch(Ajax.url+"agree/update",{
				method: 'POST',
				body: JSON.stringify({Agree:stageAgree,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static getAgree = async (stageId:string)=>{
		try {
			return await fetch(Ajax.url+"agree/get",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static startAgree = async (stageId:string)=>{
		try {
			return await fetch(Ajax.url+"agree/start",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static updateStageRange = async (stageRange:StageRange)=>{
		try {
			//stageBooking.UpdatedAt = new Date();
			return await fetch(Ajax.url+"range/update",{
				method: 'POST',
				body: JSON.stringify({Range:stageRange,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static updateStageFormConfig = async (stageFormConfig:StageFormConfig)=>{
		try {
			return await fetch(Ajax.url+"config/update",{
				method: 'POST',
				body: JSON.stringify({Config:stageFormConfig,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static addInquiry = async (inquiry:Inquiry)=>{
		try {
			return await fetch(Ajax.url+"inquiry/add",{
				method: 'POST',
				body: JSON.stringify({Inquiry:inquiry}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			console.log(error);
			throw error;
		};
	}

	static sendPayjpToken = async (tokenId:string)=>{
		try {
			return await fetch(Ajax.url+"payjp/send",{
				method: 'POST',
				body: JSON.stringify({TokenId:tokenId,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			throw error;
		};
	}

	static sendMail = async (stageId:string,mail:Mail)=>{
		try {
			return await fetch(Ajax.url+"mail/send",{
				method: 'POST',
				body: JSON.stringify({StageId:stageId,Subject:mail.Subject,Body:mail.Body,Token:Ajax.token}),
				credentials: "include",
			}).then(response => response.json());
		} catch(error){
			return {ok:false};
			//console.log("error",error);
			//throw error;
		};
	}

}