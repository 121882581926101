import React,{useState, useEffect} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'//yarn add @types/react-router-dom
import {MuiThemeProvider} from '@material-ui/core/styles';
import TopBar from "./TopBar";
import Main from "./Main";
import Login from "./Login";
import Form from "./Form";
import StageInput from './StageInput';
import StageDetail from './StageDetail';
import StageSeatList from './StageSeatList';
import StageAgreeDetail from './StageAgreeDetail';
import StageFormList from './StageFormList';
import StageBookingList from './StageBookingList';
import StageBookingLimitedList from './StageBookingLimitedList';
import StageSeatInput from './StageSeatInput';
import MailNew from './MailNew';
import MailUpdate from './MailUpdate';
import MailResult from './MailResult';
import HelpIndex from './HelpIndex';
import HelpDetail from './HelpDetail';
import HelpTerm from './HelpTerm';
import HelpPrivacy from './HelpPrivacy';
import OldVersion from './OldVersion';
import NoService from './NoService';
import NoResponse from './NoResponse';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faInfoCircle,faCopyright,faTrashAlt,faClock, faPlus, faEdit, faSearch, faClipboard,faExternalLinkSquareAlt,faBan,faCheckCircle,faRobot,faUserCircle,faEnvelope,faClone,faFileCsv,faQuestionCircle,faLock,faBell,faFileUpload,faFileDownload,faMapMarkerAlt,faCog,faTimes,faPhoneSquare,faEnvelopeOpenText,faUser,faListOl,faCaretDown,faUserLock,faMailBulk} from '@fortawesome/free-solid-svg-icons';
//faBackspace,faCalendarAlt,faTicketAlt,faGhost,faHome
import {faTwitter,faGoogle,faChrome,faTwitterSquare} from '@fortawesome/free-brands-svg-icons';
import {Theme} from './Theme';
import Ajax from './Ajax';
import "./App.scss";
import {UserContainer} from './UserContainer';

const App: React.FC = () => {
  const basename = process.env.REACT_APP_BASENAME;
  const [redirectTo,setRedirectTo] = useState("");
  const [version,setVersion] = useState("");
  const [available,setAvailable] = useState(true);
  const [serverError,setServerError] = useState(false);
  //library.add(faInfoCircle,faCopyright,faTrashAlt,faCalendarAlt,faPlus,faEdit,faSearch,faBackspace,faClipboard,faExternalLinkSquareAlt,faBan,faCheckCircle,faRobot,faTicketAlt,faGhost,faUserCircle,faEnvelope,faClone,faFileCsv,faQuestionCircle,faHome,faClock,faLock,faMapMarkerAlt,faBell,faFileUpload,faFileDownload,faCog,faTimes,faPhoneSquare,faEnvelopeOpenText,faUser,faListOl,faCaretDown,faUserLock,faTwitter,faGoogle,faChrome,faTwitterSquare);
  library.add(faInfoCircle,faCopyright,faTrashAlt,faPlus,faEdit,faSearch,faClipboard,faExternalLinkSquareAlt,faBan,faCheckCircle,faRobot,faUserCircle,faEnvelope,faClone,faFileCsv,faQuestionCircle,faClock,faLock,faMapMarkerAlt,faBell,faFileUpload,faFileDownload,faCog,faTimes,faPhoneSquare,faEnvelopeOpenText,faUser,faListOl,faCaretDown,faUserLock,faTwitter,faGoogle,faChrome,faTwitterSquare,faMailBulk);
  const redirect = (to:string)=>{
    setRedirectTo(to);
  }
  const hello = async ()=>{
	await Ajax.hello().then((res)=>{
		if( res.ok ){
			if( res.version!==process.env.REACT_APP_VERSION ){
				setVersion(res.version);
			} else if( res.available===false ){
				setAvailable(false);
			}
			//console.log("hello","true");
		} else {
			//console.log("hello","false");
			setServerError(true);
		}
	}).catch((error)=>{
		console.log(error);
		setServerError(true);
	});
  }

  useEffect(()=>{
	hello();
  },[]);

  return (
    <BrowserRouter basename={basename}>
      <MuiThemeProvider theme={Theme}>
        {redirectTo!=="" &&
        <Redirect to={redirectTo}/>
        }
        <UserContainer.Provider>
          <TopBar redirect={redirect}/>
          <div className="App">
            <Switch>
              <Route exact path='/' render={()=><Main/>}/>
              <Route exact path='/login' render={()=><Login/>}/>
              <Route exact path='/stage/new' render={()=><StageInput new={true}/>}/>
              <Route exact path='/stage/detail/:gid' render={()=><StageDetail/>}/>
              <Route exact path='/stage/booking/:gid' render={()=><StageBookingList/>}/>
			  <Route exact path='/stage/booking/:gid/:fid/:secret' render={()=><StageBookingLimitedList/>}/>
              <Route exact path='/stage/form/:gid' render={()=><StageFormList/>}/>
              <Route exact path='/stage/ticket/:gid' render={()=><StageSeatList/>}/>
              <Route exact path='/stage/agree/:gid' render={()=><StageAgreeDetail/>}/>
              <Route exact path='/stage/input/:gid' render={()=><StageInput new={false}/>}/>
			  <Route exact path='/stage/ticket/input/:gid' render={()=><StageSeatInput/>}/>
              <Route exact path='/mail/new' render={()=><MailNew/>}/>
              <Route exact path='/mail/update' render={()=><MailUpdate/>}/>
			  <Route exact path='/mail/success' render={()=><MailResult result={true}/>}/>
			  <Route exact path='/mail/failure' render={()=><MailResult result={false}/>}/>
              <Route exact path='/help' render={()=><HelpIndex/>}/>
			  <Route exact path='/help/term' render={()=><HelpTerm/>}/>
			  <Route exact path='/help/privacy' render={()=><HelpPrivacy/>}/>
			  <Route exact path='/help/:code' render={()=><HelpDetail/>}/>
              <Route exact path='/:code' render={()=><Form/>}/>
            </Switch>
          </div>
        </UserContainer.Provider>
		<OldVersion latest={version} isOpen={version!==""}/>
		<NoService isOpen={!available}/>
		<NoResponse isOpen={serverError}/>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
