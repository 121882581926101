import {useState,useEffect} from 'react';
//import firebase from 'firebase/app'
import {googleAuth,twitterAuth,emailAuth,auth} from './firebase';
import {User, signInWithPopup, sendSignInLinkToEmail, signInWithEmailAndPassword, isSignInWithEmailLink, signInWithEmailLink, linkWithCredential,
	reauthenticateWithCredential, updateEmail, getIdToken, sendEmailVerification, signOut, EmailAuthProvider} from "firebase/auth";
import {createContainer} from "unstated-next";
import {Inc,Stage} from './Inc';
import Ajax from './Ajax';

interface Data {
	stage:Stage,
}

const newMailSettings = {
    url: process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_NEW_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const reauthMailSettings = {
    url: process.env.REACT_APP_FIREBASE_REAUTH_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_REAUTH_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
const updateMailSettings = {
    url: process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN ? process.env.REACT_APP_FIREBASE_UPDATE_MAIL_RETURN : "http://localhost",
    handleCodeInApp: true,
};
  
const useUserState = ()=> {
	const [user, setUser] = useState<User | null>(null);
	const [stage, setStage] = useState(Inc.defaultStage());
	const [ready, setReady] = useState(false);
	const [ver, setVer] = useState("");
	const [token, setToken] = useState("");

	//auth.onAuthStateChanged(user => setUser(user));
    //auth.onIdTokenChanged(id=>check(id));

    const check = (u:User | null)=>{
		//console.log("check");
		setReady(true);
		
		if( !u || u.uid==="" ){
			return;
		}
	
		Ajax.newToken(u.uid).then((res)=>{
			if(res.ok){
				//console.log("token:"+res.token);
				setToken(res.token);
			}
		})
	}

	const hasStage = ()=>{
		if( stage.Id!=="" ){
			return true;
		} else {
			return false;
		}
	}

	const signIn = async (method:"twitter"|"google"|"yahoo"|"email",funcSuccess?:any,funcFailure?:any) => {
        //console.log("user",user);
		try {
			if( method==="google" ){
				//const provider = new GoogleAuthProvider();
				await signInWithPopup(auth,googleAuth) && funcSuccess && funcSuccess();
			}
			if( method==="twitter" ){
				//const provider = new auth.TwitterAuthProvider();
				await signInWithPopup(auth,twitterAuth) && funcSuccess && funcSuccess();
			}
			//if( method==="yahoo" ){
			//	const provider = new auth.OAuthProvider('yahoo.com');
			//	await auth().signInWithPopup(provider) && funcSuccess && funcSuccess();
			//}
			if( method==="email" ){
				await signInWithEmail(funcSuccess,funcFailure);
			}
		} catch (error) {
			//console.log("siginIn error",error);
			setUser(null);
			funcFailure();
		}
	}

	const logout = async () => {
        //console.log("user",user);
		await signOut(auth);
	}

	const sendEmail = async (email:string,funcSuccess?:any,funcFailure?:any) => {
		try {
			await sendSignInLinkToEmail(auth, email, newMailSettings)
			.then(function() {
				//console.log("success");
				window.localStorage.setItem('emailForSignIn', email);
				funcSuccess && funcSuccess();
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				console.log("error",error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			funcFailure && funcFailure();
		}
	}

	const sendReauthEmail = async (oldEmail:string,newEmail:string,funcSuccess?:any,funcFailure?:any) => {
		try {
			await sendSignInLinkToEmail(auth, oldEmail, reauthMailSettings)
			.then(function() {
				//console.log("success");
				window.localStorage.setItem('oldEmailForReauth', oldEmail);
				window.localStorage.setItem('newEmailForReauth', newEmail);
				funcSuccess && funcSuccess();
			})
			.catch(function(error) {
				// Some error occurred, you can inspect the code: error.code
				console.log("error",error);
				funcFailure && funcFailure();
			});
		} catch (error) {
			funcFailure && funcFailure();
		}
	}

	const signInWithEmail = async (funcSuccess:any,funcFailure?:any)=>{
		//console.log("siginInWithEmail:"+window.location.href);
		window.localStorage.setItem('emaillink',window.location.href);
		if (isSignInWithEmailLink(auth,window.location.href)) {
			let email = window.localStorage.getItem('emailForSignIn');
			if (!email) {
				funcFailure && funcFailure();
			} else {
				await signInWithEmailLink(auth, email)
				.then(function() {
					window.localStorage.removeItem('emailForSignIn');
					funcSuccess && funcSuccess();
				})
				.catch(function(error) {
					console.log(error);
					funcFailure && funcFailure();
				});
			}
		}
	}

	const updateUserEmail = async (funcSuccess?:any,funcFailure?:any)=>{
		const currentUser = auth.currentUser; 
		const oldEmail = window.localStorage.getItem('oldEmailForReauth');
		const newEmail = window.localStorage.getItem('newEmailForReauth');
		window.localStorage.removeItem('oldEmailForReauth');
		window.localStorage.removeItem('newEmailForReauth');
		try {
			if( currentUser && oldEmail && newEmail ){
				const credential = EmailAuthProvider.credentialWithLink(oldEmail, window.location.href);
				reauthenticateWithCredential(currentUser,credential)
				.then(async(usercred) => {
					const newUser = usercred.user;
					await updateEmail(newUser,newEmail);
					await getIdToken(newUser,true);
					await sendEmailVerification(newUser,updateMailSettings);
					funcSuccess && funcSuccess();
				})
				.catch((error) => {
					funcFailure && funcFailure();
				});
		
				//await currentUser.updateEmail(email);
				//await currentUser.getIdToken(true);
				//await currentUser.sendEmailVerification(updateMailSettings);
				//if( user ){
				//	await user.updateEmail(email);
				//	await user.getIdToken(true);
				//	await user.sendEmailVerification(updateMailSettings);

				//	funcSuccess && funcSuccess();
				//} else {
				//	funcFailure && funcFailure();		
				//}
				//funcSuccess && funcSuccess();
			} else {
				funcFailure && funcFailure();	
			}
		} catch (e) {
			console.log("error",e);
			funcFailure && funcFailure();			
		}
	}

	//useEffect(()=>{
	//	if( !user || user.uid==="" ){
	//		return;
	//	}
	//	Ajax.newToken(user.uid).then((res)=>{
	//		if(res.ok){
	//			//console.log("token",res.token);
	//			setToken(res.token);
	//		}
	//	})
	//},[user])

	useEffect(()=>{
		auth.onAuthStateChanged(u => setUser(u));
		auth.onIdTokenChanged(u=>check(u));
	},[])

	return { user, ready, signIn, logout, sendEmail, updateUserEmail, sendReauthEmail, stage, setStage, hasStage, token, ver, setVer }
}
export const UserContainer = createContainer(useUserState);
