import {db} from './firebase';
import { collection, query, where, orderBy, limit, doc, getDocs, getDoc, addDoc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import {Info,Stage,StageForm} from './Inc';

export default class store {
	static stages = process.env.REACT_APP_RUNMODE==="PROD" ? "stages" : "stagesDev";
	static infos = process.env.REACT_APP_RUNMODE==="PROD" ? "infos" : "infosDev";

	static getInfos = async ()=>{
		try {
			const q = await query(collection(db,store.infos),where("Deleted","==",false),orderBy('Date','desc'),limit(10));
			const querySnapshot = await getDocs(q);
			let infos:Info[] = [];
			querySnapshot.forEach((doc) => {
				infos.push( doc.data() as Info );
			});
			//console.log(infos);
			return infos;
		} catch(error) {
			throw error;
		}
	}

	static test0 = (uid:string)=>{
		//console.log("test");
		return new Promise((resolve,reject)=>{
			getDocs(query(collection(db, store.stages), where("userId","==",uid), orderBy('createdAt','desc'), limit(10))).then((response) => {
				let list:Stage[] = [];
				response.forEach((doc) => {
					list.push( doc.data() as Stage );
				});
				resolve(list);
			}).catch(()=>{
				reject();
			})
		})
	}
	static test1 = (uid:string)=>{
		//console.log("test1");
		return getDocs(query(collection(db,store.stages),where("userId","==",uid),orderBy('createdAt','desc'),limit(10)));
	}

	// promise/async/await
	static getStage = async (id:string)=> {
		try {
			//const doc = await collection(db,store.stages).doc(id).get();

			const docRef = doc(db, store.stages, id);
			const docSnap = await getDoc(docRef);
			
			if( docSnap.exists() ){
				return docSnap.data() as Stage;
			}
			const error = new Error();
			error.message = "notFound";
			throw error;
		} catch(error) {
			throw error;
		}
	}

	static searchStage = async (code:string,id?:string)=> {
		try {
			const ref = await getDocs(query(collection(db,store.stages),where("Code","==",code),limit(2)));
			let stages:Stage[] = [];
			ref.forEach((doc) => {
				if(!id || doc.id!==id){
					stages.push(doc.data() as Stage);
				}
			});
			if(stages[0]){
				return stages[0];
			}
			const error = new Error();
			error.message = "notFound";
			throw error;
		} catch(error) {
			throw error;
		}
	}

	static getStages = async (uid:string)=>{
		try {
			if( uid ){
				const ref = await getDocs(query(collection(db,store.stages),where("UserId","==",uid),where("Deleted","==",false),orderBy('CreatedAt','desc'),limit(10)));
				let stages:Stage[] = [];
				ref.forEach((doc) => {
					stages.push( doc.data() as Stage );
				});
				return stages;
			}
		} catch(error) {
			throw error;
		}
	}

	static addStage = async (stage:Stage)=>{
		try {
			if(stage.Code===""){
				stage.Code = store.getUniqueId();
			}
			//stageDoc.CreatedAt = new Date();
			//stageDoc.UpdatedAt = new Date();
			return await addDoc(collection(db,store.stages),stage);
		} catch(error){
			throw error;
		};
	}

	static updateStage = async (stage:Stage,id:string)=>{
		try {
			if(!stage.Code || stage.Code===""){
				stage.Code = store.getUniqueId();
			}
			return await setDoc(doc(db,store.stages,id),stage);
		} catch(error) {
			throw error;
		};		
	}

	static updateStageForms = async (stageForms:StageForm[],id:string)=>{
		try {
			return await updateDoc(doc(db,store.stages,id),{forms:stageForms});
		} catch(error) {
			throw error;
		};		
	}

	//old
	static removeStage = (id:string,funcSuccess:any,funcFailure?:any)=>{
		deleteDoc(doc(db,store.stages,id))
		.then(() => {
			funcSuccess();
		})
		.catch( (error)=>{
			funcFailure();
		});
	}


	static getUniqueId(myStrong?: number): string {
		let strong = 10000;
		if (myStrong) strong = myStrong;
		return (
			("0000" + Math.floor(strong * Math.random()).toString(16)).slice(-4) + ("00000000000" + new Date().getTime().toString(16)).slice(-11)
		);
	}

}