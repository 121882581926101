import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Tools} from './Tools';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
//import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
//import ModalHeader from './ModalHeader';
import DialogHeader from './DialogHeader';
import ColInput from './ColInput';
import Toast from './Toast';
import Yesno from './Yesno';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Inc,Dataset,Stage,StageSeat,StageTicket} from './Inc';
import {Theme} from './Theme';

interface Props {
    close():void,
    ticket:StageTicket,
    stage:Stage,
    update(stageTicket:StageTicket,deleteFlag:boolean):void,
    isOpen:boolean,
    index:number,
}

const StageTicketInput = (props:Props)=> {
    const [ticket,setTicket] = useState(Inc.defaultStageTicket())
    const [message,setMessage] = useState("");
    const [vali,setVali] = useState({
        errors:Inc.defaultStageTicketInputErrors(),
        message:"",
    });
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));

    const change = (name:keyof StageTicket,value:string|number)=>{
        if( typeof value === "string" ){
            if( name==="Date" && value!=="" ){
				if( Tools.validateDate(value) ){
					const sp = value.split(/[^0-9]/);
					value = Tools.zeroPadding(sp[0],4) + "/" + Tools.zeroPadding(sp[1],2) + "/" + Tools.zeroPadding(sp[2],2);
				} else {
					//console.log(value);
				}
            }
        }
        setTicket({...ticket,[name]:value});
    }
    const changeSeats = (index:number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        //console.log("change",index);
        let seats = ticket.TargetSeats;
        seats[index] = !seats[index];
        setTicket({...ticket,"TargetSeats":seats});
    }
    const update = ()=>{
        let errors = Inc.defaultStageTicketInputErrors();
        let flag = false;
        if( ticket.Date==="" ){
            errors.Date = "入力して下さい";
            flag = true;
        }

        if( ticket.UseLimit===true && !ticket.Limit && ticket.Limit!==0 ){
            errors.Limit = "入力して下さい";
            flag = true;
        }

        let count = 0;
        ticket.TargetSeats.map((b:boolean,index:number)=>{
            if( b ){
                count++;
			}
			return true;
        })
        if( count===0 ){
            errors.TargetSeats = "一つ以上選択して下さい";
            flag = true;
        }

        ticket.Code = Tools.getTicketCode(ticket);

        if( flag ){
            setVali({errors:errors,message:Inc.errorText+"部分を修正して下さい"});
        } else {
            //日時と種類の重複を確認する
            let dbl = false;
            props.stage.Tickets && props.stage.Tickets.map((other:StageTicket,index:number)=>{
                if( other.Code.substr(0,12)===ticket.Code.substr(0,12) && props.index!==index ){
                    for( let i=0; i<5; i++ ){
                        if( other.Code.substr(12+i,1)==="1" && ticket.Code.substr(12+i,1)==="1" ){
							dbl = true;
							return true;
                        }
                    }
				}
				return false;
            });
            if( dbl ){
                setVali({errors:errors,message:"登録済の内容と重複しています"});
            } else {
                ticket.Label = Tools.getTicketLabel(ticket);
                props.update(ticket,false);
            }
        }
    }
    const confirm = ()=>{
        setMessage("本当に削除してよろしいですか？");
    }
    const yes = ()=>{
        props.update(props.ticket,true);
        setMessage("");
    }
    const no = ()=>{
        setMessage("");
    }
    const closeToast = ()=>{
        setVali({...vali,message:""});
    }

    useEffect(()=>{
		//console.log("form",props.stageTicket);
		if( props.isOpen ){
			setTicket(props.ticket);
			setVali({
				errors:Inc.defaultStageTicketInputErrors(),
				message:"",
			});
		}
    },[props.isOpen]);

    let hours:Dataset[] = [];
    let minutes:Dataset[] = [];
    for( let h=0 ; h<24 ; h++ ){
        let id = String(h);
        if( h<10 ){
            id = "0"+id;
        }
        hours.push({
            id: id,
            label: id + "時",
        });
    }
    for( let m=0 ; m<60 ; m+=5 ){
        let id = String(m);
        if( m<10 ){
            id = "0"+id;
        }
        minutes.push({
            id: id,
            label: id + "分",
        });
    }

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
        >
                <DialogHeader close={props.close}/>
                {props.ticket && ticket &&
				<>
				<DialogContent dividers>
                	<div className="confirmRoot">
                    <h2>開演時間とチケット枚数</h2>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={3} className="formName">開演時間</Grid>
                        <Grid item xs={6} sm={3}>
                            <ColInput name="開演時間" id="Date" noName={true} dense={true} type="date" error={vali.errors.Date} placeholder="カレンダーで選択" value={ticket.Date} change={change}/>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <ColInput name="時" id="Hour" noName={true} dense={true} type="select" list={hours} notEmpty={true} value={ticket.Hour} change={change}/>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <ColInput name="分" id="Minute" noName={true} dense={true} type="select" list={minutes} notEmpty={true} value={ticket.Minute} change={change}/>
                        </Grid>
                        <Grid item xs={12} sm={3} className="formName">枚数制限</Grid>
                        <Grid item xs={8} sm={5}>
                            <ColInput name="枚数制限" id="UseLimit" useBool={true} noName={true} dense={true} type="radio" value={ticket.UseLimit} list={Inc.useLimits} change={change}/>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            {ticket.UseLimit &&
                            <ColInput name="枚数制限" id="Limit" noName={true} dense={true} error={vali.errors.Limit} type="number" placeholder="0枚にすると受付休止" endAdornment="枚" value={ticket.Limit} change={change}/>
}
                        </Grid>
                        <Grid item xs={12} sm={3} className="formName">種類</Grid>
                        <Grid item xs={12} sm={9}>
                            <FormControl fullWidth={true} error={vali.errors.TargetSeats!==""} className="formControl formControlDense">
                                <FormGroup row>
                                    {props.stage.Seats.map((seat:StageSeat,index:number)=>{
                                    const key = "seat"+index;
                                    if( seat.Name==="" ){
                                        return (null);
                                    }
                                    return (
                                        <FormControlLabel
                                            key={key}
                                            control={<Checkbox checked={ticket.TargetSeats[index]} color="primary" onChange={changeSeats(index)} value={seat.Name}/>}
                                            label={seat.Name}
                                        />
                                    )
                                })}
                                </FormGroup>
                                {vali.errors.TargetSeats!=="" &&
                                <FormHelperText>※{vali.errors.TargetSeats}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
					</div>
					</DialogContent>
					<DialogActions>
                    {props.index===9999 ? (
						<>
						<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
                        <Button variant="contained" color="secondary" onClick={update}>決定する</Button>
						</>
                    ):(
						<React.Fragment>
							<Button variant="outlined" size="small" color="primary" onClick={confirm}><FontAwesomeIcon icon="times" style={{marginRight:"0.25em"}}/>削除する</Button>
							<Box style={{flex:1}}/>
							<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
							<Button variant="contained" color="secondary" onClick={update}>変更する</Button>
						</React.Fragment>
                    )}
					</DialogActions>
					</>
                }
                <Toast close={closeToast} mes={vali.message}/>
                <Yesno mes={message} yes={yes} no={no} isOpen={message!==""}/>

        </Dialog>
    );
}

export default StageTicketInput;
