import React,{useEffect,useState} from 'react';
import {Link} from 'react-router-dom'//yarn add @types/react-router-dom
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import {Tools} from './Tools';
//import Drawer from '@material-ui/core/Drawer';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Stage} from './Inc';

interface Props {
    stage:Stage,
    current: "detail" | "booking" | "agree" | "form" | "ticket" | "hall",
	limited?: boolean,
}

const StageHeader = (props:Props)=> {
	const [states,setStates] = useState({
		status: "",
		startTime: "",
		limited: false,
	});
    const toDetail = "/stage/detail/"+props.stage.Id;
    const toBooking = "/stage/booking/"+props.stage.Id;
    const toForm = "/stage/form/"+props.stage.Id;
	let toTicket = "/stage/ticket/"+props.stage.Id;
	const toAgree = "/stage/agree/"+props.stage.Id;

	let classDetail = "";
	let classBooking = "";
	let classForm = "";
	let classTicket = "";
	let classAgree = "";
	
	let useNextGuide = false;

	if( props.current==="detail" ){
		classDetail = "current";
	}
	if( props.current==="booking" ){
		classBooking = "current";
	}
	if( props.current==="form" ){
		classForm = "current";
	}
	if( props.current==="ticket" ){
		classTicket = "current";
	} else {
		if( props.stage.Tickets && props.stage.Tickets.length>0 ){

		} else {
			useNextGuide = true;
		}
	}
	if( props.current==="agree" ){
		classAgree = "current";
	}
	
	useEffect( ()=>{
		let startTime = "";
		if( props.stage.StartDate ){
			startTime = props.stage.StartDate.substr(5,5)+" "+props.stage.StartHour+":"+props.stage.StartMinute;
		}
		let status = Tools.getStageStatus(props.stage);
		let limited = false;
		if( props.limited!==null && props.limited===true ){
			limited = true;
		}
		setStates({status:status,startTime:startTime,limited:limited});
	},[props.stage,props.stage.StartDate]);

	if(!props.stage.Id){
		return (
			<div className="stageHeader">
				<div className="inner">
					<h2>
						新しいイベント<br/>
						<span>　</span>
					</h2>
					<div className="accepting">
						<span className="notOpen">新規作成</span>
					</div>
				</div>
			</div>	
		);
	}

	if( !props.stage.Tickets || props.stage.Tickets.length===0 ){
		toTicket = "/stage/ticket/input/"+props.stage.Id;
	}

    return (
        <div className="stageHeader">
			<div className="inner">
				<Grid container spacing={0}>
					<Grid item xs={12} md={6}>
						<h2>
							{props.stage.Title}<br/>
							<p>{props.stage.Organizer}</p>
						</h2>
						<div className="accepting">
							{states.status==="accepting" &&
							<span className="active">受付中</span>
							}
							{states.status==="waiting" &&
							<span className="before">{states.startTime}～</span>
							}
							{states.status==="finished" &&
							<span className="after">受付終了</span>
							}
							{states.status==="editing" &&
							<span className="notOpen"><FontAwesomeIcon icon="lock" style={{marginRight:"0.5em"}}/>準備中</span>
							}
						</div>
					</Grid>
					{!states.limited &&
					<Grid item xs={12} md={6}>
						<Hidden implementation="js" smDown>
						<div className="btns">
							<Link to={toDetail} style={{marginRight:"0.25em",textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="detail"} className={classDetail}>
								開催内容
							</Button></Link>
							<Tooltip open={useNextGuide} title="▲ 次はこちら！"><Link to={toTicket} style={{marginRight:"0.25em",textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="ticket"} className={classTicket}>
								チケット
							</Button></Link></Tooltip>
							{props.stage.Tickets && props.stage.Tickets.length>0 ? (
							<React.Fragment>
							<Link to={toForm} style={{marginRight:"0.25em",textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="form"} className={classForm}>
								フォーム
							</Button></Link>
							<Link to={toAgree} style={{marginRight:"0.25em",textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="agree"} className={classAgree}>
								受付開始
							</Button></Link>
							<Link to={toBooking} style={{textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="booking"} className={classBooking}>
								予約状況
							</Button></Link>
							</React.Fragment>
							):(
							<React.Fragment>
							<Button variant="outlined" color="secondary" disabled={true} style={{marginRight:"0.25em"}}>
								フォーム
							</Button>
							<Button variant="outlined" color="secondary" disabled={true} style={{marginRight:"0.25em"}}>
								受付開始
							</Button>
							<Button variant="outlined" color="secondary" disabled={true}>
								予約状況
							</Button>
							</React.Fragment>

							)}
						</div>
						</Hidden>
						<Hidden implementation="js" mdUp>
						<div className="btns">
							<Grid container spacing={1}>
								<Grid item xs={4} sm={3}>
									<Link to={toDetail} style={{marginRight:"0.25em",textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="detail"} style={{width:"100%"}} className={classDetail}>
										開催内容
									</Button></Link>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Link to={toTicket} style={{marginRight:"0.25em",textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="ticket"} style={{width:"100%"}} className={classTicket}>
										チケット
									</Button></Link>
								</Grid>
								{props.stage.Tickets && props.stage.Tickets.length>0 ? (
								<React.Fragment>
								<Grid item xs={4} sm={3}>
									<Link to={toForm} style={{marginRight:"0.25em",textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="form"} style={{width:"100%"}} className={classForm}>
										フォーム
									</Button></Link>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Link to={toAgree} style={{marginRight:"0.25em",textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="agree"} style={{width:"100%"}} className={classAgree}>
										受付開始
									</Button></Link>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Link to={toBooking} style={{textDecoration:"none"}}><Button variant="outlined" color="secondary" disabled={props.current==="booking"} style={{width:"100%"}} className={classBooking}>
										予約状況
									</Button></Link>
								</Grid>
								</React.Fragment>
								):(
								<React.Fragment>
								<Grid item xs={4} sm={3}>
									<Button variant="outlined" color="secondary" disabled={true} style={{marginRight:"0.25em",width:"100%"}}>
										フォーム
									</Button>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Button variant="outlined" color="secondary" disabled={true} style={{marginRight:"0.25em",width:"100%"}}>
										受付開始
									</Button>
								</Grid>
								<Grid item xs={4} sm={3}>
									<Button variant="outlined" color="secondary" disabled={true} style={{width:"100%"}}>
										予約状況
									</Button>
								</Grid>
								</React.Fragment>
								)}
							</Grid>
						</div>
						</Hidden>
					</Grid>
					}
				</Grid>
			</div>
        </div>
    )
}

export default StageHeader;