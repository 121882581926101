import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DialogTitle from '@material-ui/core/DialogTitle';
//import Box from '@material-ui/core/Box';
import {Theme} from './Theme';

//import {Tools} from './Tools';

interface Props {
    close():void,
}

const DialogHeader = (props:Props)=> {
    return (
        <DialogTitle style={{padding:'0',backgroundColor:Theme.palette.primary.main}}>
            <IconButton aria-label="close" style={{padding:"0.25em",color:Theme.palette.primary.contrastText}} onClick={props.close}><CancelIcon/></IconButton>
        </DialogTitle>
    )
}

export default DialogHeader;