import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Tools} from './Tools';
//import FormLabel from '@material-ui/core/FormLabel';
import ColInput from './ColInput';
import Toast from './Toast';
//import {UserContainer} from './UserContainer';
import {Inc,Dataset,Stage,StageRange,StageAgree} from './Inc';
//import Ajax from './Ajax';
import DialogHeader from './DialogHeader';
import {Theme} from './Theme';


interface Props {
	close():void,
	stage:Stage,
	agree:StageAgree,
	update(stageRange:StageRange):void,
	isOpen:boolean,
}

const StageRangeInput = (props:Props)=> {
	//const userState = UserContainer.useContainer();
	const [range,setRange] = useState(Inc.defaultStageRange());
	const [vali,setVali] = useState({
		errors:Inc.defaultStageRangeInputErrors(),
		message:"",
	});
	const [toast,setToast] = useState("");
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	
	const change = (name:string,value:string)=>{
		if( name==="StartDate" && value!=="" ){
			if( Tools.validateDate(value) ){
				const sp = value.split(/[^0-9]/);
				value = Tools.zeroPadding(sp[0],4) + "/" + Tools.zeroPadding(sp[1],2) + "/" + Tools.zeroPadding(sp[2],2);
			}
			//const sp = value.split(/[^0-9]/);
			//value = Tools.zeroPadding(sp[0],4) + "/" + Tools.zeroPadding(sp[1],2) + "/" + Tools.zeroPadding(sp[2],2);
		}
		if( name==="EndDays" || name==="EndHours" || name==="EndMinutes" ){
			//console.log(name,value);
			setRange({...range,[name]:parseInt(value)});
		} else {
			setRange({...range,[name]:value});
		}
	}

	const validate = ()=>{
		let errors = Inc.defaultStageRangeInputErrors();
		let flag = false;

		if( range.StartDate==="" ){
			errors.StartDate = "省略できません";
			flag = true;
		}
		if( range.StartDate!=="" ){
			const st = new Date(range.StartDate+" "+range.StartHour+":"+range.StartMinute);
			const a_start = new Date(props.agree.DateFrom+" 00:00:00");
			const a_end = new Date(props.agree.DateTo+" 23:59:59");
			if( st<a_start || st>a_end ){
				errors.StartDate = "利用期間内の日時にして下さい";
				flag = true;
			}
		}

		if( flag ){
			setVali({errors:errors,message:"赤字部分を修正して下さい"});
			return false
		} else {
			return true
		}

	}

	const update = ()=>{
		const result = validate();
		if( !result ){
			return;
		}
		props.update(range);
	}

	const closeToast = ()=>{
		setToast("");
	}

	useEffect(()=>{
		if( props.isOpen && props.stage.Id!=="" ){
			//console.log("stage",props.stage);
			setRange({
				StageId: props.stage.Id,
				StartDate: props.stage.StartDate,
				StartHour: props.stage.StartHour,
				StartMinute: props.stage.StartMinute,
				EndDays: props.stage.EndDays,
				EndHours: props.stage.EndHours,
				EndMinutes: props.stage.EndMinutes,
			});
			setVali({
				errors:Inc.defaultStageRangeInputErrors(),
				message:"",
			});
		}
	},[props.stage,props.isOpen]);

	let hours:Dataset[] = [];
	let minutes:Dataset[] = [];
	for( let h=0 ; h<24 ; h++ ){
		let id = String(h);
		if( h<10 ){
			id = "0"+id;
		}
		hours.push({
			id: id,
			label: id + "時",
		});
	}
	for( let m=0 ; m<60 ; m+=5 ){
		let id = String(m);
		if( m<10 ){
			id = "0"+id;
		}
		minutes.push({
			id: id,
			label: id + "分",
		});
	}

	let endDays:Dataset[] = [];
	let endHours:Dataset[] = [];
	let endMinutes:Dataset[] = [];
	for( let d=0 ; d<=10 ; d++ ){
		let id = String(d);
		endDays.push({
			id: id,
			label: id + "日",
		});
	}
	for( let h=0 ; h<24 ; h++ ){
		let id = String(h);
		if( h<10 ){
			//id = "0"+id;
		}
		endHours.push({
			id: id,
			label: id + "時間",
		});
	}
	for( let m=0 ; m<60 ; m+=5 ){
		let id = String(m);
		if( m<10 ){
			//id = "0"+id;
		}
		endMinutes.push({
			id: id,
			label: id + "分",
		});
	}

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
				<DialogHeader close={props.close}/>
				<DialogContent dividers>
				<div className="confirmRoot">
					<h2>受付期間</h2>
					<Grid container spacing={1} style={{marginBottom:"2em"}}>
						<Grid item xs={12} sm={3} className="formName">受付開始日時</Grid>
						<Grid item xs={12} sm={9}>
							<Grid container spacing={0}>
								<Grid item xs={6}>
									<ColInput name="受付開始日" id="StartDate" noName={true} dense={true} type="date" error={vali.errors.StartDate} placeholder="カレンダーで選択" value={range.StartDate} change={change}/>
								</Grid>
								<Grid item xs={3}>
									<ColInput name="時" id="StartHour" noName={true} dense={true} type="select" list={hours} notEmpty={true} value={range.StartHour} change={change}/>
								</Grid>
								<Grid item xs={3}>
									<ColInput name="分" id="StartMinute" noName={true} dense={true} type="select" list={minutes} notEmpty={true} value={range.StartMinute} change={change}/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">受付終了日時</Grid>
						<Grid item xs={12} sm={9}>
							<Grid container spacing={0}>
								<Grid item xs={3} className="formValue">
									開演の
								</Grid>
								<Grid item xs={2}>
									<ColInput name="受付終了日" id="EndDays" noName={true} dense={true} type="select" notEmpty={true} list={endDays} error={vali.errors.EndDays} value={String(range.EndDays)} change={change}/>
								</Grid>
								<Grid item xs={3}>
									<ColInput name="時" id="EndHours" noName={true} dense={true} type="select" list={endHours} notEmpty={true} value={String(range.EndHours)} change={change}/>
								</Grid>
								<Grid item xs={2}>
									<ColInput name="分" id="EndMinutes" noName={true} dense={true} type="select" list={endMinutes} notEmpty={true} value={String(range.EndMinutes)} change={change}/>
								</Grid>
								<Grid item xs={2} className="formValue">
									　前
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
					<Button variant="contained" color="secondary" onClick={update}>この内容で更新する</Button>
				</DialogActions>
				<Toast close={closeToast} mes={toast}/>
		</Dialog>
	);
}

export default StageRangeInput;
