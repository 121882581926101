import React,{useState, useEffect} from 'react';
import {Link, Redirect, useParams} from 'react-router-dom';
//import useReactRouter from 'use-react-router';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Popper from '@material-ui/core/Popper';
//import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
//import MultiLines from './MultiLines';
//import store from "./store";
import ColInput from './ColInput';
import Loading from './Loading';
import Ajax from './Ajax';
import Toast from './Toast';
import StageHeader from './StageHeader';
import StageTicketInput from './StageTicketInput';
//import {Tools} from './Tools';
import {UserContainer} from './UserContainer';
import {Inc,Dataset,Stage,StageTicket,StageSeat} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
	
}

const StageSeatInput = (props:Props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		stage:Inc.defaultStage(),
		redirectId:"",
		editIndex: -1,
		hasSeat: false,
		isLoading:false,
		new:false,
	})
	const [vali,setVali] = useState({
		errors:Inc.defaultStageInputErrors(),
		message:"",
	});
	const [anchor, setAnchor] = useState({
		element:null as null | HTMLElement,
		code: "",
	});
	const {gid} = useParams<{gid:string}>();


	const changeSeat = (name:string,value:string | number | boolean)=>{
		const sp = name.split("-");
		const index = parseInt(sp[0]);
		let seats = states.stage.Seats;
		if( sp[1]==="Price" ){
			if( typeof value === "number" ){
				seats[index].Price = value;
			} else {
				seats[index].Price = null;
			}
		} else if( typeof value === "string"){
			if( sp[1]==="Name" ){
				seats[index].Name = value;
			}
		} else if( typeof value === "boolean"){
			if( sp[1]==="Accepting" ){
				seats[index].Accepting = value;
			}
		}

		const hasSeat = checkSeats(seats);
		//console.log("changeSeat",sp[1],typeof value,value,seats[index].Price);
		setStates({...states,hasSeat:hasSeat,stage:{...states.stage,Seats:seats}})
	}

	const checkSeats = (seats:StageSeat[])=>{
		let has = false;
		for( let i=0;i<seats.length;i++ ){
			if( seats[i].Name!=="" ){
				has = true;
			}
		}
		return has;
	}

	const loadStage = async (id:string)=>{
		setStates({...states,isLoading:true});
		try {
			const res = await Ajax.getStage(id,false);
			if( res.ok ){
				userState.setStage(res.stage);
				userState.setVer(res.ver);
				let flag = false;
				if( !res.stage.Tickets || res.stage.Tickets.length===0 ){
					flag = true;
				}
				let seats:StageSeat[] = [];
				for( let i=0 ; i<5 ; i++ ){
					if( res.stage.Seats[i] ){
						seats.push(res.stage.Seats[i]);
					} else {
						seats.push(Inc.defaultStageSeat());
					}
				}
				const hasSeat = checkSeats(seats);
				setStates({...states,hasSeat:hasSeat,stage:{...res.stage,Seats:seats},isLoading:false,new:flag});
			}
		} catch(error){
			throw error;
		}
	}

	const saveStage = (seats:StageSeat[])=>{
		if( !userState.user ){
			return false;
		}
		//console.log({...states.stage,Seats:seats,UserId:userState.user.uid});
		
		Ajax.setToken(userState.token);
		setStates({...states,isLoading:true});
		if( states.stage.Id!=="" ){
			let stage:Stage = {...states.stage,Seats:seats};
			//console.log("updateStage",stage);
			Ajax.updateStageSeats(stage).then((ref)=>{				
				//console.log("ref",ref);
				//userState.setStage(stage);
				setStates({...states,stage:Inc.defaultStage(),isLoading:false,redirectId:states.stage.Id});
			}).catch((error)=>{
				console.log("error",error);
			});
		}
	}
	const openStageTicketInput = (e: React.MouseEvent<HTMLElement>)=>{
		if( !e.currentTarget.dataset.index ){
			return;
		}
		const index = parseInt(e.currentTarget.dataset.index);
		//console.log(index);
		setStates({...states,editIndex:index});
	}
	const closeStageTicketInput = ()=>{
		setStates({...states,editIndex:-1});
	}
	const updateStageTicket = (stageTicket:StageTicket,deleteFlag:boolean)=>{
		//console.log("update",stageTicket);
		let tickets:StageTicket[];
		if( deleteFlag ){
			if( states.editIndex===0 ){
				tickets = states.stage.Tickets.slice(1);
			} else if( states.stage.Tickets.length>states.editIndex  ){
				tickets = states.stage.Tickets.slice(0,states.editIndex).concat(states.stage.Tickets.slice(states.editIndex+1));
			} else {
				return;
			}
		} else {
			if( states.editIndex===0 ){
				tickets = [stageTicket].concat(states.stage.Tickets.slice(1));
			} else if( states.stage.Tickets && states.stage.Tickets.length>states.editIndex  ){
				tickets = states.stage.Tickets.slice(0,states.editIndex).concat(stageTicket).concat(states.stage.Tickets.slice(states.editIndex+1));
			} else if( states.stage.Tickets ){
				tickets = states.stage.Tickets.concat(stageTicket);
			} else {
				tickets = [stageTicket];
			}
			tickets.sort( (a:StageTicket, b:StageTicket)=>{
				if (a.Code.substr(0,12) > b.Code.substr(0,12) || (a.Code.substr(0,12) === b.Code.substr(0,12) && a.Code<b.Code ) ) {
					return 1;
				} else {
					return -1;
				}
			})
		}

		setStates({...states,editIndex:-1,stage:{...states.stage,Tickets:tickets}});
	}
	const closeToast = ()=>{
		setVali({...vali,message:""});
	}
	const openAnswer = (event: React.MouseEvent<HTMLElement>)=>{
		if( !event.currentTarget.dataset.code ){
			return;
		}
		setAnchor({element:anchor.element ? null : event.currentTarget,code:event.currentTarget.dataset.code});		
	}
	const putQ = (code:string)=>{
		return (
			<IconButton size="small" onClick={openAnswer} data-code={code}><FontAwesomeIcon icon="question-circle" className="question"/></IconButton>
		);
	}

	const validate = async ()=>{
		let errors = Inc.defaultStageInputErrors();
		let flag = false;
		let seats:StageSeat[] = [];
		for( let i=0 ; i<states.stage.Seats.length ; i++ ){
			if( i>0 && states.stage.Seats[i].Name==="" && states.stage.Seats[i].Price===null && states.stage.Seats[i].Accepting===false ){
				
			} else {
				if( states.stage.Seats[i].Name==="" || states.stage.Seats[i].Price===null ){
					errors.Seats[i] = "省略できません";
					flag = true;
				} else {
					seats.push(states.stage.Seats[i]);
				}
			}
		}

		if( states.stage.Tickets.length===0 ){
			errors.Tickets = "省略できません";
			flag = true;
		}

		if( !flag ){
			setVali({...vali,errors:errors});
			saveStage(seats);
		} else {
			setVali({errors:errors,message:Inc.errorText+"部分を修正して下さい"});
		}
		window.scroll(0,0);
	}

	useEffect( ()=>{
		const id:string = String(gid).trim();
		loadStage(id);
	},[gid]);

	if( !userState.ready ){
		return (null);
	}

	if( states.redirectId!=="" ){
		const to = "/stage/ticket/"+states.redirectId;
		return (
			<Redirect to={to}/>
		)
	}

	let hours:Dataset[] = [];
	let minutes:Dataset[] = [];
	let quantitys:Dataset[] = [];
	for( let h=0 ; h<24 ; h++ ){
		let id = String(h);
		if( h<10 ){
			id = "0"+id;
		}
		hours.push({
			id: id,
			label: id + "時",
		});
	}
	for( let m=0 ; m<60 ; m+=5 ){
		let id = String(m);
		if( m<10 ){
			id = "0"+id;
		}
		minutes.push({
			id: id,
			label: id + "分",
		});
	}
	for( let q=1 ; q<=Inc.maxQuantity ; q++ ){
		let id = String(q);
		quantitys.push({
			id: id,
			label: id + "枚まで",
		});
	}

	let toBack = "/";
	if( states.stage.Id!=="" ){
		toBack = "/stage/ticket/"+states.stage.Id;
	}

	let title = states.stage.Title;
	let organizer = states.stage.Organizer;
	if( title==="" ){
		title = "タイトル未定";
	}
	if( organizer==="" ){
		organizer = "新規登録";
	}

	let editTicket:StageTicket;
	if( states.stage.Tickets && states.editIndex>=0 && states.stage.Tickets.length>states.editIndex ){
		editTicket = states.stage.Tickets[states.editIndex];
	} else {
		editTicket = Inc.defaultStageTicket();
	}

	const popperOpen = Boolean(anchor.element);
	const popperId = popperOpen ? 'simple-popper' : undefined;

	return (
		<React.Fragment>
			<StageHeader stage={userState.stage} current="ticket"/>
			<div id="event_input" className="mediumRoot">
				<div className="paper">
					<div className="pageTitle">
						<div className="btns">
							{!states.new &&
							<Link to={toBack} className="decn"><Button variant="text" color="default" style={{marginRight:"0.25em",textDecoration:"none"}}>もどる</Button></Link>
							}
							<Button variant="contained" color="primary" disabled={states.hasSeat===false} onClick={validate} style={{width:"8em"}}>登録する</Button>
						</div>
					</div>
					<Grid container spacing={3}>
						<Grid item xs={12} md={5} style={{paddingBottom:"2em",paddingRight:"2em"}}>
							<div style={{marginBottom:"0.5em"}}>
								<Grid container spacing={0} className="stageInput">
									<Grid item xs={12} className="formName formNameVertical">チケットの種類{putQ("Seat")}</Grid>
									<Grid item xs={12} className="separate"></Grid>
							{states.stage.Seats.map( (seat:StageSeat,index:number)=>{
								const key = "Seat-"+index;
								const key1 = String(index)+"-Name";
								const key2 = String(index)+"-Price";
								const key3 = String(index)+"-Accepting";
								return (
									<React.Fragment key={key}>
										<Grid item xs={5} sm={6}>
											<ColInput name="" noName={true} id={key1} type="text" dense={true} error={vali.errors.Seats[index]} placeholder="チケットの種類" startAdornment={Inc.seats[index]} value={seat.Name} change={changeSeat}/>
										</Grid>
										<Grid item xs={3} sm={3}>
											<ColInput name="" noName={true} id={key2} type="number" dense={true} placeholder="税込価格" endAdornment="円" value={seat.Price} change={changeSeat}/>
										</Grid>
										<Grid item xs={4} sm={3}>
											<ColInput name="" noName={true} id={key3} type="onoff" useBool={true} dense={true} value={seat.Accepting} change={changeSeat}/>
										</Grid>
									</React.Fragment>
								)

							})}
								</Grid>
							</div>
						</Grid>
						<Grid item xs={12} md={7}>
							<div style={{marginBottom:"1em"}} className="tickets">
								<Grid container spacing={0} className="stageInput">
									<Grid item xs={12} className="formName formNameVertical">開演日時と枚数{putQ("Ticket")}</Grid>
								{states.stage.Tickets && states.stage.Tickets.map( (ticket:StageTicket,index1:number)=>{
									const key1 = "Ticket-"+index1;
									return (
										<React.Fragment key={key1}>
										<Grid item xs={12} className="separate"></Grid>
										<Grid item xs={12} md={4}>
											<span style={{fontWeight:"bold"}}>{ticket.Date}　{ticket.Hour}:{ticket.Minute}</span>
										</Grid>
										<Grid item xs={6} md={5}>
											<ul>
											{ticket.TargetSeats.map((flag:boolean,index2:number)=>{
												const key2 = "seat-" + index2;
												if( !states.stage.Seats[index2] || states.stage.Seats[index2].Name==="" || !flag ){
													return (null);
												}
												return (
													<li key={key2}>
														{Inc.seats[index2]} {states.stage.Seats[index2].Name}
													</li>
												);
											})}
											{states.hasSeat===false &&
											<li>　</li>
											}
											</ul>
										</Grid>
										<Grid item xs={6} md={3}>
											<div className="stageLimit">
											{ticket.UseLimit ? (
												<span>{ticket.Limit}枚</span>
											):(
												<span>制限なし</span>
											)}
												<span className="btn"><IconButton size="small" data-index={index1} onClick={openStageTicketInput}><FontAwesomeIcon icon="edit"/></IconButton></span>
											</div>
										</Grid>								   
										</React.Fragment>
									)
								})}
								{vali.errors.Tickets!=="" &&
									<Grid item xs={12}>
										<FormControl error={true}>
											<FormHelperText>※{vali.errors.Tickets}</FormHelperText>
										</FormControl>
									</Grid>
								}
									<Grid item xs={12} className="separate"></Grid>
								</Grid>
								{states.hasSeat ? (
								<div style={{marginBottom:"2em",textAlign:"left"}}>
									<Button variant="outlined" color="primary" data-index={9999} onClick={openStageTicketInput}><FontAwesomeIcon icon="plus" style={{marginRight:"0.5em"}}/>開演日時を追加</Button>
								</div>
								):(
								<div style={{color:"#DA4234"}}>販売可能なチケットの種類を１つ以上入力して下さい。</div>
								)}
							</div>
						</Grid>
					</Grid>
				</div>
				<Popper id={popperId} open={popperOpen} anchorEl={anchor.element}>
					{anchor.code==="Seat" &&
					<div className="popperAnswer">一般用や学生用などチケットが複数種類ある場合は5種類まで登録できます。<div style={{fontSize:"85%",marginTop:"1em"}}>※右端のスイッチをOFFにするとお客様用の受付フォームには表示されなくなります。</div></div>
					}
					{anchor.code==="Ticket" &&
					<div className="popperAnswer">開演日時を設定します。開演日時とチケット種類ごとに、販売する枚数の上限を設定することが可能です。</div>
					}
					
      			</Popper>
				<StageTicketInput isOpen={states.editIndex>=0} index={states.editIndex} close={closeStageTicketInput} ticket={editTicket} stage={states.stage} update={updateStageTicket}/>
				<Toast mes={vali.message} close={closeToast}/>
				<Loading isLoading={states.isLoading}/>
			</div>
		</React.Fragment>
	);
}

export default StageSeatInput;
