import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Tools} from './Tools';
import Grid from '@material-ui/core/Grid';
import DialogHeader from './DialogHeader';
import ColInput from './ColInput';
import Toast from './Toast';
import Ajax from './Ajax';
import {Inc,Dataset,Stage,StageSeat,StageTicket,StageForm,StageBookingSearchParam} from './Inc';
import {Theme} from './Theme';

interface Props {
    close():void,
    send(param:StageBookingSearchParam):void,
    isOpen:boolean,
    stage:Stage,
    param:StageBookingSearchParam,
	formCode?:string,
}

const StageBookingSearch = (props:Props)=> {
    const [param,setParam] = useState(Inc.defaultStageBookingSearchParam())
    const [toast,setToast] = useState("");
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
    //const [message,setMessage] = useState("");
    //const [vali,setVali] = useState({
    //    errors:Inc.defaultStageTicketInputErrors(),
    //    message:"",
    //});
    const [states,setStates] = useState({
		payments:[] as Dataset[],
        tickets:[] as Dataset[],
        seats:[] as Dataset[],
        forms:[] as Dataset[],
        quantitys:[] as Dataset[],
    })

    const change = (name:keyof StageTicket,value:string|number|boolean)=>{
        if( typeof value === "string" ){
            if( name==="Date" ){
                const sp = value.split(/[^0-9]/);
                value = Tools.zeroPadding(sp[0],4) + "/" + Tools.zeroPadding(sp[1],2) + "/" + Tools.zeroPadding(sp[2],2);
            }
        }
        if( typeof value === "boolean" ){

		}
        setParam({...param,[name]:value});
    }
    const update = ()=>{
        props.send({...param,P:1});
    }
    const closeToast = ()=>{
        setToast("");
	}
	
	const prepare = async ()=>{
        if( props.stage.Id!=="" ){
			let payments:Dataset[] = [];
            let tickets:Dataset[] = [];
            let seats:Dataset[] = [];
            let exists:{[key: string]: boolean} = {};
            let forms:Dataset[] = [];
            let quantitys:Dataset[] = [];

			await Ajax.getBookingPayments(props.stage.Id).then((res)=>{
				if( res.ok ){
					res.payments.map((payment:string,index:number)=>{
						payments.push({
							id: payment,
							label: payment,
						})
						return true;
					})
				}
			}).catch((error)=>{

			})

			props.stage.Tickets.map((ticket:StageTicket,index:number)=>{
				if( !exists[ ticket.Code.substr(0,12) ] ){
					exists[ ticket.Code.substr(0,12) ] = true;
					tickets.push({
						id: ticket.Code.substr(0,12),
						label: ticket.Label,
					})
				}
				return true;
			});

            props.stage.Seats.map((seat:StageSeat,index:number)=>{
                if( seat.Price && seat.Accepting ){
                    seats.push({
                        id: String(index+1),
                        label: Inc.seats[index] + "　" + seat.Name + "（" + Tools.comma(seat.Price) + "円）",
                    })
				}
				return true;
            });

            props.stage.Forms.map((form:StageForm,index:number)=>{
                if( index>0 ){
                    forms.push({
                        id: form.Name,
                        label: form.Name,
                    })
                } else {
                    forms.push({
                        id: "form0",
                        label: Inc.defaultFormName,
                    })
				}
				return true;
            });

            for( let i=1 ; i<=Inc.maxQuantity; i++ ){
                quantitys.push({
                    id: String(i),
                    label: String(i)+"枚",
                })
            }

            setStates({...states,payments:payments,tickets:tickets,seats:seats,forms:forms,quantitys:quantitys});
        }
	}

    useEffect(()=>{
		if(!props.isOpen){
			return;
		}
		if( props.formCode ){
			let formName = '';
			for( let i=0; i<props.stage.Forms.length; i++ ){
				if(props.stage.Forms[i].Code===props.formCode){
					formName = props.stage.Forms[i].Name;
				}
			}
			setParam({...props.param,FormName:formName});
		} else {
			setParam(props.param);
		}
		prepare();
    },[props.isOpen]);

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
        >
                <DialogHeader close={props.close}/>
				<DialogContent dividers>
                {props.param && param &&
                <div className="confirmRoot">
                    <h2>検索条件</h2>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={3} className="formName">時間・回</Grid>
                        <Grid item xs={12} md={9} style={{paddingBottom:'1em'}}>
                            <ColInput id="TicketCode" noName={true} dense={true} type="select" name="時間・回" list={states.tickets} value={param.TicketCode} change={change}/>
                        </Grid>
                        <Grid item xs={12} md={3} className="formName">チケット種類</Grid>
                        <Grid item xs={12} md={9} style={{paddingBottom:'1em'}}>
                            <ColInput id="SeatId" noName={true} dense={true} type="select" name="チケット種類" list={states.seats} value={param.SeatId} change={change}/>
                        </Grid>
						{!props.formCode &&
						<>
                        <Grid item xs={12} md={3} className="formName">フォーム</Grid>
                        <Grid item xs={12} md={9} style={{paddingBottom:'1em'}}>
                            <ColInput id="FormName" noName={true} dense={true} type="select" name="フォーム" list={states.forms} value={param.FormName} change={change}/>
                        </Grid>
						</>
						}
                        <Grid item xs={12} md={3} className="formName">名前</Grid>
                        <Grid item xs={12} md={9} style={{paddingBottom:'1em'}}>
                            <ColInput id="Name" noName={true} dense={true} type="text" name="名前" value={param.Name} change={change}/>
                        </Grid>
                        <Grid item xs={12} md={3} className="formName">メールアドレス</Grid>
                        <Grid item xs={12} md={9} style={{paddingBottom:'1em'}}>
                            <ColInput id="Email" noName={true} dense={true} type="text" name="メールアドレス" value={param.Email} change={change}/>
                        </Grid>
                        <Grid item xs={12} md={3} className="formName">メール確認</Grid>
                        <Grid item xs={12} md={9} style={{paddingBottom:'1em'}}>
                            <ColInput id="Checked" noName={true} dense={true} type="select" name="メール確認" list={Inc.checked} value={param.Checked} change={change}/>
                        </Grid>
						{props.stage.UseTel &&
						<React.Fragment>
                        <Grid item xs={12} md={3} className="formName">電話番号</Grid>
                        <Grid item xs={12} md={9} style={{paddingBottom:'1em'}}>
                            <ColInput id="Tel" noName={true} dense={true} type="text" name="電話番号" value={param.Tel} change={change}/>
                        </Grid>
						</React.Fragment>
						}
						{props.stage.UseEnq &&
						<React.Fragment>
                        <Grid item xs={12} md={3} className="formName">公演情報</Grid>
                        <Grid item xs={12} md={9} style={{marginBottom:"0.5em"}}>
                            <ColInput id="Contact" noName={true} dense={true} type="select" list={Inc.contacts} name="公演情報" value={param.Contact} change={change}/>
                        </Grid>
						</React.Fragment>
						}                  
						<Grid item xs={12} sm={3} className="formName">支払方法</Grid>
                        <Grid item xs={12} md={9} style={{marginBottom:"0.5em"}}>
                            <ColInput id="Payment" noName={true} dense={true} type="select" list={states.payments} name="" value={param.Payment} change={change}/>
                        </Grid>
                        <Grid item xs={12} style={{marginBottom:"0.5em",textAlign:'right'}}>
							<ColInput id="Canceled" noName={true} dense={true} type="checkbox" name="" label="キャンセルも表示する" value={param.Canceled ? '1' : '0'} change={change}/>
                        </Grid>                            
                    </Grid>

                </div>
                }
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
                    <Button variant="contained" color="secondary" onClick={update}>この条件で検索する</Button>
				</DialogActions>
                <Toast close={closeToast} mes={toast}/>
        </Dialog>
    );
}

export default StageBookingSearch;
