import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
//import {Tools} from './Tools';
import Grid from '@material-ui/core/Grid';
import DialogHeader from './DialogHeader';
import Toast from './Toast';
import SampleView from './SampleView';
import {Stage,StageBookingSearchParam} from './Inc';
import Ajax from './Ajax';
//import {UserContainer} from './UserContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Theme} from './Theme';

interface Props {
    close():void,
	update():void,
	upload(file:File):void,
	download():void,
	error:string,
	ver:number,
    param:StageBookingSearchParam,
    isOpen:boolean,
    stage:Stage,
}

const StageBookingCsvMenu = (props:Props)=> {
	//const userState = UserContainer.useContainer();
    const [toast,setToast] = useState("");
    //const [message,setMessage] = useState("");
	const [uploadFile,setUploadFile] = useState<File>()
	const [sampleCode,setSampleCode] = useState("");
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));

    const closeToast = ()=>{
        setToast("");
    }

    useEffect(()=>{

    },[props.isOpen]);


    const changeFile = (e: React.ChangeEvent<HTMLInputElement>)=>{
        const target = e.target;
        if( !target || !target.files || !target.files.item(0) ){
            return;
        }
        const file = target.files.item(0) as File;
        setUploadFile(file);
    }

	const upload = ()=>{
        if(!uploadFile){
            setToast("ファイルを選択して下さい");
            return;
		}
		props.upload(uploadFile);
	}

	const download = ()=>{
		props.download();
	}

//    const upload2 = ()=>{
//        if(!uploadFile){
//            setToast("ファイルを選択して下さい");
//            return;
//		}
//		Ajax.setToken(userState.token);
//        Ajax.uploadBookingCsv(uploadFile,props.stage.Id).then((res)=>{
//            if( res.ok ){
//                props.update();
//            } else {
//                setToast(res.error)
//            }
//        }).catch((error)=>{
//            setToast("予期しないエラーです");
//        });
//    }

//    const download2 = async ()=>{
//		Ajax.setToken(userState.token);
//        await Ajax.downloadBookingCsv(props.stage.Id,props.param).then((res)=>{
//
//        }).catch((error)=>{
//            setToast("ダウンロードできませんでした");
//        });
//    }

	const openSample = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.code ){
			return;
        }
		setSampleCode(e.currentTarget.dataset.code);
	}
	const closeSample = ()=>{
		setSampleCode("");
	}
	
	const downloadSample = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.code ){
			return;
		}
		if(e.currentTarget.dataset.code==="csvdownload"){
			Ajax.downloadSampleDownloadCsv();
		}
		if(e.currentTarget.dataset.code==="csvupload"){
			Ajax.downloadSampleUploadCsv();
		}
	}

	useEffect(()=>{
		if( props.error!=="" ){
			setToast(props.error)
		}
	},[props.ver]);

    return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
                <DialogHeader close={props.close}/>
				<DialogContent dividers>
                <div className="confirmRoot">
                    <h2>予約の一括処理（ＣＳＶ）</h2>
					<div style={{marginBottom:"1em"}}>仕様やサンプルファイルを参考にCSVファイルを作成してください。</div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <div className="csvBox">
                                <h3>ファイルで登録</h3>
                                <div className="text">
                                    CSVファイルをアップロードして予約を一括登録します。同時に登録できる予約は100件までです。
                                </div>
								<div className="sampleBtns">
									<Button variant="text" color="primary" size="small" onClick={openSample} data-code="csvupload">CSV仕様</Button><br/>
									<Button variant="text" color="primary" size="small" onClick={downloadSample} data-code="csvupload">サンプルファイル</Button><br/>
								</div>
                                <div className="form">
                                    <input type="file" name="upload" onChange={changeFile}/>
                                </div>
                                <div className="btn">
                                    <Button variant="contained" color="secondary" onClick={upload} style={{width:"100%"}}><FontAwesomeIcon icon="file-upload" style={{marginRight:"0.25em"}}/>アップロードする</Button>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        <div className="csvBox">
                                <h3>ダウンロード</h3>
                                <div className="text">
                                    検索条件に合った予約データをCSVファイルとしてダウンロードします。
                                </div>
								<div className="sampleBtns">
									<Button variant="text" color="primary" onClick={openSample} size="small" data-code="csvdownload">CSV仕様</Button><br/>
									<Button variant="text" color="primary" onClick={downloadSample} size="small" data-code="csvdownload">サンプルファイル</Button><br/>
								</div>
                                <div className="btn">
                                    <Button variant="contained" color="secondary" onClick={download} style={{width:"100%"}}><FontAwesomeIcon icon="file-download" style={{marginRight:"0.25em"}}/>ダウンロードする</Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
				</DialogContent>
				<DialogActions>
					<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
				</DialogActions>
                <Toast close={closeToast} mes={toast}/>
				<SampleView code={sampleCode} close={closeSample}/>
        </Dialog>
    );
}

export default StageBookingCsvMenu;
