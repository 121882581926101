import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ColInput from './ColInput';
import {Inc,Stage,StageFormConfig} from './Inc';
import DialogHeader from './DialogHeader';
import {Theme} from './Theme';

interface Props {
	close():void,
	stage:Stage,
	update(config:StageFormConfig):void,
	isOpen:boolean,
}

const StageFormConfigInput = (props:Props)=> {
	const [config,setConfig] = useState(Inc.defaultStageFormConfig());
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));

	const change = (name:string,value:string)=>{
		setConfig({...config,[name]:value});
	}

	const update = ()=>{
		props.update(config);
	}

	useEffect(()=>{
		if( props.isOpen ){
			setConfig({
				StageId: props.stage.Id,
				UseTel: props.stage.UseTel,
				UseMemo: props.stage.UseMemo,
				UseEnq: props.stage.UseEnq,
			});
		}
	},[props.isOpen]);

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
			<DialogHeader close={props.close}/>
			<DialogContent dividers>
			<div className="confirmRoot">
				<h2>フォーム設定</h2>
				<Box style={{marginBottom:"1em"}}>
					予約フォームに必要な項目をチェックして下さい
				</Box>
				<Box style={{marginBottom:"0em"}}>
					<ColInput name="" noName={true} id="UseTel" type="checkbox" useBool={true} dense={true} label="電話番号" value={config.UseTel} change={change}/>
				</Box>
				<Box style={{marginBottom:"0em"}}>
					<ColInput name="" noName={true} id="UseEnq" type="checkbox" useBool={true} dense={true} label="公演情報が届いているかの確認" value={config.UseEnq} change={change}/>
				</Box>
				<Box style={{marginBottom:"2em"}}>
					<ColInput name="" noName={true} id="UseMemo" type="checkbox" useBool={true} dense={true} label="備考欄（フリーテキスト）" value={config.UseMemo} change={change}/>
				</Box>
			</div>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
				<Button variant="contained" color="secondary" onClick={update}>この内容で更新する</Button>
			</DialogActions>
		</Dialog>
	);
}

export default StageFormConfigInput;
