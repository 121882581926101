import React,{useState, useEffect} from 'react';
import {Link, Redirect, useParams} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ClimbUp from './ClimbUp';
import Loading from './Loading';
import Yesno from './Yesno';
import Ajax from './Ajax';
import NotFound from './NotFound';
import Toast from './Toast';
import MultiLines from './MultiLines';
import StageHeader from './StageHeader';
import LoadImage from './LoadImage';
import {UserContainer} from './UserContainer';
import {Inc} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import "./StageDetail.scss";
import { Tools } from './Tools';

interface Props {
	
}

//interface RCProps extends RouteComponentProps<{id:string}> {
//	
//}

const StageDetail = (props:Props)=> {
	const userState = UserContainer.useContainer();
	//const rcp:RCProps = useReactRouter();
	const [states,setStates] = useState({
		stage:Inc.defaultStage(),
		isLoading:true,
		editIndex: -1,
	})
	const [redirectId,setRedirectId] = useState("");
	const [notFound,setNotFound] = useState(false);
	const [message,setMessage] = useState("");
	const [toast,setToast] = useState("");
	const {gid} = useParams<{gid:string}>();

	const confirm = ()=>{
		setMessage("このイベントを削除してよろしいですか？");
	}

	const closeToast = ()=>{
		setToast("");
	}

	const yes = ()=>{
		setStates({...states, isLoading:true});
		Ajax.setToken(userState.token);
		Ajax.removeStage(userState.stage.Id).then((res)=>{
			if( res.ok ){
				setRedirectId("/");
			} else {
				setStates({...states, isLoading:false});
				setToast("削除できませんでした");
			}
		}).catch((error)=>{
			setStates({...states, isLoading:false});
			setToast("削除できませんでした");
		});
	}
	const no = ()=>{
		setMessage("");
	}
	const load = async (stageId:string)=>{
		try {
			const res = await Ajax.getStage(stageId,false);
			//console.log(res);
			if( res.ok ){
				userState.setStage(res.stage);
				userState.setVer(res.ver);
				setStates({...states, stage:res.stage, isLoading:false});	
			} else {
				setNotFound(true);
			}
		} catch(error) {
			console.log("error",error);
			setRedirectId("/");
		}
	}

	useEffect( ()=>{
		const id:string = String(gid).trim();
		if( id==="" ){
			setRedirectId("/");
			return;
		}
		if( !userState.user ){
			return;
		}
		load(id);
		window.scrollTo(0,0);

	},[userState.user,gid]);

	if( notFound ){
		return (
			<NotFound/>
		)
	}

	if( !userState.ready ){
		return (null);
	}

	if( redirectId!=="" ){
		return (
			<Redirect to={redirectId}/>
		)
	}

	const toEdit = "/stage/input/"+states.stage.Id;

	return (
		<React.Fragment>
			<StageHeader stage={userState.stage} current="detail"/>
			<div id="stage_detail" className="mediumRoot">
				<div className="paper">
					<Grid container spacing={3}>
						<Grid item xs={6}>
							<Button variant="contained" color="default" disabled={!states.stage.Removable} onClick={confirm}>
								<FontAwesomeIcon icon="trash-alt"/>削除する
							</Button>
						</Grid>
						<Grid item xs={6} style={{textAlign:"right"}}>
							<Link to={toEdit} className="decn"><Button variant="contained" color="secondary">
								<FontAwesomeIcon icon="edit"/>編集する
							</Button></Link>
						</Grid>
						<Grid item xs={12} sm={12} md={6} style={{paddingTop:"1em",paddingBottom:"2em"}}>
							<Table>
								<TableBody>
									<TableRow>
										<TableCell className="colName">イベント名称</TableCell>
										<TableCell>{states.stage.Title}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">アーティスト</TableCell>
										<TableCell>{states.stage.Organizer}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">会場</TableCell>
										<TableCell>{states.stage.Place}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">コード</TableCell>
										<TableCell>{states.stage.Code}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">通知メール</TableCell>
										<TableCell>{states.stage.BccMail}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">整理番号</TableCell>
										<TableCell>
											{Tools.getLabel(Inc.refnums,states.stage.UseNumber)}
											{states.stage.UseNumber && states.stage.Prefix!=="" &&
											<span>（{states.stage.Prefix}0001～）</span>
											}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">同時予約枚数</TableCell>
										<TableCell>{states.stage.MaxQuantity}枚まで</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">公式サイト</TableCell>
										<TableCell>{states.stage.Url}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">問合せ窓口</TableCell>
										<TableCell>{states.stage.Desk}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">窓口メール</TableCell>
										<TableCell>
									{states.stage.Email ? (
									<span>{states.stage.Email}</span>
									):(
									<span className="empty">なし</span>
									)}

										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName">窓口電話</TableCell>
										<TableCell>
										{states.stage.Tel ? (
									<span>{states.stage.Tel}</span>
									):(
									<span className="empty">なし</span>
									)}

										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell className="colName noWrap">イメージ画像</TableCell>
										<TableCell>
										{states.stage.ImageKey ? (
										<LoadImage src={Tools.getUploadImgPath(states.stage.ImageKey)}/>
										):(
										<span className="empty">なし</span>
										)}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
							
						</Grid>
						<Grid item xs={12} sm={12} md={6} style={{paddingTop:"1em"}}>
							<Box className="stageTextBox">
								<Box className="name">イベント内容</Box>
								<Box className="value">
									<MultiLines text={states.stage.Description}/>
								</Box>
							</Box>
							<Box className="stageTextBox">
								<Box className="name">当日の案内</Box>
								<Box className="value">
									<MultiLines text={states.stage.Message}/>
								</Box>
							</Box>
							<Box className="stageTextBox">
								<Box className="name">緊急のお知らせ</Box>
								<Box className="value notice">
									{states.stage.Notice!=="" ? (
									<MultiLines text={states.stage.Notice}/>
									):(
									<span className="empty">なし</span>
									)}
								</Box>
							</Box>
							<Box className="stageTextBox">
								<Box className="name">仮予約の注意事項</Box>
								<Box className="value">
									<MultiLines text={states.stage.Alert}/>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</div>
				<Toast close={closeToast} mes={toast}/>
				<Yesno mes={message} yes={yes} no={no} isOpen={message!==""}/>
				<Loading isLoading={states.isLoading}/>
				<ClimbUp/>
			</div>
		</React.Fragment>
	);
}

export default StageDetail;
