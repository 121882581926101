import React,{useState, useEffect} from 'react';
import {Link, Redirect, useParams} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ClimbUp from './ClimbUp';
//import store from "./store";
import Loading from './Loading';
import Yesno from './Yesno';
import Ajax from './Ajax';
import NotFound from './NotFound';
import Toast from './Toast';
//import MultiLines from './MultiLines';
import StageHeader from './StageHeader';
import {UserContainer} from './UserContainer';
import {Inc,StageSeat,StageTicket} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import "./StageDetail.scss";
import { Tools } from './Tools';

interface Props {
	
}

//interface RCProps extends RouteComponentProps<{id:string}> {
//	
//}

const StageSeatList = (props:Props)=> {
	const userState = UserContainer.useContainer();
	//const rcp:RCProps = useReactRouter();
	const [states,setStates] = useState({
		stage:Inc.defaultStage(),
		rests: {} as {[key:string]:number;},
		isLoading:true,
		editIndex: -1,
	})
	const [redirectId,setRedirectId] = useState("");
	const [notFound,setNotFound] = useState(false);
	const [message,setMessage] = useState("");
	const [toast,setToast] = useState("");
	const {gid} = useParams<{gid:string}>();

	const closeToast = ()=>{
		setToast("");
	}

	const yes = ()=>{
		setStates({...states, isLoading:true});
		Ajax.setToken(userState.token);
		Ajax.removeStage(userState.stage.Id).then((res)=>{
			if( res.ok ){
				setRedirectId("/");
			} else {
				setStates({...states, isLoading:false});
				setToast("削除できませんでした");
			}
		}).catch((error)=>{
			setStates({...states, isLoading:false});
			setToast("削除できませんでした");
		});
	}
	const no = ()=>{
		setMessage("");
	}
	const load = async (stageId:string)=>{
		try {
			const res = await Ajax.getStage(stageId,true);
			if( res.ok ){
				userState.setStage(res.stage);
				userState.setVer(res.ver);
				let rests = {} as {[key:string]:number;};
				if( res.rests ){
					for( let i=0; i<res.rests.length; i++ ){
						rests[ res.rests[i].TicketCode ] = res.rests[i].Rest
					}
				}
				setStates({...states, stage:res.stage, rests:rests, isLoading:false});	
			} else {
				setNotFound(true);
			}
		} catch(error) {
			console.log("error",error);
			setRedirectId("/");
		}
	}

	useEffect( ()=>{
		const id:string = String(gid).trim();
		if( id==="" ){
			setRedirectId("/");
			return;
		}
		if( !userState.user ){
			return;
		}
		load(id);
	},[userState.user,gid]);

	if( notFound ){
		return (
			<NotFound/>
		)
	}

	if( !userState.ready ){
		return (null);
	}

	if( redirectId!=="" ){
		return (
			<Redirect to={redirectId}/>
		)
	}

	const toEdit = "/stage/ticket/input/"+states.stage.Id;

	return (
		<React.Fragment>
			<StageHeader stage={userState.stage} current="ticket"/>
			<div id="stage_detail" className="mediumRoot">
				<div className="paper">
					<Grid container spacing={3}>
						<Grid item xs={6}>

						</Grid>
						<Grid item xs={6} style={{textAlign:"right"}}>
							<Link to={toEdit} className="decn"><Button variant="contained" color="secondary">
								<FontAwesomeIcon icon="edit"/>編集する
							</Button></Link>
						</Grid>
						<Grid item xs={12} sm={12} md={5} style={{paddingTop:"1em",paddingBottom:"2em"}}>
							<Grid container spacing={0} className="stageInput" style={{marginBottom:"2em"}}>
								<Grid item xs={12} className="formName formNameVertical">チケット種類</Grid>
								{states.stage.Seats.map( (seat:StageSeat,index:number)=>{
									const key = "seat"+index;
									if(seat.Name===""){
										return (null);
									}
									let price = 0;
									if( seat.Price ){
										price = seat.Price;
									}
									return (
										<React.Fragment key={key}>
											<Grid item xs={12} className="separate"></Grid>
											<Grid item xs={6} style={{paddingLeft:"0"}}><span style={{marginRight:"1em"}}>{Inc.seats[index]}</span>{seat.Name}</Grid>
											<Grid item xs={4} style={{textAlign:"right"}}>
												{Tools.comma(price)}円
											</Grid>
											<Grid item xs={2} style={{textAlign:"center"}}>
												{seat.Accepting ? (
													<span className="swOn">ON</span>
												):(
													<span className="swOff">OFF</span>
												)}
											</Grid>
										</React.Fragment>
									);
								})}
								<Grid item xs={12} className="separate"></Grid>
							</Grid>
							


						</Grid>
						<Grid item xs={12} sm={12} md={7} style={{paddingTop:"1em"}}>
							<Grid container spacing={0} className="stageInput">
								<Grid item xs={8} className="formName formNameVertical">開演日時</Grid>
								<Grid item xs={2} className="formName formNameVertical" style={{textAlign:"right"}}>枚数</Grid>
								<Grid item xs={2} className="formName formNameVertical" style={{textAlign:"right"}}>予約済</Grid>
								{states.stage.Tickets && states.stage.Tickets.map( (ticket:StageTicket,index1:number)=>{
									const key = "ticket"+index1;
									//let limit = "制限なし";
									//if( ticket.UseLimit ){
									//	limit = ticket.Limit + "枚";
									//	if( states.rests[ticket.Code] ){
									//		limit = limit + " / 残" + states.rests[ticket.Code];
									//	}
									//}
									return (
										<React.Fragment key={key}>
											<Grid item xs={12} className="separate"></Grid>
											<Grid item xs={12} md={4}>
												<span style={{fontWeight:"bold"}}>{ticket.Date}　{ticket.Hour}:{ticket.Minute}</span>
											</Grid>
											<Grid item xs={7} md={4}>
												<ul>
												{ticket.TargetSeats.map((flag:boolean,index2:number)=>{
													const key2 = "seat-" + index2;
													if( !states.stage.Seats[index2] || states.stage.Seats[index2].Name==="" || !flag ){
														return (null);
													}
													return (
														<li key={key2}>
															{Inc.seats[index2]} {states.stage.Seats[index2].Name}
														</li>
													);
												})}
												</ul>
											</Grid>
											<Grid item xs={2} md={2} style={{textAlign:"right"}}>
												{ticket.UseLimit &&
												<span>{ticket.Limit}枚</span>
												}
											</Grid>
											<Grid item xs={3} md={2} style={{textAlign:"right"}}>
												{ticket.UseLimit && states.rests[ticket.Code]>0 && ticket.Limit &&
												<div>
													<span className="reserved">{ticket.Limit-states.rests[ticket.Code]}枚</span><br/>
													<span className="available">残{states.rests[ticket.Code]}</span><br/>
												</div>
												}
												{ticket.UseLimit && states.rests[ticket.Code]===0 &&
												<span className="soldout">売切</span>
												}
											</Grid>
										</React.Fragment>
									)
								})}
								<Grid item xs={12} className="separate"></Grid>
							</Grid>
						</Grid>
					</Grid>
				</div>
				<Toast close={closeToast} mes={toast}/>
				<Yesno mes={message} yes={yes} no={no} isOpen={message!==""}/>
				<Loading isLoading={states.isLoading}/>
				<ClimbUp/>
			</div>
		</React.Fragment>
	);
}

export default StageSeatList;
