import React,{useEffect,useState} from 'react';
import {useParams} from 'react-router-dom'//yarn add @types/react-router-dom
import Loading from './Loading';
import SecretForm from './SecretForm';
import NotFound from './NotFound';
//import MultiLines from './MultiLines';
//import ClimbUp from './ClimbUp';
import {Inc} from "./Inc";
import store from './store';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Form = ()=> {
    const [stage,setStage] = useState(Inc.defaultStage());
    const [notFound,setNotFound] = useState(false);
    const {code} = useParams<{code:string}>();

    useEffect( ()=>{
        const id:string = String(code).trim();
        if( id==="" ){
            setNotFound(true);
            return;
        }
        store.getStage(id).then((stage)=>{
            setStage(stage);
        })
        .catch((error)=>{
            setNotFound(true);
        });
    },[code]);

    //console.log(stage);

    if( notFound ){
        return (
            <NotFound/>
        )
    }

    if( !stage ){
        return (
            <Loading isLoading={true}/>
        )
    }

    if( !stage.Accepting ){
        return (
            <SecretForm/>
        );    
    }

    return (
        <div id="view" className="mediumRoot">
            {stage.Title}
        </div>
    );
}

export default Form;
