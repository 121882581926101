import React from 'react';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogHeader from './DialogHeader';
//import useStyles from './Styles';
import Title from './Title';
import {Theme} from './Theme';

interface Props {
    latest:string,
    isOpen:boolean,
}

const OldVersion:React.FC<Props> = (props)=> {
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	
    //const classes = useStyles();
    if( !props.isOpen ){
        return (null);
	}
	
	const close = ()=>{

	}

	return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				//none
				close();
			}}
			fullScreen={fullScreen}
        >
				<DialogHeader close={close}/>
				<DialogContent dividers>
				<div className="confirmRoot">
                    <Title text="最新バージョンにアップデートして下さい" size={2}/>
                    <Grid container spacing={4} style={{marginBottom:"1em"}}>
                        <Grid item xs={12} sm={6}><div className="verBox">
                            <h3>ご利用中のバージョン</h3>
                            <div className="current">
                                {process.env.REACT_APP_VERSION}
                            </div>
                        </div></Grid>
                        <Grid item xs={12} sm={6}><div className="verBox">
                            <h3>最新バージョン</h3>
                            <div className="latest">
                                {props.latest}
                            </div>
                        </div></Grid>
                    </Grid>
                    <div className="verGuide">
                        <Title text="スマホ（iOS/Android）の場合" size={3}/>
                        <div>
                            「画面を上から下へスワイプして放す」を何度か行うことで更新されます。
                            <p>
                            ※キャッシュの削除が必要になる場合もあります。<br/>
                            </p>
                        </div>
                    </div>
                    <div className="verGuide">
                        <Title text="アプリの場合" size={3}/>
                        <div>
                            「ホーム画面に追加」してアプリとして起動している場合は、アプリを終了してからもう一度起動すると最新バージョンが読み込まれます。
                            <p>
                            ※２～３回繰り返さないと更新されない場合もあります。<br/>
                            </p>
                        </div>
                    </div>
                    <div className="verGuide">
                        <Title text="Windowsの場合" size={3}/>
                        <div>
                            強制リロード（Ctrl+F5）で最新バージョンが読み込まれます。<br/>
                            <p>
                            ※キャッシュの削除が必要になる場合もあります。<br/>
                            </p>
                        </div>
                    </div>
                    <div className="verGuide">
                        <Title text="Macの場合" size={3}/>
                        <div>
                            強制リロード（command+R 又は command+Shift+R）で最新バージョンが読み込まれます。<br/>
                            <p>
                            ※キャッシュの削除が必要になる場合もあります。<br/>
                            </p>
                        </div>
                    </div>
                </div>
				</DialogContent>
        </Dialog>

    );
}

export default OldVersion;
