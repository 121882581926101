import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogHeader from './DialogHeader';
//import useStyles from './Styles';
import Title from './Title';
import {Theme} from './Theme';
import {UserContainer} from './UserContainer';

interface Props {
    isOpen:boolean,
	close():void,
}

const EmailSend:React.FC<Props> = (props)=> {
	const userState = UserContainer.useContainer();
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));

	const close = ()=>{
		props.close();
	}

    if( !props.isOpen ){
        return (null);
	}

	return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				//none
				close();
			}}
			fullScreen={fullScreen}
        >
			<DialogHeader close={close}/>
			<DialogContent dividers>
			<div className="confirmRoot">
				<Title text="メールアドレス変更" size={2}/>
				<Box style={{marginBottom:'1em'}}>
					アカウントの有効性確認のため、現在のメールアドレスに再認証メールを送信しました。<br/>
					メールアドレスの変更手続きを進めるには、メールを受信して再認証（再ログイン）を行ってください。<br/>
				</Box>
			</div>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="default" onClick={close}>閉じる</Button>
			</DialogActions>
        </Dialog>

    );
}

export default EmailSend;
