import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogHeader from './DialogHeader';
import {Theme} from './Theme';
//import useStyles from './Styles';
import Title from './Title';

interface Props {
    isOpen:boolean,
}

const NoResponse:React.FC<Props> = (props)=> {
    //const classes = useStyles();
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	
    if( !props.isOpen ){
        return (null);
    }

    const close = ()=>{

    }

	return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				//none
				close();
			}}
			fullScreen={fullScreen}
        >
				<DialogHeader close={close}/>
				<DialogContent dividers>
				<div className="confirmRoot">
                    <Title text="サーバー応答なし" size={2}/>
                    <div style={{marginBottom:"4em"}}>
                        サーバーからの応答がありませんでした。<br/>
						回線の混雑もしくはサーバーの動作に異常が発生している可能性があります。<br/>
						しばらく待ってから再度お試しください。<br/>
						<br/>
                        また、<a href="https://twitter.com/r7ticket">公式ツイッター</a>の情報もご確認ください。<br/>
                    </div>
                </div>
				</DialogContent>
        </Dialog>

    );
}

export default NoResponse;
