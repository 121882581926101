import React from 'react';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';


const SecretForm = ()=> {
    return (
        <div id="secret" className="smallRoot">
            <div className="paper">
                <div style={{fontSize:"200%"}}>
                    <FontAwesomeIcon icon="user-lock"/> 非公開
                </div>
                <div style={{marginTop:"1em"}}>
                    この受付フォームは現在ご利用になれません
                </div>
                <div style={{marginTop:"2em",textAlign:"center"}}>
                    <Link to="/" className="link"><Button variant="outlined" color="primary">トップへ</Button></Link>
                </div>

            </div>
        </div>
    );
}

export default SecretForm;
