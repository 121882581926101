import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Tools} from './Tools';
import FormControl from '@material-ui/core/FormControl';
import DialogHeader from './DialogHeader';
import ColInput from './ColInput';
import Toast from './Toast';
import Yesno from './Yesno';
//import {UserContainer} from './UserContainer';
import {Inc,Inquiry} from './Inc';
//import Ajax from './Ajax';
import {Theme} from './Theme';

interface Props {
	close():void,
	send(inquiry:Inquiry):void,
	isOpen:boolean,
}

const InquiryInput = (props:Props)=> {
	//const userState = UserContainer.useContainer();
	const [inquiry,setInquiry] = useState(Inc.defaultInquiry());
	const [vali,setVali] = useState({
		errors:Inc.defaultInquiryInputErrors(),
		message:"",
	});
	const [isConfirm,setIsConfirm] = useState(false);
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	
	const change = (name:string,value:string)=>{
		setInquiry({...inquiry,[name]:value});
	}

	const validate = ()=>{
		let errors = Inc.defaultInquiryInputErrors();
		let flag = false;

		if( inquiry.Name==="" ){
			errors.Name = "入力して下さい";
			flag = true;
		} else if( inquiry.Name.length>50 ){
			errors.Name = "50文字以内にして下さい";
			flag = true;
		}
		if( inquiry.Company==="" ){
			//errors.Company = "入力して下さい";
			//flag = true;
		} else if( inquiry.Company.length>50 ){
			errors.Company = "50文字以内にして下さい";
			flag = true;
		}
		if( inquiry.Email==="" ){
			errors.Email = "入力して下さい";
			flag = true;
		} else if( Tools.validateMail(inquiry.Email)===false ){
			errors.Email = "不正なメールアドレスです";
			flag = true;
		}
		if( inquiry.Email.length>100 ){
			errors.Email = "100文字以内にして下さい";
			flag = true;
		}
		if( inquiry.Message==="" ){
			errors.Message = "入力して下さい"
			flag = true;
		}
		if( inquiry.Message.length>500 ){
			errors.Message = "500文字以内にして下さい";
			flag = true;
		}

		if( flag ){
			setVali({errors:errors,message:Inc.errorText+"部分を修正して下さい"});
		} else {
			setIsConfirm(true);
		}
	}

	const yes = ()=>{
		props.send(inquiry);
	}

	const no = ()=>{
		setIsConfirm(false);
	}

	const closeToast = ()=>{
		setVali({...vali,message:""});
	}

	useEffect(()=>{
		setInquiry(Inc.defaultInquiry());
		setIsConfirm(false);
		setVali({
			errors:Inc.defaultInquiryInputErrors(),
			message:"",
		});
	},[props.isOpen]);

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
				<DialogHeader close={props.close}/>
				<DialogContent dividers>
				<div className="confirmRoot">
					<h2>お問い合わせ</h2>
					<Grid container spacing={1} style={{marginBottom:"2em"}}>
						<Grid item xs={12} sm={3} className="formName">お名前</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Name" noName={true} dense={true} error={vali.errors.Name} placeholder="50文字以内" helper="団体の場合は担当者名" type="text" name="名前" value={inquiry.Name} change={change}/>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">団体名</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Company" noName={true} dense={true} error={vali.errors.Company} placeholder="50文字以内" helper="省略可" type="text" name="団体名" value={inquiry.Company} change={change}/>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">Ｅメール</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Email" noName={true} pattern="email" dense={true} error={vali.errors.Email} placeholder="100文字以内" type="text" name="Ｅメール" value={inquiry.Email} change={change}/>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">内容</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Message" noName={true} dense={true} rows={10} error={vali.errors.Message} type="textarea" name="内容" value={inquiry.Message} change={change}/>
						</Grid>
					</Grid>
				</div>
				</DialogContent>
				<DialogActions>
					<FormControl fullWidth={true}>
						<Button variant="contained" color="secondary" onClick={validate} style={{width:"100%"}}>この内容で送信する</Button>
					</FormControl>
				</DialogActions>
				<Toast close={closeToast} mes={vali.message}/>
				<Yesno yes={yes} no={no} isOpen={isConfirm} mes="この内容で送信してよろしいですか？"/>
		</Dialog>
	);
}

export default InquiryInput;
