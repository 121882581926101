import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, TwitterAuthProvider, EmailAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const config = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "ticketa19.firebaseapp.com",
    databaseURL: "https://ticketa19.firebaseio.com",
    projectId: "ticketa19",
    storageBucket: "ticketa19.appspot.com",
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const app = initializeApp(config);

const auth = getAuth(app);
const googleAuth = new GoogleAuthProvider();
const twitterAuth = new TwitterAuthProvider();
const emailAuth = EmailAuthProvider;
const db = getFirestore(app);



// exportしてどこからでも使えるようにする
export {auth, googleAuth, twitterAuth, emailAuth, db};