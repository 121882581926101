import React from 'react';
import {Link} from 'react-router-dom'//yarn add @types/react-router-dom
//import Hidden from '@material-ui/core/Hidden';
//import logo from "./imgs/logo.png";
import namelogo from "./img/logo.png";
//import back from "./imgs/back.png";
import HomeIcon from '@material-ui/icons/Home';

//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
	isTop:boolean,
}

const Logo = (props:Props)=> {
	return (
		<Link to="/" style={{position:"relative",textDecoration:"none",color:"#000000",marginTop:"3px"}}>
			{props.isTop ? (
			<React.Fragment>
				<img src={namelogo} alt="title" style={{width:"150px",height:"32px"}}/>
			</React.Fragment>
			):(
			<HomeIcon className="home"/>
			)}
		</Link>
	)
}

export default Logo;