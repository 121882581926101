import React,{useState, useEffect} from 'react';
import {Link, Redirect, useParams} from 'react-router-dom';
//import useReactRouter from 'use-react-router';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
//import FormControl from '@material-ui/core/FormControl';
import Popper from '@material-ui/core/Popper';
//import FormLabel from '@material-ui/core/FormLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
//import MultiLines from './MultiLines';
import store from "./store";
import ColInput from './ColInput';
import Loading from './Loading';
import Ajax from './Ajax';
import Toast from './Toast';
import StageHeader from './StageHeader';
import ImageSelect from './ImageSelect';
//import StageTicketInput from './StageTicketInput';
import {Tools} from './Tools';
import {UserContainer} from './UserContainer';
import {Inc,Dataset,Stage,ImageData} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
	new:boolean,
}

const StageInput = (props:Props)=> {
	const userState = UserContainer.useContainer();
	const [states,setStates] = useState({
		stage:Inc.defaultStage(),
		redirectId:"",
		editIndex: -1,
		isLoading:false,
	})
	const [image,setImage] = useState({} as ImageData);
	const [vali,setVali] = useState({
		errors:Inc.defaultStageInputErrors(),
		message:"",
	});
	const [anchor, setAnchor] = useState({
		element:null as null | HTMLElement,
		code: "",
	});
	const {gid} = useParams<{gid:string}>();


	const changeStage = (name:keyof Stage,value:string)=>{
		if( name==="MaxQuantity" ){
			const quantity = parseInt(value);
			setStates({...states,stage:{...states.stage,"MaxQuantity":quantity}})
		} else {
			setStates({...states,stage:{...states.stage,[name]:value}})
		}
	}

	const loadStage = async (id:string)=>{
		setStates({...states,isLoading:true});
		try {
			const res = await Ajax.getStage(id,false);
			if( res.ok ){
				userState.setStage(res.stage);
				userState.setVer(res.ver);
				setStates({...states,stage:res.stage,isLoading:false});
				setImage({key:res.stage.ImageKey} as ImageData)
			}
		} catch(error){
			throw error;
		}
	}

	const saveStage = ()=>{
		if( !userState.user ){
			return false;
		}
		//console.log({...states.stage,UserId:userState.user.uid});
		
		Ajax.setToken(userState.token);
		setStates({...states,isLoading:true});
		if( states.stage.Id==="" ){
			Ajax.addStage({...states.stage,UserId:userState.user.uid},image.source).then((ref)=>{
				//console.log("ref",ref);
				setStates({...states,stage:Inc.defaultStage(),isLoading:false,redirectId:ref.id});
			}).catch((error)=>{
				console.log(error);
			})
		} else {
			//let stage:Stage = {...states.stage};
			//console.log("updateStage",states.stage);
			Ajax.updateStage(states.stage,image.source).then((ref)=>{				
				//console.log("ref",ref);
				userState.setStage(states.stage);
				setStates({...states,stage:Inc.defaultStage(),isLoading:false,redirectId:states.stage.Id});
			}).catch((error)=>{
				console.log("error",error);
			});
		}
	}
	const closeToast = ()=>{
		setVali({...vali,message:""});
	}
	const openAnswer = (event: React.MouseEvent<HTMLElement>)=>{
		if( !event.currentTarget.dataset.code ){
			return;
		}
		setAnchor({element:anchor.element ? null : event.currentTarget,code:event.currentTarget.dataset.code});		
	}
	const putQ = (code:string)=>{
		return (
			<IconButton size="small" onClick={openAnswer} data-code={code}><FontAwesomeIcon icon="question-circle" className="question"/></IconButton>
		);
	}
	const cancelImage = ()=>{
		setImage({} as ImageData);
		setStates({...states,stage:{...states.stage,ImageKey:''}});
	}
	const updateImage = (newImage:ImageData)=>{
		setImage(newImage);
	}


	const validate = async ()=>{
		let errors = Inc.defaultStageInputErrors();
		let flag = false;
		if( states.stage.Title==="" ){
			errors.Title = "省略できません";
			flag = true;
		}
		if( states.stage.Organizer==="" ){
			errors.Organizer = "省略できません";
			flag = true;
		}
		if( states.stage.Place==="" ){
			errors.Place = "省略できません";
			flag = true;
		}
		//if( states.stage.StartDate==="" ){
		//	errors.startDate = "省略できません";
		//	flag = true;
		//}
		//if( states.stage.EndDate==="" ){
		//	errors.endDate = "省略できません";
		//	flag = true;
		//}
		if( states.stage.Desk==="" ){
			errors.Desk = "省略できません";
			flag = true;
		}
		if( states.stage.Url==="" ){
			errors.Url = "省略できません";
			flag = true;
		} else if( Tools.validateUrl(states.stage.Url)===false ){
			if(states.stage.Url.substr(0,8)==="https://"){
				errors.Url = "不正なＵＲＬです";
			} else {
				errors.Url = "https://から入力してください";
			}
			flag = true;
		}
		if( states.stage.Email==="" ){
			//errors.Email = "省略できません";
			//flag = true;
		} else if( Tools.validateMail(states.stage.Email)===false ){
			errors.Email = "不正なメールアドレスです";
			flag = true;
		}
		if( states.stage.Tel==="" ){
			//errors.Tel = "省略できません";
			//flag = true;
		} else if( Tools.validateTel(states.stage.Tel)===false ){
			errors.Tel = "不正な電話番号です";
			flag = true;
		}
		if( states.stage.Email==="" && states.stage.Tel==="" ){
			errors.Email = "メールか電話番号のどちらか一つは必須です"
			errors.Tel = "メールか電話番号のどちらか一つは必須です"
			flag = true;
		}

		if( states.stage.Prefix && states.stage.Prefix.length>6 ){
			errors.Prefix = "6文字以内にしてください"
			flag = true;
		}
		if( states.stage.BccMail==="" ){
			//errors.BccMail = "省略できません";
			//flag = true;
		} else if( Tools.validateMail(states.stage.BccMail)===false ){
			errors.BccMail = "不正なメールアドレスです";
			flag = true;
		}

		if( states.stage.Description==="" ){
			errors.Description = "省略できません";
			flag = true;
		}
		if( states.stage.Message==="" ){
			errors.Message = "省略できません";
			flag = true;
		}

		//if( states.stage.StartDate!=="" && states.stage.EndDate!=="" ){
		//	const st = Date.parse(states.stage.StartDate+" "+states.stage.StartHour+":"+states.stage.StartMinute);
		//	const en = Date.parse(states.stage.EndDate+" "+states.stage.EndHour+":"+states.stage.EndMinute);
		//	if( st>=en ){
		//		errors.endDate = "受付開始日より後の日時にして下さい";
		//		flag = true;
		//	}
		//}

		if( states.stage.Code!=="" ){
			//重複チェック
			await store.searchStage(states.stage.Code,states.stage.Id).then((stage)=>{
				errors.Code = "このコードはほかのイベントで使われています";
				flag = true;
			}).catch((error)=>{
				if(error.message==="notFound"){

				} else {
					console.log("searchStage",error);
				}
			})
		}

		if( !flag ){
			setVali({...vali,errors:errors});
			saveStage();
		} else {
			setVali({errors:errors,message:Inc.errorText+"部分を修正して下さい"});
		}
		window.scroll(0,0);
	}

	useEffect( ()=>{
		const id:string = String(gid).trim();
		if( !props.new && id!=="" ){
			//念のため毎回最新の内容をロードしてから編集
			loadStage(id);				
		} else {
			setStates({...states,stage:Inc.defaultStage(),isLoading:false});
			userState.setStage(Inc.defaultStage());
			userState.setVer("");
		}
	},[gid]);

	if( !userState.ready ){
		return (null);
	}

	if( states.redirectId!=="" ){
		const to = "/stage/detail/"+states.redirectId;
		return (
			<Redirect to={to}/>
		)
	}

	let quantitys:Dataset[] = [];
	for( let q=1 ; q<=Inc.maxQuantity ; q++ ){
		let id = String(q);
		quantitys.push({
			id: id,
			label: id + "枚まで",
		});
	}

	let toBack = "/";
	if( states.stage.Id!=="" ){
		toBack = "/stage/detail/"+states.stage.Id;
	}

	let title = states.stage.Title;
	let organizer = states.stage.Organizer;
	if( title==="" ){
		title = "タイトル未定";
	}
	if( organizer==="" ){
		organizer = "新規登録";
	}

	const popperOpen = Boolean(anchor.element);
	const popperId = popperOpen ? 'simple-popper' : undefined;

	return (
		<React.Fragment>
			<StageHeader stage={userState.stage} current="detail"/>
			<div id="event_input" className="mediumRoot">
				<div className="paper">
					<div className="pageTitle">
						<div className="btns">
							<Link to={toBack} className="decn"><Button variant="text" color="default" style={{marginRight:"0.25em",textDecoration:"none"}}>もどる</Button></Link>
							<Button variant="contained" color="primary" onClick={validate} style={{width:"8em"}}>登録する</Button>
						</div>
					</div>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} style={{paddingBottom:"2em",paddingRight:"2em"}}>
							<Grid container spacing={2} className="stageInput">
								<Grid item xs={12} sm={3} className="formName">イベント名称</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="イベント名称" id="Title" noName={true} dense={true} type="text" placeholder="イベントの名称・タイトル" error={vali.errors.Title} value={states.stage.Title} change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={3} className="formName">アーティスト</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="出演・主催" id="Organizer" noName={true} dense={true} placeholder="劇団・バンド・アーティストの名称" type="text" error={vali.errors.Organizer} value={states.stage.Organizer} change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={3} className="formName">会場</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="会場" id="Place" noName={true} dense={true} placeholder="劇場・ライブハウス・ホールの名称" type="text" error={vali.errors.Place} value={states.stage.Place} change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={3} className="formName">コード{putQ("Code")}</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="コード" id="Code" noName={true} dense={true} type="text" error={vali.errors.Code} value={states.stage.Code} placeholder="半角英数字6～20文字" maxLength={20} minLength={6} helper="省略するとコードが自動作成されます" hankaku={true} change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={3} className="formName">通知メール</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="通知メール" id="BccMail" noName={true} dense={true} type="text" pattern="email" value={states.stage.BccMail} error={vali.errors.BccMail} placeholder="通知メール" helper="省略可　予約が入ったらこのメールにお知らせします" change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={3} className="formName">整理番号{putQ("UseNumber")}</Grid>
								<Grid item xs={7} sm={5}>
									<ColInput name="整理番号" id="UseNumber" useBool={true} noName={true} dense={true} type="radio" list={Inc.refnums} value={states.stage.UseNumber} change={changeStage}/>
								</Grid>
								<Grid item xs={5} sm={4}>
								{states.stage.UseNumber &&
									<ColInput name="プレフィックス" id="Prefix" noName={true} dense={true} error={vali.errors.Prefix} type="text" placeholder="プレフィックス" maxLength={6} value={states.stage.Prefix} change={changeStage} helper="省略可　6文字以内"/>
								}
								</Grid>
								<Grid item xs={12} sm={3} className="formName noWrap">同時予約枚数{putQ("MaxQuantity")}</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="同時予約枚数" id="MaxQuantity" noName={true} dense={true} type="select" required={true} list={quantitys} value={String(states.stage.MaxQuantity)} change={changeStage}/>
								</Grid>

								<Grid item xs={12} sm={3} className="formName">公式サイト{putQ("Url")}</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="公式サイト" id="Url" noName={true} dense={true} type="text" pattern="url" error={vali.errors.Url} placeholder="https://" helper="イベントの詳細がわかるサイトやＳＮＳのＵＲＬ" value={states.stage.Url} change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={3} className="formName">問合せ窓口{putQ("Desk")}</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="問い合わせ窓口" id="Desk" noName={true} dense={true} type="text" value={states.stage.Desk} error={vali.errors.Desk} placeholder="問い合わせ窓口の名称" helper="問い合わせを受け付ける担当者や部署の名称" change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={3} className="formName">窓口メール</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="Ｅメール" id="Email" noName={true} dense={true} type="text" pattern="email" value={states.stage.Email} error={vali.errors.Email} placeholder="窓口のＥメール（省略可）" change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={3} className="formName">窓口電話</Grid>
								<Grid item xs={12} sm={9}>
									<ColInput name="電話番号" id="Tel" noName={true} dense={true} type="text" pattern="tel" value={states.stage.Tel} error={vali.errors.Tel} placeholder="窓口の電話番号（省略可）" change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={3} className="formName noWrap">イメージ画像{putQ("Image")}</Grid>
								<Grid item xs={12} sm={9}>
									<ImageSelect title="イメージ画像" image={image} cancel={cancelImage} update={updateImage} maxSize={5} maxWidth={1200} helper="省略可　ツイッターなどで表示される画像"/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={6}>
						<Grid container spacing={2} className="stageInput">
								<Grid item xs={12} sm={12} className="formName">イベント内容</Grid>
								<Grid item xs={12} sm={12} style={{paddingTop:"0",marginBottom:"2em"}}>
									<ColInput name="イベント内容" id="Description" noName={true} dense={true} rows={8} colored={true} type="textarea" value={states.stage.Description} error={vali.errors.Description} placeholder="イベント内容" helper="イベントの内容を簡潔に記入して下さい" change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={12} className="formName">当日の案内</Grid>
								<Grid item xs={12} sm={12} style={{paddingTop:"0",marginBottom:"2em"}}>
									<ColInput name="当日の案内" id="Message" noName={true} dense={true} rows={4} colored={true} type="textarea" value={states.stage.Message} error={vali.errors.Message} placeholder="" helper="開場時間や当日の受付の方法など、予約通知メール（予約をしたお客様に自動的に送信されるメール）に記載したい情報を入力してください" change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={12} className="formName">緊急のお知らせ</Grid>
								<Grid item xs={12} sm={12} style={{paddingTop:"0",marginBottom:"2em"}}>
									<ColInput name="緊急のお知らせ" id="Notice" noName={true} dense={true} rows={4} colored={true} type="textarea" value={states.stage.Notice} error={vali.errors.Notice} placeholder="緊急のお知らせ" helper="省略可　日時や出演者の変更など特に注意を要することがあったら記入して下さい　各フォームと受付内容の確認画面に赤字で表示されます" change={changeStage}/>
								</Grid>
								<Grid item xs={12} sm={12} className="formName">仮予約の注意事項</Grid>
								<Grid item xs={12} sm={12} style={{paddingTop:"0",marginBottom:"2em"}}>
									<ColInput name="仮予約の注意事項" id="Alert" noName={true} dense={true} rows={4} colored={true} type="textarea" value={states.stage.Alert} error={vali.errors.Alert} placeholder="仮予約の注意事項" helper="省略可　仮予約メールを送信した後（まだ予約完了ではありません！の画面）に表示されます" change={changeStage}/>
								</Grid>
							</Grid>

						</Grid>
					</Grid>
				</div>
				<Popper id={popperId} open={popperOpen} anchorEl={anchor.element}>
					{anchor.code==="Code" &&
        			<div className="popperAnswer">コードは受付フォームのURLに使われます。ほかのイベントですでに使われているコードは使えません。</div>
					}
					{anchor.code==="UseNumber" &&
        			<div className="popperAnswer">予約に整理番号をつけることができます。整理番号の先頭に記号をつけたい場合は、その記号をプレフィックスに入力してください。</div>
					}
					{anchor.code==="MaxQuantity" &&
					<div className="popperAnswer">１回の申し込みで予約できる枚数の上限です。特に必要のない限りは少なめに設定しておいた方が良いかも知れません。</div>
					}
					{anchor.code==="Url" &&
					<div className="popperAnswer">公演の詳細がわかるウェブページのURLを入力してください。団体や個人のＳＮＳでも構いません。</div>
					}
					{anchor.code==="Desk" &&
					<div className="popperAnswer">公演についての質問を受けつける問い合わせ窓口の名前（団体名や担当者名など）を入力してください。</div>
					}
					{anchor.code==="Description" &&
					<div className="popperAnswer">公演の内容について説明してください。</div>
					}
					{anchor.code==="Message" &&
					<div className="popperAnswer">開場時間や当日の受付の方法など、予約通知メール（予約をしたお客様に自動的に送信されるメール）に記載したい情報を入力してください。</div>
					}
					{anchor.code==="Image" &&
					<div className="popperAnswer">ツイッターで受付フォームのURLをツイートした時にこの画像が一緒に表示されます。<br/>幅1200×高さ630pixel程度が目安です。</div>
					}
				
      			</Popper>
				<Toast mes={vali.message} close={closeToast}/>
				<Loading isLoading={states.isLoading}/>
			</div>
		</React.Fragment>
	);
}

export default StageInput;
