import React,{useState,useEffect} from 'react';
import {Link,Redirect,useParams} from 'react-router-dom';
import Card from '@material-ui/core/Card';
//import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SampleView from './SampleView';
import Toast from './Toast';
import {HelpCard,HelpCardBtn,SiteName} from './Inc';
import HelpDict from './HelpDict';
import Ajax from './Ajax';
import {Tools} from './Tools';

interface Props {
    
}

const HelpDetail = (props:Props)=> {
	const [toast,setToast] = useState("");
	const [sampleCode,setSampleCode] = useState("");
    const closeToast = ()=>{
        setToast("");
	}
	const {code} = useParams<{code:string}>();
	
	const openSample = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.code ){
			return;
        }
		setSampleCode(e.currentTarget.dataset.code);
	}

	const download = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.code ){
			return;
		}
		if(e.currentTarget.dataset.code==="csvdownload"){
			Ajax.downloadSampleDownloadCsv();
		}
		if(e.currentTarget.dataset.code==="csvupload"){
			Ajax.downloadSampleUploadCsv();
		}
	}

	const closeSampleView = ()=>{
		setSampleCode("");
	}
  
	const multilines = (texts:string[])=>{
		return texts.map((text:string,index:number)=>{
			const key = "line"+index;
			return <p key={key}>{text}</p>;
		})
	}

	const titles: { [key: string]: string; } = {
		"outline": "大まかな流れ",
		"make":"イベントをつくる",
		"ticket":"チケットを登録する",
		"open":"予約受付を始める",
		"booking":"予約をもらう",
		"mail":"緊急メール",
		"fee":"仕様と利用料金",
		"about":"R7 TICKET SERVICEとは？",
	}
	let title = "ヘルプ";
	if( code && titles[code] ){
		title = titles[code];
	}
	let isArrow = false;
	if( code && code==="outline" ){
		isArrow = true;
	}
	const cards = HelpDict.get(code);

	useEffect(()=>{
		document.title = title + "｜"+SiteName;
		window.scrollTo(0, 0);
	},[]);

    if( !code ){
		return (
			<Redirect to="/help"/>
		)
	}

    return (
        <div id="login" className="smallRoot noStageRoot">
			<div className="help">
				<h2>{title}</h2>
				{cards.map((card:HelpCard,index1:number)=>{
					const key="card"+index1;
					let img = "";
					if( card.Img!=="" ){
						img = Tools.getImgPath("help/"+card.Img);
					}

					return (
			<React.Fragment key={key}>
				<Card className="cardRoot">
					{img!=="" &&
					<div className="img">
						<img src={img} alt={card.Title}/>
					</div>
					}
					<CardContent>
					<Typography gutterBottom variant="h5" color="primary" component="h3">
						{card.Title}
					</Typography>
					{card.Text &&
					<Typography variant="body2" color="textPrimary" component="p">
						{card.Text}
					</Typography>
					}
					{card.Texts &&
					<Typography variant="body2" color="textPrimary" component="p">
						{multilines(card.Texts)}
					</Typography>
					}
					{card.Notice!=="" &&
					<Typography variant="body2" component="p" style={{marginTop:"1em",color:"#666666"}}>
						{card.Notice}
					</Typography>
					}
					</CardContent>

					<CardActions>
						{card.Btns.map((btn:HelpCardBtn,index2:number)=>{
							const key="btn"+index2;
							if(btn.Link!==""){
								return (
							<Link to={btn.Link} key={key}><Button size="small" color={btn.Color}>
							{btn.Name}
							</Button></Link>
								);
							} else if(btn.Sample!==""){
								return (
							<Button size="small" color={btn.Color} key={key} onClick={openSample} data-code={btn.Sample}>
							{btn.Name}
							</Button>
								);
							} else if (btn.Download!==""){
								return (
							<Button size="small" color={btn.Color} key={key} onClick={download} data-code={btn.Download}>
							{btn.Name}
							</Button>
								);
							} else {
								return (null);
							}
						})}
					</CardActions>
				</Card>
				{index1<cards.length-1 && isArrow &&
				<div style={{textAlign:"center",paddingBottom:"1em"}}><ArrowDownwardIcon/></div>
				}
			</React.Fragment>
					);
				})}
			</div>
			<div style={{textAlign:"center",marginBottom:"3em"}}>
				<Link to="/help" className="link"><Button variant="outlined" color="default">もどる</Button></Link>
			</div>
            <Toast mes={toast} close={closeToast}/>
			<SampleView code={sampleCode} close={closeSampleView}/>
        </div>
    );
}

export default HelpDetail;
