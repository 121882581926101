import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogHeader from './DialogHeader';
import {Theme} from './Theme';
//import useStyles from './Styles';
import Title from './Title';

interface Props {
    isOpen:boolean,
}

const NoService:React.FC<Props> = (props)=> {
    //const classes = useStyles();
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));

    if( !props.isOpen ){
        return (null);
    }

    const close = ()=>{

    }

	return (
        <Dialog
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				//none
				close();
			}}
			fullScreen={fullScreen}
        >
				<DialogHeader close={close}/>
				<DialogContent dividers>
				<div className="confirmRoot">
                    <Title text="休止中" size={2}/>
                    <div style={{marginBottom:"4em"}}>
                        メンテナンスのためサービスを一時休止しています。<br/>
                        最新情報は<a href="https://twitter.com/r7ticket">公式ツイッター</a>をご確認ください。<br/>
                    </div>
                </div>
				</DialogContent>
        </Dialog>

    );
}

export default NoService;
