import React from 'react';
import {Link} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
//import Typography from '@material-ui/core/Typography';

interface Props {
    
}

const HelpTerm = (props:Props)=> {
    return (
        <div id="login" className="smallRoot noStageRoot">
			<div className="help">
				<h2>R7 TICKET SERVICE利用規約</h2>
				<Paper className="paper">

				<p>有限会社レトロインク（以下、当社）は、当社が運営するサービス「R7 TICKET SERVICE」（以下、本サービス）の利用と提供に関し、以下の通り、R7 TICKET SERVICE利用規約（以下、本規約）を定めます。</p>
	<dl className="documentHelp">
		<dt>1．定義</dt>
		<dd>
			<p>本規約において使用する用語の意義は、本規約において別段の定めがある場合を除き、次の各号に定める通りとします。</p>
			<ul>
				<li>本サービスとは、R7 TICKET SERVICEの全サービスをいいます。</li>
				<li>会員とは、本規約に基づきイベントを登録し、利用の開始を申請することで、当社から本サービスの全部又は一部の利用を認められた方をいいます。</li>
				<li>利用の開始とは、利用者情報を登録した上で、利用開始手続きを行うことをいいます。</li>
				<li>イベントとは、チケットの予約を受け付けるために会員が登録した興行情報をいいます。</li>
				<li>予約者とは、本サービスを利用してチケットの予約を行った方のうち、該当チケットの開演日時がまだ過ぎていない方をいいます。</li>
				<li>利用者とは、会員および予約者をいいます。</li>
			</ul>
		</dd>
		<dt>2. 本規約の適用</dt>
		<dd>
			<p>本規約は、本サービスの利用および運用に関して、当社と利用者の関係の全てに適用されます。</p>
			<p>会員は利用の開始によって本規約の内容を全て承諾したものとみなします。</p>
			<p>予約者は予約の申し込みによって本規約の内容を全て承諾したものとみなします。</p>
		</dd>
		<dt>3. 本規約の変更</dt>
		<dd>
			<p>当社は、本規約の全部又は一部を変更、中断、又は終了（以下、変更）する際には、利用者へ事前の通知を行うものとします。ただし、緊急を要すると当社が判断した場合には、事後の通知でそれに代えることができるものとします。</p>
			<p>また、変更後に本サービスのご利用があった時点で、変更後の本規約に同意したものとみなします。</p>
		</dd>
		<dt>4. 通知</dt>
		<dd>
			<p>当社が利用者に対して、通知、連絡等を行う必要があると判断した場合、電子メール、本サービスのWebサイトへの掲載など、当社が適当と判断する方法により行うものとします。</p>
			<p>前項における電子メールによる通知、連絡等は、利用者が本サービスに登録している電子メールアドレスに対して行うものとします。</p>
			<p>当社が通知、連絡等を本サービスのWebサイト上で行う場合は、当該通知、連絡等をWebサイトに掲載した日をもって、利用者に当該通知、連絡等が到達したものとみなします。また、電子メールによって通知、連絡等を行う場合には、当社が会員に対して電子メールを発信した時点をもって、会員に当該通知、連絡等が到達したものとみなします。</p>
		</dd>
		<dt>5. 本サービスの内容</dt>
		<dd>
			<p>本サービスは、当日精算チケットの予約受付フォームを利用者に提供するものです。</p>
			<p>当社は、利用者に事前に通知することなく本サービスの内容の全部又は一部の変更を行うことができるものとし、当社は変更により利用者に生じた損害、結果について一切責任を負いません。</p>
		</dd>
		<dt>6. 利用の開始</dt>
		<dd>
			<p>利用者情報の登録および利用の開始は、会員が自己の判断と責任により登録するものとし、その内容によって会員に不利益が生じても当社は一切責任を負わないものとします。</p>
			<p>会員は、本サービスを利用するにあたり当社に登録した情報に誤り又は変更が生じた場合は、ただちに本サービス上の会員ページにおいて、情報の訂正、修正又は変更を行うものとします。</p>
			<p>また、会員登録において、会員が他社の提供するＳＮＳアカウントの利用を選択した場合、当社は該当ＳＮＳアカウントの情報を会員登録および会員登録の確認以外の目的では使用しないものとします。</p>
		</dd>
		<dt>7. 退会・解約</dt>
		<dd>
			<p>会員は、当社が本サービス上で定める方法で、いつでも本サービスの利用者情報を削除することができるものとします。</p>
			<p>利用者情報の削除は、退会・解約を意味するものとします。</p>
		</dd>
		<dt>8. チケットの予約</dt>
		<dd>
			<p>予約者は、当社の定める手続きに基づき、チケットを予約することができます。</p>
			<p>また、予約手続きを完了せずに終了した場合および予約手続きの途中でチケットが会員の定める販売枚数に達した場合、さらに当社が妥当と判断する理由で予約手続きが認められない場合は、その予約手続きを無効とします。</p>
		</dd>
		<dt>9. 予約のキャンセル</dt>
		<dd>
			<p>予約者は、前条に基づくチケットの予約手続きの後、該当チケットの開演日時の10分前までは、いつでも予約手続きの取り消し（以下、キャンセル）ができるものとします。</p>
			<p>該当チケットの開演日時の10分前を過ぎた場合でも、予約者から会員にキャンセルの申し出があり、会員がそれを認めた場合には、キャンセルができるものとします。</p>
			<p>予約者からのキャンセルの申し出がない場合でも、会員がキャンセルの必要を認めた場合には、会員は予約者の了解なくキャンセルができるものとします。</p>
		</dd>
		<dt>10. 予約したチケット代金の支払い</dt>
		<dd>
			<p>予約したチケット代金の支払い方法は当日精算とし、予約者が会員に直接支払うものとします。</p>
			<p>会員と予約者の間で合意がある場合には、当日精算以外の支払ができるものとします。その場合でも、本サービスが支払い手続きの代行を行うことはありません。</p>
		</dd>
		<dt>11. 予約したチケットの受け渡し</dt>
		<dd>
			<p>原則として予約したチケットは、該当イベントの当日受付にて代金と引き換えに受け渡されるものとします。また、この場合にチケットの現物は必ずしも必要としません。</p>
			<p>会員と予約者の間で合意がある場合には、前号以外の受け渡し方法が認められるものとします。</p>

		</dd>
		<dt>12. 免責事項</dt>
		<dd>
			<p>利用者は、次の各号をあらかじめ承諾したものとみなします。</p>
			<ul>
				<li>本サービスの利用において会員と予約者の間に生じたトラブルは、両者間の話し合いで解決するものとし、当社および本サービスは一切責任を負わないものとします。</li>
				<li>本サービスに利用において利用者に不利益が生じても当社は一切責任を負わないものとします。</li>
				<li>イベントの中止等により予約が無効となった場合、予約者は会員および本サービスに対して一切の賠償を求めないものとします。</li>
			</ul>
		</dd>

		<dt>13. 個人情報</dt>
		<dd>
			<p>当社が本サービスで取得した会員の個人情報は、当社が別途定める<Link to="/help/privacy" className="link">プライバシーポリシー</Link>に従って取り扱うものとします。</p>
		</dd>
		<dt>14. 本サービスの停止</dt>
		<dd>
			<p>当社は、以下のいずれかに該当する場合には、利用者に事前に通知することなく一時的に本サービスを中断することができるものとします。</p>
			<ul>
				<li>サーバ、通信回線若しくはその他の設備の故障、障害の発生又はその他の事由により本サービスの提供ができなくなった場合</li>
				<li>システム(サーバ、通信回線や電源、それらを収容する建築物などを含む)の保守、点検、修理、変更を定期的に又は緊急に行う場合</li>
				<li>火災、停電などにより本サービスの提供ができなくなった場合</li>
				<li>地震、噴火、洪水、津波などの天災により本サービスの提供ができなくなった場合</li>
				<li>戦争、変乱、暴動、騒乱、労働争議、疫病の蔓延等その他不可抗力により本サービスの提供ができなくなった場合</li>
				<li>法令による規制、司法命令等が適用された場合</li>
				<li>その他、運用上、技術上当社が本サービスの一時的な中断を必要と判断した場合</li>
			</ul>
		</dd>
		<dt>15. 利用環境の整備</dt>
		<dd>
			<p>利用者は、本サービスを利用するために必要な通信機器、ソフトウェア、通信回線などのすべてを自己の責任と費用をもって準備し、本サービスを利用するために必要となる全ての環境の整備および維持管理を行うものとします。</p>
			<p>当社は、利用者の使用する本サービスの利用に供する装置、ソフトウェア又は通信網の瑕疵、障害、動作不良、もしくは不具合により、利用者に損害が生じたときであっても一切の責任を負いません。</p>
		</dd>
		<dt>16. 認証の管理責任</dt>
		<dd>
			<p>会員が本サービス上を利用する上での認証に必要な情報は会員が責任をもって管理するものとします。会員によるパスワード等の紛失、失念、漏洩、開示又はこれらを起因とする第三者のパスワード等の不正使用等から生じた損害については、当社は一切その責任を負わないものとします。</p>
		</dd>
		<dt>17. 会員の禁止事項</dt>
		<dd>
			<p>会員は、本サービスの利用に関して、以下の行為を行ってはならないものとします。</p>
			<ul>
				<li>本サービスを利用する権利の第三者への譲渡および転売行為</li>
				<li>当社もしくは第三者の著作権、商標権などの知的財産権その他の権利を侵害する行為、又は侵害するおそれのある行為</li>
				<li>法令もしくは公序良俗に違反し、又は当社もしくは第三者に不利益を与える行為</li>
				<li>他者を差別もしくは誹謗中傷し、又はその名誉もしくは信用を毀損する行為</li>
				<li>詐欺等の犯罪に結びつく又は結びつくおそれがある行為</li>
				<li>第三者になりすまして本サービスを利用する行為</li>
				<li>ウィルス等の有害なコンピュータプログラム等を送信又は掲載する行為</li>
				<li>その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様・目的でリンクをはる行為</li>
				<li>その他、当社が不適当と判断する行為</li>
			</ul>
			<br/>
			<p>当社は、本サービスの利用に関して、会員の行為が前項各号のいずれかに該当するものであると判断した場合、事前に会員に通知することなく、本サービスの全部又は一部の提供を一時停止することができるものとします。</p>
		</dd>
		<dt>18. 予約者の禁止事項</dt>
		<dd>
			<p>予約者は、本サービスの利用に関して、以下の行為を行ってはならないものとします。</p>
			<ul>
				<li>本サービスで予約したチケットの権利の第三者への譲渡および転売行為</li>
				<li>会員または本サービスの運営・活動を妨害する行為</li>
				<li>法令もしくは公序良俗に違反し、又は当社もしくは第三者に不利益を与える行為</li>
				<li>他者を差別もしくは誹謗中傷し、又はその名誉もしくは信用を毀損する行為</li>
				<li>詐欺等の犯罪に結びつく又は結びつくおそれがある行為</li>
				<li>第三者になりすまして本サービスを利用する行為</li>
				<li>ウィルス等の有害なコンピュータプログラム等を送信又は掲載する行為</li>
				<li>その行為が前各号のいずれかに該当することを知りつつ、その行為を助長する態様・目的でリンクをはる行為</li>
				<li>その他、当社が不適当と判断する行為</li>
			</ul>
			<br/>
			<p>当社は、本サービスの利用に関して、予約者の行為が前項各号のいずれかに該当するものであると判断した場合、事前に予約者に通知することなく、本サービスの全部又は一部の提供を一時停止することができるものとします。</p>
		</dd>
		<dt>19. その他</dt>
		<dd>
			<p>本規約は、日本法に基づき解釈されるものとし、本規約に関して訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
			<p>本規約の解釈を巡って疑義が生じた場合、当社は合理的な範囲でその解釈を決定できるものとします。</p>
		</dd>
	</dl>

	<p>附則</p>
	<p>
		本規約は2020年4月1日から施行します。<br/>
		2020年4月1日 施行
	</p>



				</Paper>
			</div>
        </div>
    );
}

export default HelpTerm;
