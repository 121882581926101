import React,{useState,useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogHeader from './DialogHeader';
import ColInput from './ColInput';
import Toast from './Toast';
import MultiLines from './MultiLines';
import Loading from './Loading';
import {Inc,Stage,Mail} from './Inc';
import Ajax from './Ajax';
import {Theme} from './Theme';

interface MailerProps {
	close():void,
	finish():void,
	send:number,
	isOpen:boolean,
	stage:Stage,
}

const Mailer = (props:MailerProps)=> {
	const [mail,setMail] = useState(Inc.defaultMail());
	const [step,setStep] = useState("input");
	const [checked,setChecked] = useState(false);
	const [vali,setVali] = useState({
		errors:Inc.defaultMailInputErrors(),
		message:"",
	});
	const [toast,setToast] = useState("");
	const fullScreen = useMediaQuery(Theme.breakpoints.down('sm'));
	
	const change = (name:keyof Mail,value:string)=>{
		value = value.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'');
		setMail({...mail,[name]:value});
	}

	const validate = ()=>{
		let errors = Inc.defaultMailInputErrors();
		let flag = false;

		if( mail.Subject==="" ){
			errors.Subject = "タイトルを入力して下さい";
			flag = true;
		} else if( mail.Subject.length>100 ){
			errors.Subject = "タイトルは100文字以内にして下さい";
			flag = true;
		}
		if( mail.Body==="" ){
			errors.Body = "本文を入力して下さい";
			flag = true;
		} else if( mail.Body.length>1000 ){
			errors.Body = "本文は1000文字以内にして下さい";
			flag = true;
		}
		if( flag ){
			setVali({errors:errors,message:"赤字部分を修正して下さい"});
		} else {
			setStep("confirm");
		}

	}

	const closeToast = ()=>{
		setToast("");
	}

	const retry = ()=>{
		setChecked(false);
		setStep("input");
	}

	const send = async ()=>{
		setStep("waiting");
		const res = await Ajax.sendMail(props.stage.Id,mail);
		if( res.ok ){
			setStep("finished");
		} else {
			setStep("confirm");
			setToast("送信できませんでした");
		}
	}

	const check = ()=>{
		setChecked(!checked);
	}

	const close = ()=>{
		if( step==="finished" ){
			props.finish();
		} else {
			props.close();
		}
	}

	useEffect(()=>{
		if( props.isOpen ){
			setMail(Inc.defaultMail());
			setVali({
				errors:Inc.defaultMailInputErrors(),
				message:"",
			});
			setChecked(false);
			setStep("input");
		}
	},[props.isOpen]);

	return (
		<Dialog
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			disableBackdropClick={true}
			open={props.isOpen}
			disableEscapeKeyDown={true}
			onClose={(event: object, reason: string) =>{
				if( reason!=="backdropClick" ){
					props.close();
				}
			}}
			fullScreen={fullScreen}
		>
				<DialogHeader close={props.close}/>
				<DialogContent dividers>
				<div className="confirmRoot">
					<h2>緊急メールの送信（あと{props.send}回送信可能）</h2>
					{step === "input" &&
					<Grid container spacing={1} style={{marginBottom:"2em"}}>
						<Grid item xs={12} sm={3} className="formName">タイトル</Grid>
						<Grid item xs={12} sm={9}>
							<ColInput id="Subject" noName={true} dense={true} error={vali.errors.Subject} type="text" name="タイトル" placeholder="100文字以内" value={mail.Subject} change={change}/>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">本文</Grid>
						<Grid item xs={12} sm={9}>
							<div style={{marginTop:"0.5em",color:"#999999"}}>〇〇〇〇様</div>
							<ColInput id="Body" noName={true} dense={true} error={vali.errors.Body} type="textarea" rows={20} name="本文" placeholder="1000文字以内" value={mail.Body} change={change}/>
							<div style={{marginTop:"0.5em",color:"#999999"}}>
								▼お問い合わせは {props.stage.Desk} まで<br/>
								{props.stage.Email!=="" && <div>Mail: {props.stage.Email}</div>}
								{props.stage.Tel!=="" && <div>Tel: {props.stage.Tel}</div>}
								<br/>
								------------------------------------------------------------------<br/>
								※このメールはR7 TICKET SERVICEから送信されました。<br/>
								※このメールへの返信はできませんのでご注意下さい。<br/>
								------------------------------------------------------------------<br/>
							</div>
						</Grid>
					</Grid>
					}
					{(step === "confirm" || step ==="waiting") &&
					<React.Fragment>
					<Grid container spacing={1} style={{marginBottom:"2em"}}>
						<Grid item xs={12} sm={3} className="formName">タイトル</Grid>
						<Grid item xs={12} sm={9}>
							<div style={{marginTop:"0.5em",color:"#333333"}}>{mail.Subject}</div>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">本文</Grid>
						<Grid item xs={12} sm={9}>
							<div style={{marginTop:"0.5em",marginBottom:"1em",color:"#333333"}}>〇〇〇〇様</div>
							<MultiLines text={mail.Body}/>
							<div style={{marginTop:"1.5em",color:"#333333"}}>
								▼お問い合わせは {props.stage.Desk} まで<br/>
								{props.stage.Email!=="" && <div>Mail: {props.stage.Email}</div>}
								{props.stage.Tel!=="" && <div>Tel: {props.stage.Tel}</div>}
								<br/>
								------------------------------------------------------------------<br/>
								※このメールはR7 TICKET SERVICEから送信されました。<br/>
								※このメールへの返信はできませんのでご注意下さい。<br/>
								------------------------------------------------------------------<br/>
							</div>
						</Grid>
						<Grid item xs={12} sm={3} className="formName">送信先</Grid>
						<Grid item xs={12} sm={9}>
							<div style={{marginTop:"0.5em",color:"#333333"}}>予約者全員</div>
						</Grid>
					</Grid>
					<div className="mailerCaution">
						<h3>- 注意事項 -</h3>
						<p>この機能は公演の中止や内容の変更および新型コロナウイルス対応関連など緊急のお知らせの場合にだけご利用いただけます。</p>
						<p>宣伝や販促など緊急以外のご利用、スパムメールの送信などの迷惑行為はご利用停止措置の対象となりますのでご注意下さい。</p>
						<div className="check">
							<FormControlLabel control={<Checkbox name="check" color="primary" onChange={check} checked={checked}/>} label="注意事項の内容を承知しました" />
						</div>
					</div>
					</React.Fragment>
					}
				</div>
				</DialogContent>
				<DialogActions>
					{step === "input" &&
						<>
							<Button variant="text" color="default" onClick={props.close}>閉じる</Button>
							<Button variant="contained" color="secondary" onClick={validate}>送信内容の確認</Button>
						</>
					}
					{(step === "confirm" || step ==="waiting") &&
						<>
							<Button variant="text" color="default" onClick={retry}>もどる</Button>
							<Button variant="contained" color="secondary" onClick={send} disabled={!checked}>送信する</Button>
						</>
					}
					{step === "finished" &&
					<React.Fragment>
					<div>
						<p>送信手続きが完了しました。</p>
						<p>件数が多い場合やサーバーが混みあっている場合には送信完了までに時間がかかる場合もあります。</p>
					</div>
					<FormControl fullWidth={true}>
						<Button variant="outlined" color="default" onClick={close}>閉じる</Button>
					</FormControl>
					</React.Fragment>
					}
				</DialogActions>
				<Toast close={closeToast} mes={toast}/>
				<Loading isLoading={step==="waiting"}/>
		</Dialog>
	);
}

export default Mailer;