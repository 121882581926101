import React,{useState, useEffect} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ClimbUp from './ClimbUp';
import Loading from './Loading';
import Yesno from './Yesno';
import Ajax from './Ajax';
import Toast from './Toast';
import StageHeader from './StageHeader';
import StageBookingInput from './StageBookingInput';
import StageBookingDetail from './StageBookingDetail';
import StageBookingSearch from './StageBookingSearch';
import StageBookingCsvMenu from './StageBookingCsvMenu';
import StageBookingSerialInput from './StageBookingSerialInput';
import Mailer from './Mailer';
import {UserContainer} from './UserContainer';
import {Inc,StageSeat,StageTicket,StageBooking,StageBookingSearchParam,Dataset} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import "./StageDetail.scss";
import { Tools } from './Tools';

interface Props {
    
}

//interface RCProps extends RouteComponentProps<{id:string}> {
//	
//}

const StageBookingList = (props:Props)=> {
    const userState = UserContainer.useContainer();
    //const rcp:RCProps = useReactRouter();
    const [states,setStates] = useState({
        isLoading:true,
        bookings:[] as StageBooking[],
        seats: [] as Dataset[],
        tickets: [] as Dataset[],
        isSearch: false,
		isCsv: false,
		isSerial: false,
		isMailer: false,
		errorCsv: "",
		serial: 0,
		ver: 0,
		send: 0,
        param: Inc.defaultStageBookingSearchParam(),
        pager: Inc.defaultPagerParam(),
        inputIndex: -1,
        detailIndex: -1,
    })
    const [redirectId,setRedirectId] = useState("");
    const [message,setMessage] = useState("");
    const [toast,setToast] = useState("");
    const {gid} = useParams<{gid:string}>();

    const yes = ()=>{
        setMessage("");
    }
    const no = ()=>{
        setMessage("");
    }
	const jump = (to:number)=>{
        //console.log("jump",to);
		search({...states.param,P:to});
	}
    const closeToast = ()=>{
        setToast("");
    }
    const openStageBookingInput = ()=>{
        setStates({...states,inputIndex:9999});
    }
    const closeStageBookingInput = ()=>{
        setStates({...states,inputIndex:-1});
    }
    const openStageBookingSearch = ()=>{
        setStates({...states,isSearch:true});
    }
    const closeStageBookingSearch = ()=>{
        setStates({...states,isSearch:false});
    }
    const openStageBookingCsvMenu = ()=>{
        setStates({...states,isCsv:true});
    }
    const closeStageBookingCsvMenu = ()=>{
        setStates({...states,isCsv:false});
    }
    const openStageBookingDetail = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.index ){
			return;
        }
        setStates({...states,detailIndex:parseInt(e.currentTarget.dataset.index)});
    }
    const closeStageBookingDetail = ()=>{
        setStates({...states,detailIndex:-1});
	}
	const openStageBookingSerialInput = async ()=>{
		setStates({...states,isLoading:true});
		await Ajax.getSerial(userState.stage.Id).then((res)=>{
			if( res.ok ){
				setStates({...states,isSerial:true,serial:res.serial,isLoading:false});
			} else {
				setStates({...states,isLoading:false});
				setToast("整理番号情報を取得できませんでした");
			}
		}).catch((error)=>{
			setStates({...states,isLoading:false});
			setToast("整理番号情報を取得できませんでした");
		});
	}
	const closeStageBookingSerialInput = ()=>{
		setStates({...states,isSerial:false});
	}
    const openMailer = ()=>{
        setStates({...states,isMailer:true});
    }
    const closeMailer = ()=>{
        setStates({...states,isMailer:false});
    }
    const finishMailer = ()=>{
		search(states.param);
    }

	const updateSerial = async (serial:number)=>{
		setStates({...states,isLoading:true});
		await Ajax.updateSerial(userState.stage.Id,serial).then((res)=>{
			if( res.ok ){
				setStates({...states,isSerial:false,serial:0,isLoading:false});
				setToast("整理番号のインデックスを変更しました");
			} else {
				setStates({...states,isLoading:false});
				setToast("整理番号のインデックスを変更できませんでした");
			}
		}).catch((error)=>{
			setStates({...states,isLoading:false});
			setToast("整理番号のインデックスを変更できませんでした");
		});
	}

    const refresh = ()=>{
        search(states.param);
	}
	const updateBooking = async (booking:StageBooking)=>{
		setStates({...states, isLoading:true});
		Ajax.setToken(userState.token);
		await Ajax.updateBooking(booking).then((res)=>{
			if( res.ok ){
				refresh();
			} else {
				//console.log(res);
				setStates({...states, isLoading:false});
				setToast("更新できませんでした");
			}
		}).catch((error)=>{
			setStates({...states, isLoading:false});
			setToast("更新できませんでした");
		});
	}
	const addBooking = async (booking:StageBooking)=>{
		setStates({...states, isLoading:true});
		Ajax.setToken(userState.token);
		await Ajax.addBooking(booking).then((res)=>{
			//console.log(res);
			if( res.ok ){
				refresh();
			} else {
				setStates({...states, isLoading:false});
				if( res.reject && res.reject==="noStock" ){
					setToast("残り枚数が足りないため登録できませんでした");
				} else {
					setToast("登録できませんでした");
				}
			}
		}).catch((error)=>{
			setStates({...states, isLoading:false});
			setToast("登録できませんでした");
		});
	}

    const editStageBooking = ()=>{
        setStates({...states,inputIndex:states.detailIndex,detailIndex:-1});
    }

    const getSeatsDataset = ()=>{
        let seats:Dataset[] = [];
        userState.stage.Seats.map((seat:StageSeat,index:number)=>{
            if( seat.Name!=="" && seat.Accepting ){
                seats.push({
                    id: String(index+1),
                    label: seat.Name,
                })
			}
			return true;
        });
        return seats;
    }
    const getTicketsDataset = ()=>{
        let tickets:Dataset[] = [];
        let exists:{[key: string]: boolean} = {};
        userState.stage.Tickets.map((ticket:StageTicket,index:number)=>{
            if( ticket.Accepting && !exists[ ticket.Code ] ){
                exists[ ticket.Code ] = true;
                tickets.push({
                    id: ticket.Code,
                    label: ticket.Label,
                })
			}
			return true;
        });
        return tickets;
    }
    const search = (param:StageBookingSearchParam)=>{
		setStates({...states, isLoading:true});
		const id:string = String(gid).trim();
		Ajax.setToken(userState.token);
		//console.log(param);
        Ajax.getBookings(id,param).then((res)=>{
            //console.log(res);
            if( res.ok ){
                if( states.detailIndex>=0 ){
                    setToast("キャンセルしました");
                }
                setStates({...states, bookings:res.Bookings, send:res.Send, param:param, pager:res.Pager, inputIndex:-1, detailIndex:-1, isCsv:false, isSearch:false, isMailer:false, isLoading:false});
            } else {
                setToast("予約データの読み込みに失敗しました");
            }
        }).catch((error)=>{
            setToast("予約データの読み込みに失敗しました");
        })

    }
    const upload = (uploadFile:File)=>{
		setStates({...states, isLoading:true});
		Ajax.setToken(userState.token);
        Ajax.uploadBookingCsv(uploadFile,userState.stage.Id).then((res)=>{
            if( res.ok ){
				//props.update();
				refresh();
            } else {
				setStates({...states, errorCsv:res.error, ver:states.ver+1, isLoading:false});
                //setToast(res.error)
            }
        }).catch((error)=>{
			setStates({...states, isLoading:false});
            setToast("予期しないエラーです");
        });
    }

    const download = async ()=>{
		setStates({...states, isLoading:true});
		Ajax.setToken(userState.token);
        await Ajax.downloadBookingCsv(userState.stage.Id,states.param).then((res)=>{
			setStates({...states, isCsv:false, isLoading:false});
        }).catch((error)=>{
			setStates({...states, isLoading:false});
            setToast("ダウンロードできませんでした");
        });
	}

	const load = async (stageId:string)=>{
		try {
			const res = await Ajax.getStage(stageId,false);
			if( res.ok ){
				userState.setStage(res.stage);
				userState.setVer(res.ver);
			} else {
				setRedirectId("/");
			}
		} catch(error) {
			console.log("error",error);
			setRedirectId("/");
		}
	}

	const checkVer = async (stageId:string)=>{
		let flag = true;
        if( !userState.user || !userState.hasStage() || userState.stage.Id!==stageId ){
			flag = false;
        }
		const res = await Ajax.getStageVer(stageId);
		//console.log("stageVer",res);
		if( !res.ok ){
			flag = false;
		} else if( userState.ver!==res.ver ){
			flag = false;
		}
		if( !flag ){
			//setRedirectId("/stage/detail/"+stageId);
			//console.log("ver",userState.ver,res.ver);
			await load(stageId);
            //return;
		}
		Ajax.setToken(userState.token);
        Ajax.getBookings(stageId,states.param).then((res)=>{
            //console.log("res",res);
            if( res.ok ){
                const seats = getSeatsDataset();
                const tickets = getTicketsDataset();
                setStates({...states, bookings:res.Bookings, send:res.Send,pager:res.Pager, seats:seats, tickets:tickets, isLoading:false});
            } else {
                setToast("予約データの読み込みに失敗しました");
            }
        }).catch((error)=>{
            setToast("予約データの読み込みに失敗しました");
        })
	}

    useEffect( ()=>{
		const id:string = String(gid).trim();
		checkVer(id);
	},[userState.user,gid]);

    if( redirectId!=="" ){
        return (
            <Redirect to={redirectId}/>
        )
    }

    if( !userState.ready ){
        return (null);
    }

    return (
		<React.Fragment>
			<StageHeader stage={userState.stage} current="booking"/>
			<div id="stage_detail" className="mediumRoot">
				<div className="paper">
					<Grid container spacing={2} className="stageForms">
						<Grid item xs={4}>
							<Hidden implementation="js" xsDown>
							<Button variant="contained" color="secondary" onClick={openStageBookingSearch} style={{marginRight:"0.5em"}}>
								<FontAwesomeIcon icon="search"/>
								検索
							</Button>
							</Hidden>
							<Hidden implementation="js" smUp>
							<Tooltip title="検索"><Fab color="secondary" size="small" onClick={openStageBookingSearch} style={{marginRight:"0.25em"}}>
								<FontAwesomeIcon icon="search"/>
							</Fab></Tooltip>
							</Hidden>
							<Tooltip title="最新の情報に更新"><Fab color="secondary" size="small" onClick={refresh}><AutorenewIcon/></Fab></Tooltip>
						</Grid>
						<Grid item xs={8} style={{textAlign:"right"}}>
							<Hidden implementation="js" xsDown>
							<Button variant="contained" color="secondary" onClick={openStageBookingCsvMenu} style={{marginRight:"0.25em"}}>
								<FontAwesomeIcon icon="file-csv"/>ＣＳＶ
							</Button>
							<Button variant="contained" color="secondary" onClick={openStageBookingSerialInput} style={{marginRight:"0.25em"}}>
								<FontAwesomeIcon icon="list-ol"/>整理番号
							</Button>
							</Hidden>
							<Hidden implementation="js" smUp>
							<Tooltip title="ＣＳＶ"><Fab color="secondary" size="small" onClick={openStageBookingCsvMenu} style={{marginRight:"0.25em"}}>
								<FontAwesomeIcon icon="file-csv"/>
							</Fab></Tooltip>
							<Tooltip title="整理番号"><Fab color="secondary" size="small" onClick={openStageBookingSerialInput} style={{marginRight:"0.25em"}}>
								<FontAwesomeIcon icon="list-ol"/>
							</Fab></Tooltip>
							</Hidden>
							<Button variant="contained" color="secondary" onClick={openStageBookingInput}>
								<FontAwesomeIcon icon="edit"/>
								<Hidden implementation="js" xsDown>予約する</Hidden>
								<Hidden implementation="js" smUp>予約</Hidden>
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={0} className="bookingList">
						<Grid item xs={12}>
							<div style={{borderBottom:"1px solid silver",paddingBottom:"0.5em",marginBottom:"0.25em"}}/>
						</Grid>

					{states.bookings.map((booking:StageBooking,index:number)=>{
						const key = "booking"+index;
						let firstLine = "clickable";
						if( booking.Canceled ){
							firstLine += " canceled";
						}
						return (
							<React.Fragment key={key}>
							<Grid item xs={8} sm={5} md={3} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								{booking.TicketCode.substr(12,5)==="00000" &&
									<Tooltip title="この日時と種類の組み合わせは存在しません"><span style={{color:"#CC9900"}}><FontAwesomeIcon icon="question-circle"/></span></Tooltip>
								}
								<span className="impact">{Tools.getTimeLabel(booking.TicketCode)}</span>
							</Grid>
							<Grid item xs={4} sm={3} md={2} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								<span className="impact">{Inc.seats[booking.SeatId-1]}　{Tools.getLabel(states.seats,String(booking.SeatId))}</span>
							</Grid>
							<Grid item xs={12} sm={4} md={3} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								<span className="impactName">{booking.Name}</span>
							</Grid>
							<Grid item xs={3} sm={2} md={1} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								{booking.Payment}
							</Grid>
							<Grid item xs={3} sm={2} md={1} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								{booking.Memo && booking.Memo!=="" &&
								<span>備考有</span>
								}
							</Grid>
							<Grid item xs={3} sm={6} md={1} className={firstLine} style={{textAlign:"right"}} onClick={openStageBookingDetail} data-index={index}>
								{booking.Quantity}枚
							</Grid>
							<Grid item xs={3} sm={2} md={1} className={firstLine} style={{textAlign:"right"}} onClick={openStageBookingDetail} data-index={index}>
								{booking.TotalFee}円
							</Grid>
							<Grid item xs={6} sm={4} md={3} className="secondLine clickable" onClick={openStageBookingDetail} data-index={index}>
								{booking.FormName!=="" ? (
								<span>{booking.FormName}</span>
								):(
								<span>{Inc.defaultFormName}</span>
								)}
								{booking.Canceled &&
									<React.Fragment>
										<br/>
										<Tooltip title="キャンセル済"><span className="canceled">キャンセル</span></Tooltip>
									</React.Fragment>
								}
							</Grid>
							<Grid item xs={6} sm={8} md={2} className="secondLine clickable" onClick={openStageBookingDetail} data-index={index}>
								{booking.Serial}
							</Grid>
							<Grid item xs={12} sm={4} md={3} className="secondLine clickable" onClick={openStageBookingDetail} data-index={index}>
								<div>
									{booking.Checked ? (
									<FontAwesomeIcon icon="envelope-open-text" className="mail mailChecked"/>
									):(
									<FontAwesomeIcon icon="envelope" className="mail"/>
									)}
									{booking.Email}
									{booking.Tel !== "" &&
									<React.Fragment>
										<br/><FontAwesomeIcon icon="phone-square" className="tel"/>{booking.Tel}
									</React.Fragment>
									}
								</div>
							</Grid>
							<Grid item xs={6} sm={4} md={2} className="secondLine clickable" onClick={openStageBookingDetail} data-index={index}>
								{Tools.toDate(booking.CreatedAt)}
							</Grid>
							<Grid item xs={6} sm={4} md={2} className="secondLine clickable" style={{textAlign:'right'}} onClick={openStageBookingDetail} data-index={index}>
								{booking.Contact !== "" ? (
									<span>{Tools.getLabel(Inc.contacts,booking.Contact)}</span>
								):(
									<span className="empty">選択なし</span>
								)}
							</Grid>
							<Grid item xs={12}>
								<div style={{borderBottom:"1px solid silver",padding:"0.5em 0",marginTop:"0.25em",marginBottom:"0.25em"}}/>
							</Grid>

							</React.Fragment>
						)
					})}
					</Grid>
					<div className="pager">
						{Tools.pager(states.pager,jump)}         
					</div>
					<div className="bottomButtons">
						{states.pager.Count>0 ? (
						<React.Fragment>
							<Button variant="outlined" color="primary" onClick={openMailer} disabled={states.send===0}>予約者全員に緊急メールを送る</Button>
							<div className="mailerLife">- あと{states.send}回送信可能 -</div>
						</React.Fragment>
						):(
						<React.Fragment>
							<Button variant="outlined" color="primary" disabled={true}>緊急メールを送れる予約者がいません</Button>
							<div className="mailerLife">- あと{states.send}回送信可能 -</div>
						</React.Fragment>
						)}
					</div>
				</div>
				<StageBookingInput isOpen={states.inputIndex>=0} close={closeStageBookingInput} update={updateBooking} add={addBooking} stage={userState.stage} booking={states.bookings[states.inputIndex]}/>
				<StageBookingDetail isOpen={states.detailIndex>-1} close={closeStageBookingDetail} cancel={refresh} edit={editStageBooking} stage={userState.stage} booking={states.bookings[states.detailIndex]}/>
				<StageBookingSearch isOpen={states.isSearch} close={closeStageBookingSearch} param={states.param} send={search} stage={userState.stage}/>
				<StageBookingCsvMenu isOpen={states.isCsv} close={closeStageBookingCsvMenu} update={refresh} param={states.param} stage={userState.stage} upload={upload} download={download} error={states.errorCsv} ver={states.ver}/>
				<StageBookingSerialInput isOpen={states.isSerial} close={closeStageBookingSerialInput} update={updateSerial} serial={states.serial} stage={userState.stage}/>
				<Mailer isOpen={states.isMailer} close={closeMailer} finish={finishMailer} send={states.send} stage={userState.stage}/>
				<Yesno mes={message} yes={yes} no={no} isOpen={message!==""}/>
				<Toast close={closeToast} mes={toast}/>
				<ClimbUp/>
				<Loading isLoading={states.isLoading}/>
			</div>
		</React.Fragment>
    );
}

export default StageBookingList;
