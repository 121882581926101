import React,{useState, useEffect} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ClimbUp from './ClimbUp';
import Loading from './Loading';
import Yesno from './Yesno';
import Ajax from './Ajax';
import Toast from './Toast';
import StageHeader from './StageHeader';
import StageBookingInput from './StageBookingInput';
import StageBookingDetail from './StageBookingDetail';
import StageBookingSearch from './StageBookingSearch';
import StageBookingCsvMenu from './StageBookingCsvMenu';
import StageBookingSerialInput from './StageBookingSerialInput';
import Mailer from './Mailer';
import {UserContainer} from './UserContainer';
import {Inc,Stage,StageSeat,StageTicket,StageBooking,StageBookingSearchParam,Dataset} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import "./StageDetail.scss";
import { Tools } from './Tools';

interface Props {
    
}

//interface RCProps extends RouteComponentProps<{id:string}> {
//	
//}

const StageBookingLimitedList = (props:Props)=> {
    //const userState = UserContainer.useContainer();
    //const rcp:RCProps = useReactRouter();
    const [base,setBase] = useState({
		stage: {} as Stage,
        seats: [] as Dataset[],
        tickets: [] as Dataset[],
		token: '',
    })
    const [states,setStates] = useState({
        isLoading:true,
        bookings:[] as StageBooking[],
        isSearch: false,
		isCsv: false,
		isSerial: false,
		isMailer: false,
		errorCsv: "",
		serial: 0,
		send: 0,
        param: Inc.defaultStageBookingSearchParam(),
        pager: Inc.defaultPagerParam(),
        inputIndex: -1,
        detailIndex: -1,
    })
    const [redirectId,setRedirectId] = useState("");
    const [message,setMessage] = useState("");
	const [toast,setToast] = useState("");
    const {gid,fid,secret} = useParams<{gid:string,fid:string,secret:string}>();

    const yes = ()=>{
        setMessage("");
    }
    const no = ()=>{
        setMessage("");
    }
	const jump = (to:number)=>{
        //console.log("jump",to);
		search({...states.param,P:to});
	}
    const closeToast = ()=>{
        setToast("");
    }	
    const openStageBookingSearch = ()=>{
        setStates({...states,isSearch:true});
    }
    const closeStageBookingSearch = ()=>{
        setStates({...states,isSearch:false});
    }
    const openStageBookingDetail = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.index ){
			return;
        }
        setStates({...states,detailIndex:parseInt(e.currentTarget.dataset.index)});
    }
    const closeStageBookingDetail = ()=>{
        setStates({...states,detailIndex:-1});
	}
    const refresh = ()=>{
        search(states.param);
	}
    const getSeatsDataset = (stage:Stage)=>{
        let seats:Dataset[] = [];
        stage.Seats.map((seat:StageSeat,index:number)=>{
            if( seat.Name!=="" && seat.Accepting ){
                seats.push({
                    id: String(index+1),
                    label: seat.Name,
                })
			}
			return true;
        });
        return seats;
    }
    const getTicketsDataset = (stage:Stage)=>{
        let tickets:Dataset[] = [];
        let exists:{[key: string]: boolean} = {};
        stage.Tickets.map((ticket:StageTicket,index:number)=>{
            if( ticket.Accepting && !exists[ ticket.Code ] ){
                exists[ ticket.Code ] = true;
                tickets.push({
                    id: ticket.Code,
                    label: ticket.Label,
                })
			}
			return true;
        });
        return tickets;
    }
	const editStageBooking = ()=>{

	}

	const load = async (stageId:string,formCode:string,formSecret:string)=>{
		try {
			const res = await Ajax.getStage(stageId,false);
			console.log(res);
			if( res.ok ){
				let token = '';
				await Ajax.freeToken(res.stage.uid).then((res)=>{
					if(res.ok){
						token = res.token;
					}
				});

				let formName = states.param.FormName;
				for( let i=0; i<res.stage.Forms.length; i++ ){
					if(res.stage.Forms[i].Code===formCode){
						if(res.stage.Forms[i].Secret!==formSecret){
							setRedirectId("/");
							return;
						}
						formName = res.stage.Forms[i].Name;
					}
				}

				const param = {...Inc.defaultStageBookingSearchParam(),FormName:formName};
				const seats = getSeatsDataset(res.stage);
				const tickets = getTicketsDataset(res.stage);		

				Ajax.setToken(base.token);
				setBase({...base,stage:res.stage, seats:seats, tickets:tickets, token:token});
				search(param);
			} else {
				setRedirectId("/");
			}
		} catch(error) {
			console.log("error",error);
			setRedirectId("/");
		}
	}

    const search = (param:StageBookingSearchParam)=>{
		setStates({...states, isLoading:true});
		const id:string = String(gid).trim();
		//console.log(param);
        Ajax.getBookings(id,param).then((res)=>{
            //console.log(res);
            if( res.ok ){
                setStates({...states, bookings:res.Bookings, send:res.Send, param:param, pager:res.Pager, inputIndex:-1, detailIndex:-1, isCsv:false, isSearch:false, isMailer:false, isLoading:false});
            } else {
                setToast("予約データの読み込みに失敗しました");
            }
        }).catch((error)=>{
            setToast("予約データの読み込みに失敗しました");
        })

    }


    useEffect( ()=>{
		const id:string = String(gid).trim();
		const formCode:string = String(fid).trim();
		const formSecret:string = String(secret).trim();
		load(id,formCode,formSecret);
	},[gid,fid,secret]);

    if( redirectId!=="" ){
        return (
            <Redirect to={redirectId}/>
        )
    }

    if( !base.stage || !base.stage.Forms || base.stage.Forms.length===0 ){
        return (null);
    }

    return (
		<React.Fragment>
			<StageHeader stage={base.stage} current="booking" limited={true}/>
			<div id="stage_detail" className="mediumRoot">
				<div className="paper">
					<Grid container spacing={2} className="stageForms">
						<Grid item xs={4}>
							<Hidden implementation="js" xsDown>
							<Button variant="contained" color="secondary" onClick={openStageBookingSearch} style={{marginRight:"0.5em"}}>
								<FontAwesomeIcon icon="search"/>
								検索
							</Button>
							</Hidden>
							<Hidden implementation="js" smUp>
							<Tooltip title="検索"><Fab color="secondary" size="small" onClick={openStageBookingSearch} style={{marginRight:"0.25em"}}>
								<FontAwesomeIcon icon="search"/>
							</Fab></Tooltip>
							</Hidden>
							<Tooltip title="最新の情報に更新"><Fab color="secondary" size="small" onClick={refresh}><AutorenewIcon/></Fab></Tooltip>
						</Grid>
						<Grid item xs={8} style={{textAlign:"right",position:'relative'}}>
							<Box style={{position:'absolute',top:'20%',right:'1em'}}>{states.param.FormName}</Box>
						</Grid>
					</Grid>
					<Grid container spacing={0} className="bookingList">
						<Grid item xs={12}>
							<div style={{borderBottom:"1px solid silver",paddingBottom:"0.5em",marginBottom:"0.25em"}}/>
						</Grid>

					{states.bookings.map((booking:StageBooking,index:number)=>{
						const key = "booking"+index;
						let firstLine = "clickable";
						if( booking.Canceled ){
							firstLine += " canceled";
						}
						return (
							<React.Fragment key={key}>
							<Grid item xs={8} sm={5} md={3} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								{booking.TicketCode.substr(12,5)==="00000" &&
									<Tooltip title="この日時と種類の組み合わせは存在しません"><span style={{color:"#CC9900"}}><FontAwesomeIcon icon="question-circle"/></span></Tooltip>
								}
								<span className="impact">{Tools.getTimeLabel(booking.TicketCode)}</span>
							</Grid>
							<Grid item xs={4} sm={3} md={2} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								<span className="impact">{Inc.seats[booking.SeatId-1]}　{Tools.getLabel(base.seats,String(booking.SeatId))}</span>
							</Grid>
							<Grid item xs={12} sm={4} md={3} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								<span className="impactName">{booking.Name}</span>
							</Grid>
							<Grid item xs={3} sm={2} md={1} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								{booking.Payment}
							</Grid>
							<Grid item xs={3} sm={2} md={1} className={firstLine} onClick={openStageBookingDetail} data-index={index}>
								{booking.Memo && booking.Memo!=="" &&
								<span>備考有</span>
								}
							</Grid>
							<Grid item xs={3} sm={6} md={1} className={firstLine} style={{textAlign:"right"}} onClick={openStageBookingDetail} data-index={index}>
								{booking.Quantity}枚
							</Grid>
							<Grid item xs={3} sm={2} md={1} className={firstLine} style={{textAlign:"right"}} onClick={openStageBookingDetail} data-index={index}>
								{booking.TotalFee}円
							</Grid>
							<Grid item xs={6} sm={4} md={3} className="secondLine clickable" onClick={openStageBookingDetail} data-index={index}>
								{booking.FormName!=="" ? (
								<span>{booking.FormName}</span>
								):(
								<span>{Inc.defaultFormName}</span>
								)}
								{booking.Canceled &&
									<React.Fragment>
										<br/>
										<Tooltip title="キャンセル済"><span className="canceled">キャンセル</span></Tooltip>
									</React.Fragment>
								}
							</Grid>
							<Grid item xs={6} sm={8} md={2} className="secondLine clickable" onClick={openStageBookingDetail} data-index={index}>
								{booking.Serial}
							</Grid>
							<Grid item xs={12} sm={4} md={3} className="secondLine clickable" onClick={openStageBookingDetail} data-index={index}>
								<div>
									{booking.Checked ? (
									<FontAwesomeIcon icon="envelope-open-text" className="mail mailChecked"/>
									):(
									<FontAwesomeIcon icon="envelope" className="mail"/>
									)}
									{booking.Email}
									{booking.Tel !== "" &&
									<React.Fragment>
										<br/><FontAwesomeIcon icon="phone-square" className="tel"/>{booking.Tel}
									</React.Fragment>
									}
								</div>
							</Grid>
							<Grid item xs={6} sm={4} md={2} className="secondLine clickable" onClick={openStageBookingDetail} data-index={index}>
								{Tools.toDate(booking.CreatedAt)}
							</Grid>
							<Grid item xs={6} sm={4} md={2} className="secondLine clickable" style={{textAlign:'right'}} onClick={openStageBookingDetail} data-index={index}>
								{booking.Contact !== "" ? (
									<span>{Tools.getLabel(Inc.contacts,booking.Contact)}</span>
								):(
									<span className="empty">選択なし</span>
								)}
							</Grid>
							<Grid item xs={12}>
								<div style={{borderBottom:"1px solid silver",padding:"0.5em 0",marginTop:"0.25em",marginBottom:"0.25em"}}/>
							</Grid>

							</React.Fragment>
						)
					})}
					</Grid>
					<div className="pager">
						{Tools.pager(states.pager,jump)}
					</div>
				</div>
				<StageBookingDetail isOpen={states.detailIndex>-1} close={closeStageBookingDetail} cancel={refresh} limited={true} edit={editStageBooking} stage={base.stage} booking={states.bookings[states.detailIndex]}/>
				<StageBookingSearch isOpen={states.isSearch} close={closeStageBookingSearch} param={states.param} send={search} stage={base.stage} formCode={String(fid).trim()}/>
				<Yesno mes={message} yes={yes} no={no} isOpen={message!==""}/>
				<Toast close={closeToast} mes={toast}/>
				<ClimbUp/>
				<Loading isLoading={states.isLoading}/>
			</div>
		</React.Fragment>
    );
}

export default StageBookingLimitedList;
