import {HelpCard,MaxStages} from './Inc';
const HelpEmpty:HelpCard[] = [];
const HelpAbout:HelpCard[] = [
	{
		Title:"シンプルなフォーム",
		Text:"R7の予約受付フォームは会員登録やログインの不要なシンプルなスタイルです。慣れていないお客様でも気楽に申し込むことができます。",
		Img:"about1.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"複数フォームに対応",
		Text:"出演者別にフォームを作成するなど、標準の受付フォーム以外に複数の受付フォームを用意することができます。",
		Img:"about2.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"誰でも簡単にイベント登録",
		Text:"開演日時や販売枚数の制限まで、一つの編集画面で簡単に登録できます。あとで変更したり加筆したりすることも簡単です。",
		Img:"about3.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"ＣＳＶで出力も登録も",
		Text:"予約データはリアルタイムで確認できます。ＣＳＶファイルでダウンロードすることも可能です。また、ＣＳＶファイルにまとめた複数の予約データをアップロードして登録することもできます。",
		Img:"about4.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"メールアドレスの確認機能",
		Text:"メールに書かれたＵＲＬにアクセスすることで予約完了となりますので、メールアドレスの間違いや不正なメールでの予約を防ぐことができます。",
		Img:"about5.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"予約者用のマイページ",
		Text:"予約をすると予約内容がいつでも確認できるマイページが用意されます。マイページでは予約のキャンセルも可能です。",
		Img:"about6.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"整理番号にも対応",
		Text:"予約に整理番号をつけることができます。整理番号の先頭に任意の文字をつけることも可能ですので、ほかのシステムでの受付と併用するのに好都合です。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"安定したサーバー",
		Text:"GoogleFirebaseと独自サーバーを組み合わせて安定したサービスを提供。アクセスが過度に集中した際にも、イベントの情報や問い合わせ先がきちんと表示されます。",
		Img:"",
		Notice:"",
		Btns:[],
	},
]
const HelpOutline:HelpCard[] = [
	{
		Title:"ログインをする",
		Text:"画面右上の「ログイン」をタップして、ログインしてください。",
		Img:"outline1.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"イベントをつくる",
		Text:"トップページの「イベントをつくる」をタップし、イベントの情報を入力します。",
		Img:"outline2.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"チケット情報を入力する",
		Text:"イベントの登録が済んだら上のメニューから「チケット」をタップし、販売するチケットの種類や日時を入力します。種類・日時ごとに販売枚数を設定することも可能です。",
		Img:"outline3.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"フォームを追加する",
		Text:"出演者それぞれに専用フォームを用意したい場合は、「フォーム」をタップして追加しましょう。各フォームごとに通知用のメールアドレスを設定することもできます。",
		Img:"about2.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"利用者情報を入力する",
		Text:"チケットとフォームの登録が済んだら「受付開始」をタップし、利用者の情報を入力します。チケットを購入するお客様とのトラブル回避のため利用者情報は必ず入力してください。",
		Img:"outline8.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"契約開始する",
		Text:"利用者情報の入力が済んだら、「利用開始」をタップすると契約期間がスタートします。契約期間は１年です。契約期間が終了したイベントは自動的に削除されます。",
		Img:"outline4.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"受付期間を入力する",
		Text:"予約の受付を開始するため「受付期間」を入力してください。現在または過去の日時を入力すると、受付はすぐに開始されます。",
		Img:"outline5.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"予約をもらう",
		Text:"届いた予約は「予約状況」に一覧表示されます。管理者用のフォームで予約を追加したり、ＣＳＶファイルをアップロードして一括で登録することも可能です。",
		Img:"outline7.png",
		Notice:"",
		Btns:[],
	},
]
const HelpMake:HelpCard[] = [
	{
		Title:"イベント名称 / アーティスト / 会場",
		Text:"ほかのイベントと間違えることがないように、わかりやすい名前を入力してください。",
		Img:"make1.png",
		Notice:"※この情報はお客様用受付フォームに表示されます",
		Btns:[],
	},
	{
		Title:"コード",
		Text:"コードは受付フォームのURLに使われます。ほかのイベントですでに使われているコードは使えません。",
		Img:"make2.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"通知メール",
		Text:"予約が入ったことを管理者にお知らせするメール（予約通知メール）の宛先です。省略した場合、予約通知メールは送信されません。",
		Img:"make3.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"整理番号とプレフィックス",
		Text:"予約に整理番号をつけることができます。整理番号の先頭に記号をつけたい場合は、その記号をプレフィックスに入力してください。",
		Img:"make4.png",
		Notice:"※整理番号の現在値の確認・変更は「予約状況」の「整理番号」でできます。",
		Btns:[],
	},
	{
		Title:"同時予約枚数",
		Text:"１回の申し込みで予約できる枚数の上限です。特に必要のない限りは少なめに設定しておいた方が良いかも知れません。",
		Img:"make5.png",
		Notice:"※この情報はお客様用受付フォームに表示されます",
		Btns:[],
	},
	{
		Title:"公式サイト",
		Text:"イベントの詳細がわかるウェブページのURLを入力してください。団体や個人のＳＮＳでも構いません。",
		Img:"make6.png",
		Notice:"※この情報はお客様用受付フォームに表示されます",
		Btns:[],
	},
	{
		Title:"問い合わせ窓口",
		Text:"イベントについての質問を受けつける問い合わせ窓口の名前（団体名や担当者名など）を入力してください。",
		Img:"make7.png",
		Notice:"※この情報はお客様用受付フォームに表示されます",
		Btns:[],
	},
	{
		Title:"イベント内容",
		Text:"イベントの内容について説明してください。予約をするお客様用に向けた説明です。",
		Img:"make13.png",
		Notice:"※この情報はお客様用受付フォームに表示されます",
		Btns:[],
	},
	{
		Title:"当日の案内",
		Text:"開場時間や当日の受付の方法など、予約をしたお客様にお伝えしたい情報を入力してください。",
		Img:"make14.png",
		Notice:"※この情報は予約完了メールと予約内容の確認画面に表示されます",
		Btns:[],
	},
	{
		Title:"緊急のお知らせ",
		Text:"開演時間や出演者の変更など緊急のお知らせがある場合は入力してください。",
		Img:"make15.png",
		Notice:"※この情報はお客様用受付フォームと予約内容の確認画面に赤字で表示されます",
		Btns:[],
	},
	{
		Title:"仮予約の注意事項",
		Text:"仮予約メールを送信した後、本登録前（メールに書かれたＵＲＬにアクセスする前）のお客様に伝えたい内容があれば入力して下さい。",
		Img:"make16.png",
		Notice:"※この情報はお客様用受付フォームと予約内容の確認画面に表示されます",
		Btns:[],
	},
	{
		Title:"イベントの削除",
		Text:"フォームをすべて休止中にしてから、「開催内容」の「削除する」をタップしてください。予約もすべて消えてしまいますので、削除前によく確認していただくようお願いいたします。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"利用できないイベント",
		Text:"公序良俗に違反する内容や、当社もしくは第三者に不利益を与える行為と判断されるイベントについては、ご利用をお断りする場合があります。",
		Img:"",
		Notice:"",
		Btns:[],
	},

]
const HelpTicket:HelpCard[] = [
	{
		Title:"チケットの種類",
		Text:"一般用や学生用などチケットが複数種類ある場合は5種類まで登録できます。右端のスイッチをOFFにしたチケットは、お客様用の受付フォームには表示されません。（管理者用のフォームには表示されます。）",
		Img:"ticket1.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"開演日時と枚数",
		Text:"開演日時を設定します。開演日時とチケット種類ごとに、販売する枚数の上限を設定することが可能です。",
		Img:"ticket2.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"枚数制限のコツ",
		Text:"種類が2つ以上選択されていると、両方合わせての枚数制限となります。例えば上記の画像例の場合、前売券と特別券の販売枚数が合計で100枚になるまで販売されます。",
		Img:"ticket4.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"招待券の登録",
		Text:"招待券など販売対象でないチケットは価格を0円にして登録してください。価格が0円のチケットはお客様用の受付フォームには表示されません。（管理者用のフォームには表示されます。）",
		Img:"ticket3.png",
		Notice:"",
		Btns:[],
	},
]
const HelpOpen:HelpCard[] = [
	{
		Title:"利用者情報",
		Text:"このシステム（R7 TICKET SERVICE）の利用者に関する情報です。万が一のトラブルの際に責任の所在を明確にするため必ず正確に入力してください。",
		Img:"outline8.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"利用を開始する",
		Text:"利用者情報の入力が済んだら、「利用開始」をタップすると利用開始されます。利用期間は１年です。利用期間が終了したイベント（予約データ含む）は自動的に削除されます。",
		Img:"outline4.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"受付期間を入力する",
		Text:"予約の受付を開始するため「受付期間」を入力してください。現在または過去の日時を入力すると、受付はすぐに開始されます。",
		Img:"outline5.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"受付期間中のイベント情報の修正",
		Text:"受付期間中でもイベントの情報は修正が可能です。ただし修正内容がお客様用フォームに反映されるのに若干のタイムラグがあるなど、お客様用フォームへの影響が少なからずありますので、なるべくお客様のアクセスが少ない時間帯を見計らって修正することをオススメいたします。",
		Img:"",
		Notice:"",
		Btns:[],
	},

]
const HelpForm:HelpCard[] = [
	{
		Title:"代表フォーム",
		Text:"イベントを登録するとお客様用のフォームが自動的に一つ作成（代表フォーム）されます。このフォームは削除することができません。",
		Img:"form1.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"フォームを増やす",
		Text:"代表フォーム以外に出演者それぞれの専用フォームなどを必要な場合は、「フォームを追加する」で作成してください。",
		Img:"form2.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"コード",
		Text:"コードは受付フォームのURLに使われます。同じイベント内のほかのフォームですでに使われているコードは使えません。",
		Img:"form3.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"通知メール",
		Text:"予約内容を通知するメールの送り先をフォームごとに１つ追加することができます。出演者用のフォームを用意する場合は、各出演者のメールアドレスを登録すると良いでしょう。",
		Img:"form6.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"フォーム設定",
		Text:"予約時にお客様に入力してもらう情報のうち、「電話番号」「イベント情報が届いているかの確認」「備考欄」は必要に応じて非表示にすることができます。",
		Img:"form7.png",
		Notice:"※デフォルトでは「電話番号」が非表示になっています。",
		Btns:[],
	},
	{
		Title:"フォームを見る",
		Text:"各フォームのURLをクリックするとフォームが表示されます。",
		Img:"form4.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"フォームごとの予約状況を見る",
		Text:"「予約状況確認ページ」を「利用する」に設定すると、フォームごとの予約状況を確認できるページが用意されます。この確認ページはログインしなくても閲覧できますので、URLを関係者以外に知られないようにご注意ください。",
		Img:"form8.png",
		Notice:"※「なし」にしてから再度「利用する」に戻すと確認ページのURLは変更されます",
		Btns:[],
	},
	{
		Title:"フォームを隠す",
		Text:"フォームはいつでも（予約の受付が開始されていなくても）見ることができますが、一時的に隠しておきたい場合は「公開状態」を「休止中」にしてください。",
		Img:"form5.png",
		Notice:"",
		Btns:[],
	},
]
const HelpBooking:HelpCard[] = [
	{
		Title:"お客様用フォームで申し込む",
		Text:"受付期間が始まると、お客様用フォームで申し込みが可能になります。支払方法は「当日精算」のみです。また、販売枚数の上限に達したチケットや、一時休止（OFF）になっているチケットは選択できません。",
		Img:"about1.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"管理者用フォームで登録する",
		Text:"「予約状況」の「予約する」をタップすると管理者用フォームが利用できます。管理者用フォームでは、メールアドレスが省略可能です。また、受付期間外でも一時休止（OFF）になっているチケットでも予約できます。",
		Img:"booking2.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"ＣＳＶファイルで登録する",
		Text:"ＣＳＶファイルをアップロードして一括で予約を登録することができます。一度にアップロードできる予約の件数は100件までです。",
		Img:"booking3.png",
		Notice:"※整理番号を指定して登録する場合は、整理番号が重複しないようにご注意ください。",
		Btns:[
			{
				Name: "ファイルの仕様",
				Link: "",
				Sample: "csvupload",
				Download: "",
				Color: "primary",
			},
			{
				Name: "サンプルファイル",
				Link: "",
				Sample: "",
				Download: "csvupload",
				Color: "primary",
			},
		],
	},
	{
		Title:"通知メール",
		Text:"お客様用フォームで予約受付が完了すると予約したお客様にメールが送信されます。通知メールが設定されている場合は同時に管理者にも送信されます。管理者用フォームやＣＳＶのアップロードではお客様にも管理者にもメールは送信されません。",
		Img:"",
		Notice:"",
		Btns:[
			{
				Name: "メールのサンプル",
				Link: "",
				Sample: "bookingmail",
				Download: "",
				Color: "primary",
			}
		],
	},
	{
		Title:"支払方法",
		Text:"お客様用フォームでは支払方法が「当日精算」のみですが、管理者用フォームやＣＳＶのアップロードでは、当日精算以外の文言を入力することができます。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"予約状況を見る",
		Text:"届いた予約は「予約状況」に一覧表示されます。条件を指定して検索することも可能です。",
		Img:"outline7.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"予約が完了しているか確認する",
		Text: "",
		Texts:["お客様用フォームからの予約は、まず「まだ予約完了ではありません」というメールがお客様だけに送信され、そのメールの中に書かれた「確認URL」にお客様がアクセスすることで予約完了となります。","予約完了となると、「予約状況」の一覧表示の中でメールアドレスの左側に緑色のアイコンが表示されます。","赤いアイコンが表示されている場合は予約手続きが完了していないということですので、しばらくその状態が続くようでしたらお客様に個別に連絡をとって確認する必要があるかも知れません。"],
		Img:"booking13.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"予約状況のダウンロード",
		Text:"予約状況はＣＳＶファイルでダウンロードすることができます。",
		Img:"booking7.png",
		Notice:"",
		Btns:[
			{
				Name: "ファイルの仕様",
				Link: "",
				Sample: "csvdownload",
				Download: "",
				Color: "primary",
			},
			{
				Name: "サンプルファイル",
				Link: "",
				Sample: "",
				Download: "csvdownload",
				Color: "primary",
			},
		],
	},
	{
		Title:"整理番号を確認する",
		Text:"整理番号の現在値の確認・変更は「予約状況」の「整理番号」でできます。",
		Img:"booking12.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"予約内容を修正する",
		Text:"予約をしたお客様が自分の予約内容を変更・修正する機能はありません。管理者は予約の内容をいつでも変更可能です。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"予約のマイページ",
		Text:"お客様用フォームで予約をしたお客様には「予約のマイページ（予約内容の確認画面）」のURLが画面とメールで通知されます。予約のマイページでは、予約した内容がいつでも確認できます。",
		Img:"booking9.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"予約のキャンセル",
		Text:"予約をしたお客様は予約のマイページで予約のキャンセルが可能です。",
		Img:"booking11.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"予約者とのトラブル",
		Text:"予約者（予約したお客様）とイベントの主催者の間で予約に関するトラブルが発生した場合、当サービスおよび運営会社は一切の責任を負えません。あらかじめご了承ください。",
		Img:"",
		Notice:"",
		Btns:[],
	},
]
const HelpFee:HelpCard[] = [
	{
		Title:"すべて無料で利用できます",
		Text:"このサービスは基本無料です。イベントの登録から受付フォーム、予約状況のダウンロードなど、すべての機能を無料でご利用いただけます。",
		Img:"fee1.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"イベント数の制限",
		Text:"登録できるイベントは1アカウントにつき"+MaxStages+"個までです。"+(MaxStages+1)+"個以上登録したい場合は、不要になった古いイベントを削除してから登録してください。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"トラブル・免責事項",
		Text:"正常に動作することを確認した上で公開しておりますが、見落としや想定外の不具合があるかも知れません。万が一そのような不具合によって利用者および予約者に何らかの不利益を与えた場合でも、損害の補償は一切いたしかねますのであらかじめご了承ください。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"サービスの廃止",
		Text:"運営会社の業務事情等によりやむをえずサービスを終了することがあるかも知れません。その場合は十分な余裕を持って事前にお知らせするなど、なるべくご迷惑をおかけしないよう努力いたしますが、ご期待に応えられない点も出てくるかと思います。どうかあらかじめご了承ください。",
		Img:"",
		Notice:"",
		Btns:[],
	},
]
const HelpMail:HelpCard[] = [
	{
		Title:"緊急メールとは？",
		Text:"公演の中止や内容の変更、または新型コロナウイルス対応関連など、予約者に緊急にお知らせしたいことがある場合には、「予約状況」から一斉メールを送信することができます。",
		Img:"mail1.png",
		Notice:"",
		Btns:[],
	},
	{
		Title:"緊急メールの送り先",
		Text:"緊急メールが送信されるのは「予約者全員」です。一部の予約者だけに送ることはできません。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"緊急メールの内容",
		Text:"緊急メールのタイトル（件名）と本文は自由に設定することができます。タイトルは100文字まで、本文は1000文字まで入力可能です。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"緊急メールの送信回数",
		Text:"緊急メールが送信できるのは、送信件数に関わらず3回までです。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"宣伝や販促には使えません",
		Text:"宣伝や販促など緊急以外のご利用はお控えください。また、スパムメールの送信などの迷惑行為はご利用停止措置の対象となりますのでご注意下さい。",
		Img:"",
		Notice:"",
		Btns:[],
	},
	{
		Title:"どうしてもあと１回送りたい",
		Text:"送信回数を使い切ってしまった後で、どうしても予約者に伝えなくてはならない内容がある場合は、「お問い合わせフォーム」からご一報下さい。状況によっては対応が可能な場合もあります。",
		Img:"",
		Notice:"",
		Btns:[],
	},
]

export default class HelpDict {
	static readonly get = (code:string):HelpCard[]=>{
		if( code==="outline" ){
			return HelpOutline;
		} else if( code==="make" ){
			return HelpMake;
		} else if( code==="ticket" ){
			return HelpTicket;
		} else if( code==="open" ){
			return HelpOpen;
		} else if( code==="booking" ){
			return HelpBooking;
		} else if( code==="fee" ){
			return HelpFee;
		} else if( code==="about" ){
			return HelpAbout;
		} else if( code==="form" ){
			return HelpForm;
		} else if( code==="mail" ){
			return HelpMail;
		} else {
			return HelpEmpty;
		}
	}
}