import React,{useState, useEffect} from 'react';
import {Redirect, useParams} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import Hidden from '@material-ui/core/Hidden';
import Fab from '@material-ui/core/Fab';
//import MultiLines from './MultiLines';
import CopyToClipboard from 'react-copy-to-clipboard';
import ClimbUp from './ClimbUp';
import Loading from './Loading';
import Yesno from './Yesno';
import Ajax from './Ajax';
import Toast from './Toast';
import StageHeader from './StageHeader';
import StageFormInput from './StageFormInput';
import StageFormConfigInput from './StageFormConfigInput';
import {UserContainer} from './UserContainer';
import {Inc,StageForm,StageFormConfig} from './Inc';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import "./StageDetail.scss";
//import { Tools } from './Tools';

interface Props {
    
}

//interface RCProps extends RouteComponentProps<{id:string}> {
//	
//}

const StageFormList = (props:Props)=> {
    const userState = UserContainer.useContainer();
    //const rcp:RCProps = useReactRouter();
    const [states,setStates] = useState({
		isLoading:true,
		isConfig:false,
        editIndex: -1,
        all: "",
    })
    const [redirectId,setRedirectId] = useState("");
    //const [notFound,setNotFound] = useState(false);
    const [toast,setToast] = useState("");
    const {gid} = useParams<{gid:string}>();

    const yes = async ()=>{
        setStates({...states,isLoading:true});
        if(states.all==="true"){
            await updateAllStageForm(true);
        }
        if(states.all==="false"){
            await updateAllStageForm(false);
        }
        setStates({...states,isLoading:false,all:""});
    }
    const no = ()=>{
        setStates({...states,all:""});
    }
    const closeToast = ()=>{
        setToast("");
    }
    const confirm = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.flag ){
			return;
        }
        setStates({...states,all:e.currentTarget.dataset.flag});
    }
    const updateAllStageForm = async (viewing:boolean)=>{
        let forms:StageForm[] = [];
        for( let i=0; i<userState.stage.Forms.length; i++ ){
            forms.push({...userState.stage.Forms[i],Viewing:viewing});
		}
		Ajax.setToken(userState.token);
        await Ajax.updateStageForms(forms,!viewing,userState.stage.Id).then((res)=>{
			if( res.ok ){
	            userState.setStage({...userState.stage,Forms:forms,Removable:!viewing});
				setStates({...states,all:""});
			} else {
				setToast("更新できませんでした");
			}
        }).catch((error)=>{
			console.log(error);
			setToast("更新できませんでした");
        });
    }

    const openStageFormInput = (e: React.MouseEvent<HTMLElement>)=>{
        if( !e.currentTarget.dataset.index ){
			return;
        }
        const index = parseInt(e.currentTarget.dataset.index);
        //console.log(index);
        setStates({...states,editIndex:index});
    }
    const closeStageFormInput = ()=>{
        setStates({...states,editIndex:-1});
    }
    const updateStageForm = (stageForm:StageForm,deleteFlag:boolean)=>{
        //console.log("update",stageForm);
        let forms:StageForm[];
        if( deleteFlag ){
            if( states.editIndex===0 ){
                forms = userState.stage.Forms.slice(1);
            } else if( userState.stage.Forms.length>states.editIndex  ){
                forms = userState.stage.Forms.slice(0,states.editIndex).concat(userState.stage.Forms.slice(states.editIndex+1));
            } else {
                return;
            }
        } else {
            if( states.editIndex===0 ){
                forms = [stageForm].concat(userState.stage.Forms.slice(1));
            } else if( userState.stage.Forms.length>states.editIndex  ){
                forms = userState.stage.Forms.slice(0,states.editIndex).concat(stageForm).concat(userState.stage.Forms.slice(states.editIndex+1));
            } else {
                forms = userState.stage.Forms.concat(stageForm);
            }
        }
        let removable = true;
        for( let i=0; i<forms.length; i++ ){
            if( forms[i].Viewing ){
                removable = false
            }
		}
		setStates({...states,isLoading:true});
		Ajax.setToken(userState.token);
        Ajax.updateStageForms(forms,removable,userState.stage.Id).then((res)=>{
			//console.log("updateStageForms",res.forms);
			if( res.ok ){
				userState.setStage({...userState.stage,Forms:res.forms,Removable:removable});
				userState.setVer(res.ver);
				setStates({...states,editIndex:-1,isLoading:false});
			} else {
				setStates({...states,isLoading:false});
				setToast("更新できませんでした");
			}
        }).catch((error)=>{
			setStates({...states,isLoading:false});
			setToast("更新できませんでした");
			console.log(error);
        });
    }
    const putForm = (index:number,stageForm:StageForm)=>{
        let name = stageForm.Name;
        let code = stageForm.Code;
        if( index===0 ){
            name = Inc.defaultFormName;
            code = "";
        }
		//const to = "/"+userState.stage.doc.Code+"/"+code;
		//const to = process.env.REACT_APP_STAGE_FORM_URL+userState.stage.Code+"/"+code;
		//const url = process.env.REACT_APP_STAGE_FORM_URL+userState.stage.doc.Code+"/"+code;
		const urlForm = process.env.REACT_APP_STAGE_FORM_URL+userState.stage.Code+"/"+code;
		const urlSecret = process.env.REACT_APP_STAGE_LIMITED_URL+userState.stage.Id+"/"+code+"/"+stageForm.Secret;
		let bccmail = "なし";
		if( stageForm.BccMail && stageForm.BccMail!=="" ){
			bccmail = stageForm.BccMail;
		}
        const key = "form"+index;
        return (
            <Grid item xs={12} sm={6} key={key}><div className="stageForm">
                <div className="name">
                    {stageForm.Viewing===true ? (
                        <FontAwesomeIcon icon="check-circle" className="ok icon"/>
                    ):(
                        <FontAwesomeIcon icon="ban" className="ng icon"/>
                    )}
                    {name}
                </div>
                <div className="url urlForm">
					フォーム　<a href={urlForm} target="_blank" rel="noopener noreferrer">{urlForm}</a>
					<div className="clip">
						<CopyToClipboard
							text={urlForm}
							onCopy={copy}
						>
							<Tooltip title="URLをクリップボードにコピー">
								<IconButton size="small" color="default">
									<FontAwesomeIcon icon="clipboard" style={{fontSize:'0.8em'}}/>
								</IconButton>
							</Tooltip>
						</CopyToClipboard>
					</div>
				</div>
				<div className="url urlLimited">
					予約状況　
					{stageForm.Secret && stageForm.Secret!=='' ? (
					<>
					<a href={urlSecret} target="_blank" rel="noopener noreferrer">{urlSecret}</a>
					<div className="clip">
						<CopyToClipboard
							text={urlSecret}
							onCopy={copy}
						>
							<Tooltip title="URLをクリップボードにコピー">
								<IconButton size="small" color="default">
									<FontAwesomeIcon icon="clipboard" style={{fontSize:'0.8em'}}/>
								</IconButton>
							</Tooltip>
						</CopyToClipboard>
					</div>
					</>
					):(
						<span>なし</span>
					)}
				</div>
				<div className="bccmail"><FontAwesomeIcon icon="envelope" className="bccmail"/> {bccmail}</div>
                <div className="actions">
					<Button variant="outlined" data-index={index} color="default" size="small" onClick={openStageFormInput} startIcon={<FontAwesomeIcon icon="edit"/>}>
                        編集する
                    </Button>
                </div>
            </div></Grid>
        )
    };
    const isUsed = (name:string,code:string)=>{
        let flagName = false;
        let flagCode = false;
        userState.stage.Forms.map((form:StageForm,index:number)=>{
            if( form.Name===name && index!==states.editIndex ){
                flagName = true;
            }
            if( form.Code===code && index!==states.editIndex ){
                flagCode = true;
			}
			return true;
        });
        return [flagName,flagCode];
    }
    const copy = ()=>{
        setToast("URLをクリップボードにコピーしました");
	}
	const openStageFormConfig = ()=>{
		setStates({...states,isConfig:true});
	}
	const closeStageFormConfig = ()=>{
		setStates({...states,isConfig:false});
	}
	const updateStageFormConfig = async (config:StageFormConfig)=>{
		setStates({...states,isLoading:true});
		Ajax.setToken(userState.token);
		await Ajax.updateStageFormConfig(config).then((res)=>{
			//console.log(res);
			if( res.ok ){
				setStates({...states,isConfig:false, isLoading:false});
				userState.setStage({...userState.stage,UseTel:config.UseTel,UseMemo:config.UseMemo,UseEnq:config.UseEnq});
				userState.setVer(res.ver);
			} else {
				setStates({...states, isLoading:false});
				setToast("更新できませんでした");
			}
		}).catch((error)=>{
			setStates({...states,isLoading:false});
			setToast("更新できませんでした");
			throw error;
		});		
	}
	const load = async (stageId:string)=>{
		try {
			const res = await Ajax.getStage(stageId,false);
			if( res.ok ){
				userState.setStage(res.stage);
				userState.setVer(res.ver);
			} else {
				setRedirectId("/");
			}
		} catch(error) {
			console.log("error",error);
			setRedirectId("/");
		}
	}

	const checkVer = async (stageId:string)=>{
		let flag = true;
        if( !userState.user || !userState.hasStage() || userState.stage.Id!==stageId ){
			flag = false;
        }
		const res = await Ajax.getStageVer(stageId);
		//console.log("stageVer",res);
		if( !res.ok ){
			flag = false;
		} else if( userState.ver!==res.ver ){
			flag = false;
		}
		if( !flag ){
            //setRedirectId("/stage/detail/"+stageId);
			//return;
			await load(stageId);
		}
        setStates({...states, isLoading:false});
	}

    useEffect( ()=>{
        const id:string = String(gid).trim();
        if( id==="" ){
            setRedirectId("/");
            return;
		}
		checkVer(id);
    },[userState.user,gid]);

    if( redirectId!=="" ){
        return (
            <Redirect to={redirectId}/>
        )
    }

    if( !userState.ready ){
        return (null);
    }


    

    let editForm:StageForm;
    if( states.editIndex>=0 && userState.stage.Forms.length>states.editIndex ){
        editForm = userState.stage.Forms[states.editIndex];
    } else {
        editForm = Inc.defaultStageForm();
    }

    let message = "";
    if( states.all==="true" ){
        message = "すべてのフォームを公開します。よろしいですか？";
    }
    if( states.all==="false" ){
        message = "すべてのフォームを休止にします。よろしいですか？";
    }

    return (
		<React.Fragment>
			<StageHeader stage={userState.stage} current="form"/>
			<div id="stage_form_list" className="mediumRoot">
				<div className="paper">
					<Grid container spacing={2} className="stageForms">
						<Grid item xs={10} sm={8}>
							<Button variant="contained" color="secondary" onClick={confirm} data-flag="true" style={{marginRight:"0.25em"}} startIcon={<FontAwesomeIcon icon="check-circle" className="ok icon"/>}>全て公開</Button>
							<Button variant="contained" color="secondary" onClick={confirm} data-flag="false" style={{marginRight:"0.25em"}} startIcon={<FontAwesomeIcon icon="ban" className="ng icon"/>}>全て休止</Button>
						</Grid>
						<Grid item xs={2} sm={4} style={{textAlign:"right"}}>
						<Hidden implementation="js" xsDown>
							<Button variant="contained" color="secondary" onClick={openStageFormConfig} startIcon={<FontAwesomeIcon icon="cog"/>}>フォーム設定</Button>
						</Hidden>
						<Hidden implementation="js" smUp>
							<Tooltip title="フォーム設定"><Fab color="secondary" size="small" onClick={openStageFormConfig}><FontAwesomeIcon icon="cog"/></Fab></Tooltip>
						</Hidden>
						</Grid>
						{userState.stage.Forms.map((form:StageForm,index:number)=>{
							return putForm(index,form);
						})}
						<Grid item xs={12} sm={6} style={{textAlign:"center",paddingTop:"3.5em"}}>
							<Button variant="outlined" color="default" data-index={100} onClick={openStageFormInput} startIcon={<AddIcon/>}>フォームを追加する</Button>
						</Grid>
					</Grid>
				</div>
				<StageFormInput isOpen={states.editIndex>=0} close={closeStageFormInput} stageForm={editForm} update={updateStageForm} isFirst={states.editIndex===0} check={isUsed}/>
				<StageFormConfigInput isOpen={states.isConfig} close={closeStageFormConfig} update={updateStageFormConfig} stage={userState.stage}/>
				<Yesno mes={message} yes={yes} no={no} isOpen={message!==""}/>
				<Toast close={closeToast} mes={toast}/>
				<Loading isLoading={states.isLoading}/>
				<ClimbUp/>
			</div>
		</React.Fragment>
    );
}

export default StageFormList;
